import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Search.module.sass'
import Icon from '../../Icon'
import Item from './Item'
import Suggestion from './Suggestion'
import ModalProduct from '../../../components/ModalProduct'
import { makeApiCall } from '../../../lib/api'
import Loader from '../../Loader'
import { useNavigate } from 'react-router'

const result = [
  {
    title: 'Put your title here',
    content: 'Small caption',
    image: '/images/content/product-pic-3.jpg',
    image2x: '/images/content/product-pic-3@2x.jpg',
  },
  {
    title: 'Put your title here',
    content: 'Small caption',
    image: '/images/content/product-pic-4.jpg',
    image2x: '/images/content/product-pic-4@2x.jpg',
  },
]

const suggestions = [
  {
    title: 'Put your title here',
    content: 'Small caption',
    icon: 'photos',
  },
  {
    title: 'Put your title here',
    content: 'Small caption',
    icon: 'photos',
  },
]

const Search = ({ className }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [noapps, setNoApps] = useState(false)
  const [searched, setSearched] = useState([])
  const [keyword, setKeyword] = useState('')
  const getProducts = async (value) => {
    // e.preventDefault()
    setIsLoading(true)
    const response = await makeApiCall(`/users/profile?search=${value}`)
    //  co(response)
    // console.log(response)
    if (response.status === 200) {
      if (response.data.length === 0) {
        setNoApps(true)
      }
      setSearched(response?.data)
      setIsLoading(false)
    }
  }
  const [visible, setVisible] = useState(false)
  const [visibleModalProduct, setVisibleModalProduct] = useState(false)
  const navigate = useNavigate()

  const handelChange = (e) => {
    setKeyword(e.target.value)
    // console.log(e.target.value)
    if (keyword.length >= 3) {
      getProducts(keyword)
      setVisible(true)
    }
  }
  // console.log(searched, 'searched')
  const navigation = (item) => {
    navigate(`/user-profile?id=${item.user_id}`)
  }
  return (
    <>
      <div
        className={cn(styles.search, className, { [styles.active]: visible })}
      >
        <div className={styles.head}>
          <button className={styles.start}>
            <Icon name='search' size='24' />
          </button>
          <button className={styles.direction}>
            <Icon name='arrow-left' size='24' />
          </button>
          <input
            className={styles.input}
            type='text'
            name='search'
            placeholder='Search or type a command'
            onChange={handelChange}
            // onChange={() => (setVisible(true), handelChange)}
          />
          <button className={styles.result}>⌘ F</button>
          <button className={styles.close} onClick={() => setVisible(false)}>
            <Icon name='close-circle' size='24' />
          </button>
        </div>
        <div className={styles.body}>
          <div className={styles.box}>
            <div className={styles.category}>Search Results</div>
            <div
              className={styles.list}
              style={{
                // border: '2px solid red',
                height: '310px',
                overflow: 'scroll',
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Loader className={styles.loader} />
                </div>
              ) : (
                searched.map((x, index) => (
                  <Item
                    className={styles.item}
                    item={x}
                    key={index}
                    onClick={() => navigation(x)}
                  />
                ))
              )}
            </div>
          </div>
          {/* <div className={styles.box}>
            <div className={styles.category}>Suggestions</div>
            <div className={styles.list}>
              {suggestions.map((x, index) => (
                <Suggestion
                  className={styles.item}
                  item={x}
                  key={index}
                  onClick={() => setVisibleModalProduct(true)}
                />
              ))}
            </div>
          </div> */}
        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  )
}

export default Search
