import Overview from "./Overview";
import React from "react";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Transactions from "./Transactions";
import styles from "./Statements.module.sass";

const Statements = () => {
  return (
    <>
      <div className={styles.section}>
        <div style={{ marginBottom: "2rem" }}>
          <Overview className={styles.card} />
        </div>
        <Transactions />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Statements;
