import CurrencyFormatter from "../../../../../utils/CurrencyFormatter";
import CustomTooltip from "../../../../../components/CustomTooltip";
import Icon from "../../../../../components/Icon";
import React from "react";
import styles from "./Overview.module.sass";
import { useNavigate } from "react-router";
function Items({ stats, index, isLoading }) {
  const navigate = useNavigate();

  return (
    <div
      className={styles.item}
      key={index}
      style={{ cursor: "pointer", padding: "10px" }}
      onClick={() => (
        navigate(
          `/payment/gateway-report/details?channel=${stats?.channelCode}`
        ),
        localStorage.setItem("revName", JSON.stringify(stats.channelCode))
      )}
    >
      <div
        style={{
          margin: "20px",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className={styles.label} style={{ textTransform: "capitalize" }}>
            {stats.channelCode}
            <CustomTooltip content={stats?.amount} className={styles.tooltip}>
              <Icon name="info" fill="#6F767E" />
            </CustomTooltip>
          </div>

          <div className={styles.icon}>
            <Icon name="payment" size="24" />{" "}
          </div>
        </div>
        <div className={styles.counter}>
          {isLoading ? (
            "****"
          ) : (
            <>
              <CurrencyFormatter currencySymbol={"₦"} amount={stats?.amount} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Items;
