import React, { useEffect, useState } from "react";

import Balance from "../../../../../components/Balance";
import Card from "../../../../../components/Card";
import CurrencyFormatter from "../../../../../utils/CurrencyFormatter";
import CustomTooltip from "../../../../../components/CustomTooltip";
import Icon from "../../../../../components/Icon";
import Tooltip from "../../../../../components/Tooltip";
import TooltipGlodal from "../../../../../components/TooltipGlodal";
import cn from "classnames";
import styles from "./Overview.module.sass";

// import { Tooltip } from 'react-tooltip'
const items = [
  {
    title: "Earning",
    counter: "$128k",
    icon: "activity",
    color: "#B5E4CA",
    tooltip: "Small description Earning",
    value: 37.8,
  },
  {
    title: "Balance",
    counter: "$512.64",
    icon: "pie-chart",
    color: "#CABDFF",
    tooltip: "Small description Balance",
    value: -17.8,
  },
  {
    title: "Total value of sales",
    counter: "$64k",
    icon: "shopping-bag",
    color: "#B1E5FC",
    tooltip: "Small description Total",
    value: 24.3,
  },
];

const Overview = ({ className, reportData, isLoading }) => {
  return (
    <div style={{ marginBottom: "2rem" }}>
      {/* <div className={styles.counter}>Revenue for the Year {currentYear}</div> */}

      <Card className={cn(styles.card, className)}>
        <div className={styles.overview}>
          <div className={styles.list}>
            <div className={styles.item}>
              <div
                className={styles.icon}
                style={{ backgroundColor: items[0].color }}
              >
                <Icon name="activity" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.label}>
                  Today's Payment
                  <CustomTooltip
                    content={reportData?.revenue_statistics?.today_payment}
                    className={styles.tooltip}
                    symbol="NGN"
                  >
                    <Icon name="info" fill="#6F767E" />
                  </CustomTooltip>
                  {/* <Tooltip
                    className={styles.tooltip}
                    title='Hello'
                    icon='info'
                    place='right'
                  /> */}
                </div>
                <div className={styles.counter}>
                  {isLoading ? (
                    "*********"
                  ) : (
                    <CurrencyFormatter
                      amount={reportData?.revenue_statistics?.today_payment}
                      currencySymbol="₦"
                    />
                  )}
                </div>
                {/* <div className={styles.indicator}>
                  <Balance className={styles.balance} value={x.value} />
                  <span>this week</span>
                </div> */}
              </div>
            </div>
            {/* This Month Payment */}
            <div className={styles.item}>
              <div
                className={styles.icon}
                style={{ backgroundColor: items[0].color }}
              >
                <Icon name="pie-chart" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.label}>
                  This Month Payment
                  <CustomTooltip
                    content={reportData?.revenue_statistics?.monthly_payment}
                    className={styles.tooltip}
                    symbol="NGN"
                  >
                    <Icon name="info" fill="#6F767E" />
                  </CustomTooltip>
                  {/* <Tooltip
                    className={styles.tooltip}
                    title={x.tooltip}
                    icon='info'
                    place='top'
                  /> */}
                </div>
                <div className={styles.counter}>
                  {isLoading ? (
                    "*********"
                  ) : (
                    <CurrencyFormatter
                      amount={reportData?.revenue_statistics?.monthly_payment}
                      currencySymbol="₦"
                    />
                  )}
                  {/* {reportData?.revenue_statistics?.today_payment} */}
                </div>
                {/* <div className={styles.indicator}>
                  <Balance className={styles.balance} value={x.value} />
                  <span>this week</span>
                </div> */}
              </div>
            </div>
            {/* Overrall Payment */}
            <div className={styles.item}>
              <div
                className={styles.icon}
                style={{ backgroundColor: items[0].color }}
              >
                <Icon name="pie-chart" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.label}>
                  Overall Revenue
                  <CustomTooltip
                    content={
                      reportData?.revenue_statistics?.sum_total_year_payment
                    }
                    className={styles.tooltip}
                    symbol="NGN"
                  >
                    <Icon name="info" fill="#6F767E" />
                  </CustomTooltip>
                  {/* <Tooltip
                    className={styles.tooltip}
                    title={x.tooltip}
                    icon='info'
                    place='top'
                  /> */}
                </div>
                <div className={styles.counter}>
                  {isLoading ? (
                    "*********"
                  ) : (
                    <CurrencyFormatter
                      amount={
                        reportData?.revenue_statistics?.sum_total_year_payment
                      }
                      currencySymbol="₦"
                    />
                  )}
                  {/* {reportData?.revenue_statistics?.sum_total_year_payment} */}
                </div>
                {/* <div className={styles.indicator}>
                  <Balance className={styles.balance} value={x.value} />
                  <span>this week</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </div>
  );
};

export default Overview;
