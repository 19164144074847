import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Shop.module.sass";
import Card from "../../components/Card";
import { useLocation } from "react-router";
import { makeApiCall } from "../../lib/api";
import Loader from "../../components/Loader";
import SchoolDetails from "./SchoolsDetails";
import Houses from "./SchoolsDetails/Houses";
import Overview from "./SchoolsDetails/Overview";

const navigation = ["School Info", "All Students"];

const SchoolProfiles = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [userProfile, setUserProfile] = useState({});

  const getUserProfile = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(`/school?schoolId=${id}`);
    //  co(response)
    // console.log(response)
    if (response.status === 200) {
      //  if (response.data.length === 0) {
      //    setNoApps(true)
      //  }
      setUserProfile(response?.data);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getUserProfile();
  }, [id]);
  // console.log(userProfile, " This is user profile");

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // border: '2px solid red',
            height: "100vh",
          }}
        >
          <Loader className={styles.loader} />
        </div>
      ) : (
        <div className={styles.shop}>
          <div className={styles.background}>
            <img
              src="https://cross-river-pay.s3.us-east-2.amazonaws.com/cas-admin/images/content/profile-bg.jpg"
              alt="Background"
            />
          </div>
          <Card className={styles.card}>
            {/* <Profile userProfile={userProfile} /> */}

            <div className={styles.control}>
              <div className={styles.nav}>
                {navigation.map((x, index) => (
                  <button
                    className={cn(styles.link, {
                      [styles.active]: index === activeIndex,
                    })}
                    onClick={() => setActiveIndex(index)}
                    key={index}
                  >
                    {x}
                  </button>
                ))}
              </div>
            </div>
            <div className={styles.wrap}>
              {activeIndex === 0 && (
                <>
                  <div className={styles.products}>
                    <SchoolDetails userProfile={userProfile} />
                  </div>
                </>
              )}
              {activeIndex === 1 && (
                <>
                  <div className={styles.followers}>
                    <Houses userProfile={userProfile} />
                  </div>
                  {/* <div className={styles.foot}>
                  <button
                    className={cn('button-stroke button-small', styles.button)}
                  >
                    Load more
                  </button>
                </div> */}
                </>
              )}
              {/* {activeIndex === 2 && (
                <>
                  <div className={styles.followers}>
                    <NotFound />
                  </div>
                </>
              )} */}
              {/* {activeIndex === 3 && (
                <>
                  <div className={styles.followers}>

                    <UserPayment userProfile={userProfile} />
                  </div>

                </>
              )} */}
            </div>
          </Card>
          <div style={{ marginTop: "2rem" }}>
            <Overview userProfile={userProfile} />
            {/* <Card className={styles.card}> */}
            {/* </Card> */}
          </div>
        </div>
      )}
    </>
  );
};

export default SchoolProfiles;
