import React from "react";
import Table from "./Table";

function TriggerPoints() {
  return (
    <>
      <Table />
    </>
  );
}

export default TriggerPoints;
