import React from 'react'
import cn from 'classnames'
import styles from './Item.module.sass'
import Icon from '../../../Icon'

const Item = ({ className, item, onClick }) => {
  return (
    <div className={cn(styles.item, className)}>
      <div className={styles.link} onClick={onClick}>
        <div className={styles.preview}>
          <img
            // srcSet={`${item.image2x} 2x`}
            src='https://cross-river-pay.s3.us-east-2.amazonaws.com/cas-admin/images/content/user.jpg'
            alt='Product'
          />
        </div>
        <div className={styles.details}>
          <div className={styles.content}>{item.name}</div>
          <div className={styles.title}>{item.ssn}</div>
        </div>
      </div>
      {/* <button className={styles.close}>
        <Icon name='close' size='24' />
      </button> */}
    </div>
  )
}

export default Item
