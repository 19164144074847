import App from "./App";
import { BrowserRouter } from "react-router-dom";
import InactivityLogout from "./utils/InactiveLogout";
import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { registerServiceWorker } from "./serviceWorker";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <InactivityLogout /> */}
      <Toaster position="top-right" reverseOrder={false} />

      <App />
    </BrowserRouter>
  </React.StrictMode>
);
registerServiceWorker();
