import React, { useCallback, useEffect, useRef, useState } from "react";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import styles from "../ProductsDashboard.module.sass";
import styles1 from "../../Notification/Notification.module.sass";

import cn from "classnames";
import { makeApiCall } from "../../../lib/api";
import toaster from "../../../utils/toaster";
import Filters from "../Filters";

// const filters = []

// console.log(filters);
function UpdateRoles({ setVisibleModal, getRevenueHeads, filters }) {
  const item = JSON.parse(localStorage.getItem("Feature"));
  const assignedFeatures = item?.features;
  const defualtValue = item?.features;

  console.log(defualtValue, " defualtValue");
  const id = item?.id;
  // console.log(id);
  // defualtValue?.map((p, index) =>
  //   console.log(p, "THis is mapped out P", index)
  // );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(defualtValue);
  const [newPaymentDetails, setNewPaymentDetails] = useState({
    channel: item.name,
    channel_title: "",
    channel_desc: "",
    secret_key: "",
    public_key: "",
    channel_code: "",
  });
  // console.log(selectedFilters)
  //const dataFetchedRef = useRef(false);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewPaymentDetails({
      ...newPaymentDetails,
      [name]: value,
    });
  };
  const roleData = {
    features: selectedFilters,
    name: newPaymentDetails.channel,
  };
  console.log(JSON.stringify(roleData));

  const updateRole = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await makeApiCall(
      `/roles/rolesUpdate/${id}`,
      "PATCH",
      roleData
    );
    if (response.status === 200) {
      toaster(response.message, "success");
      setIsLoading(false);
      setVisibleModal(false);
      getRevenueHeads();
      // setFeatures(response.data);
      // console.log(response)
      // let data = response.data;
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };
  const createPaymentGateway = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(
      `/payment/methods`,
      "POST",
      newPaymentDetails
    );
    if (response.status === 200) {
      toaster(response.message, "success");
      setVisibleModal(false);
      setIsLoading(false);
      getRevenueHeads();
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };
  // useEffect(() => {
  //   if (dataFetchedRef.current) return;
  //   dataFetchedRef.current = true;
  // }, []);
  // console.log(features);
  // for (const object of features) {
  //   filters.push(object.name);
  // }
  // console.log(selectedFilters);
  // console.log(roleData, 'roleData')
  return (
    <>
      <Card
        className={styles.card}
        title="Update Roles"
        classTitle="title-green"
      >
        <form onSubmit={updateRole}>
          <div style={{ marginBottom: "12px" }}>
            <TextInput
              value={newPaymentDetails.channel}
              className={``}
              name="channel"
              type="text"
              placeholder="Enter Role Name"
              required
              icon="profile-circle"
              onChange={handleChange}
            />
          </div>

          {/* <div className={styles.col}> */}
          <div
            style={{
              marginBottom: "12px",
              marginTop: "12px",
              // border: '2px solid red',
            }}
          >
            <Filters
              className={styles1.filters}
              filters={filters}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              title="Update Features"
              classTitle="title-blue"
              assignedFeatures={assignedFeatures}
            />
          </div>
          {/* </div> */}

          <button
            className={cn("button")}
            style={{ width: "100%" }}
            // onClick={() => createPaymentGateway()}
            // disabled={
            //   loginDetails.email === '' ||
            //   loginDetails.password === '' ||
            //   loading === true
            // }
          >
            {isLoading ? "Please Wait ......" : "Update"}
          </button>
        </form>
      </Card>
    </>
  );
}

export default UpdateRoles;
