import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Text,
  Tooltip,
} from "recharts";
import React, { useEffect, useState } from "react";

import Card from "../../../../../components/Card";
import NotFound from "../../../../NotFound";
import cn from "classnames";
import { getCurrentYearAndMonth } from "../../../../../utils";
import { makeApiCall } from "../../../../../lib/api";
import { makeReportCall } from "../../../../../lib/reportsApi";
import styles from "./NewCustomer.module.sass";
import toaster from "../../../../../utils/toaster";
import { useLocation } from "react-router";

const legend = [
  {
    title: "New customer",
    color: "#B5E4CA",
  },
  {
    title: "Returning customer",
    color: "#CABDFF",
  },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const NewCustomer = ({ className }) => {
  const [noValueData, setNoValueData] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  //  const { currentYear, currentMonth } = getCurrentYearAndMonth()

  const id = params.get("id");
  const revCode = id.replace("/", "");

  // console.log(id, "id");
  // console.log(id, currentMonth, currentYear)
  const [recordsStats, setRecordsStats] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getStatistics = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeReportCall(
      `/revenueHead/business-statistics/${revCode}`
    );

    // console.log(response)
    if (response.status === 200) {
      // console.log(response)
      setRecordsStats(response.data);
      // console.log(response);

      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };
  const data = recordsStats?.yearly_overview;

  function areAllValuesZero(arr) {
    return arr?.every((item) => item.value === 0 || item.value === null);
  }
  useEffect(() => {
    getStatistics();
    if (areAllValuesZero(recordsStats?.yearly_overview)) {
      setNoValueData(true);
    }
  }, []);

  console.log(noValueData, "redddd----");

  return (
    <div style={{ marginBottom: "2rem" }}>
      <Card
        className={cn(styles.card, className)}
        title="Overview"
        classTitle="title-blue"
      >
        {noValueData ? (
          <div style={{ marginBottom: "1rem" }}>
            <NotFound />
          </div>
        ) : (
          <div
            className={styles.chart}
            // style={{
            //   border: '2px solid red',
            //   display: 'flex',
            //   justifyContent: 'center',
            //   alignItems: 'center',
            //   width: '100%',
            // }}
          >
            {/* <NotFound /> */}

            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={400} height={400}>
                <Tooltip
                  contentStyle={{
                    backgroundColor: "#272B30",
                    borderColor: "rgba(255, 255, 255, 0.12)",
                    borderRadius: 8,
                    boxShadow:
                      "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
                  }}
                  labelStyle={{
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#fff",
                  }}
                  itemStyle={{
                    padding: 0,
                    textTransform: "capitalize",
                    fontSize: 12,
                    fontWeight: "600",
                    color: "#fff",
                  }}
                />
                <Pie
                  data={data}
                  cx={450}
                  cy={90}
                  innerRadius={40}
                  outerRadius={60}
                  fill="#8884d8"
                  paddingAngle={2}
                  dataKey="value"
                >
                  {recordsStats?.yearly_overview?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                  {/* <CenteredText cx={200} cy={200} /> */}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        )}
        <div className={styles.legend}>
          {recordsStats?.yearly_overview?.map((x, index) => (
            <div className={styles.indicator} key={index}>
              <div
                className={styles.color}
                style={{
                  backgroundColor: COLORS[index],
                }}
              ></div>
              <p
                style={{
                  textTransform: "capitalize",
                }}
              >
                {x.name} : {x.value}
              </p>
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default NewCustomer;
