import React from "react";

function formatCurrency(value) {
  const numericValue = Number(value);

  if (isNaN(numericValue)) {
    return "0"; // or any other suitable message for non-numeric values
  } else if (Math.abs(numericValue) >= 1.0e9) {
    const valueInBillion = (Math.abs(numericValue) / 1.0e9).toString();
    const decimalIndex = valueInBillion.indexOf(".");
    if (decimalIndex !== -1) {
      return valueInBillion.slice(0, decimalIndex + 3) + "B";
    } else {
      return valueInBillion + "B";
    }
  } else if (Math.abs(numericValue) >= 1.0e6) {
    const valueInMillion = (Math.abs(numericValue) / 1.0e6).toString();
    const decimalIndex = valueInMillion.indexOf(".");
    if (decimalIndex !== -1) {
      return valueInMillion.slice(0, decimalIndex + 3) + "M";
    } else {
      return valueInMillion + "M";
    }
  } else if (Math.abs(numericValue) >= 1.0e3) {
    const valueInThousand = (Math.abs(numericValue) / 1.0e3).toString();
    const decimalIndex = valueInThousand.indexOf(".");
    if (decimalIndex !== -1) {
      return valueInThousand.slice(0, decimalIndex + 3) + "K";
    } else {
      return valueInThousand + "K";
    }
  } else {
    const valueAsString = numericValue.toString();
    const decimalIndex = valueAsString.indexOf(".");
    if (decimalIndex !== -1) {
      return valueAsString.slice(0, decimalIndex + 3);
    } else {
      return valueAsString;
    }
  }
}

function CurrencyFormatter({ amount, currencySymbol }) {
  const formattedAmount = formatCurrency(amount);

  return (
    <span>
      {currencySymbol} {formattedAmount}
    </span>
  );
}

export default CurrencyFormatter;
