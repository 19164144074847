import "./styles.css";

import React, { useEffect, useState } from "react";

import Balance from "../../../components/Balance";
import Card from "../../../components/Card";
import Chart from "./Chart";
import CurrencyFormatter from "../../../utils/CurrencyFormatter";
import CustomTooltip from "../../../components/CustomTooltip";
import Dropdown from "../../../components/Dropdown";
import Icon from "../../../components/Icon";
// import Loader from "../../../components/Loader";
// import Tooltip from "../../../components/Tooltip";
import TooltipGlodal from "../../../components/TooltipGlodal";
import cn from "classnames";
import { handleApiError } from "../../../utils";
import { makeApiCall } from "../../../lib/api";
import styles from "./Overview.module.sass";
import toaster from "../../../utils/toaster";

const intervals = ["This week", "This month", "This year"];

const Overview = ({ className }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [isLoading, setIsLoading] = useState(false);
  // const darkMode = JSON.parse(localStorage.getItem("darkMode"));
  // console.log(darkMode)
  const [statistics, setStatistics] = useState();
  const getProducts = async () => {
    try {
      setIsLoading(true);
      const response = await makeApiCall("/statistics");

      if (response.status === 200) {
        console.log(response);
        setStatistics(response?.data);
        setIsLoading(false);
      } else {
        toaster("Something Went Wrong", "error");
        setIsLoading(false);
        throw new Error("API request failed");
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);
  const bgColors = {
    background: "#edf8f2",
  };
  // console.log(statistics);
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Overview"
        classTitle="title-purple"
        head={
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={sorting}
            setValue={setSorting}
            options={intervals}
            small
          />
        }
      >
        <div className={styles.overview}>
          <div className="over">
            <div
              style={{
                backgroundColor: `${bgColors.background}`,
                padding: "10px",
                borderRadius: "10px",
              }}
              className={styles.item}
            >
              <div className={styles.icon}>
                <Icon name="payment" size="24" />{" "}
              </div>
              <div className={styles.line}>
                <div className={styles.details}>
                  <div className={styles.category}>
                    Total Entities
                    {/* <Tooltip
                      className={styles.tooltip}
                      title='Small description'
                      icon='info'
                      place='right'
                    /> */}
                    <CustomTooltip
                      content={
                        isLoading ? "**********" : statistics?.totalUsers
                      }
                    >
                      <Icon name="info" />{" "}
                    </CustomTooltip>
                  </div>
                  <div className={styles.counter}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <CurrencyFormatter amount={statistics?.totalUsers} />

                        {/* {} */}
                      </>
                    )}
                  </div>
                  <div
                    className={styles.indicator}
                    style={{ marginTop: "5px" }}
                  >
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <Balance
                          className={styles.balance}
                          value={statistics?.totalUsers}
                        />
                      </>
                    )}

                    {/* <Balance
                      className={styles.balance}
                      value={statistics?.totalUsers}
                    /> */}
                    <span>this week</span>
                  </div>
                </div>
                {/* <Chart className={styles.chart} item={x} /> */}
              </div>
            </div>
            <div
              style={{
                backgroundColor: `${bgColors.background}`,

                padding: "10px",
                borderRadius: "10px",
              }}
              className={styles.item}
            >
              <div className={styles.icon}>
                <Icon name="payment" size="24" />{" "}
              </div>
              <div className={styles.line}>
                <div className={styles.details}>
                  <div className={styles.category}>
                    Total Citizens
                    {/* <Tooltip
                      className={styles.tooltip}
                      title='Small description'
                      icon='info'
                      place='right'
                    /> */}
                    <CustomTooltip
                      content={
                        isLoading ? "*****" : statistics?.totalIndividual
                      }
                    >
                      <Icon name="info" />{" "}
                    </CustomTooltip>
                  </div>
                  <div className={styles.counter}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <CurrencyFormatter
                          amount={statistics?.totalIndividual}
                        />

                        {/* {statistics?.totalIndividual} */}
                      </>
                    )}
                  </div>
                  <div className={styles.indicator}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <Balance
                          className={styles.balance}
                          value={statistics?.totalIndividual}
                        />
                      </>
                    )}

                    <span>this week</span>
                  </div>
                </div>
                {/* <Chart className={styles.chart} item={x} /> */}
              </div>
            </div>
            <div
              style={{
                backgroundColor: `${bgColors.background}`,

                padding: "10px",
                borderRadius: "10px",
              }}
              className={styles.item}
            >
              <div className={styles.icon}>
                <Icon name="shopping-bag" size="24" />{" "}
              </div>
              <div className={styles.line}>
                <div className={styles.details}>
                  <div className={styles.category}>
                    Total Businesses
                    {/* <Tooltip
                      className={styles.tooltip}
                      title='Small description'
                      icon='info'
                      place='right'
                    /> */}
                    <CustomTooltip
                      content={
                        isLoading ? "******" : statistics?.totalCorporate
                      }
                    >
                      <Icon name="info" />{" "}
                    </CustomTooltip>
                  </div>
                  <div className={styles.counter}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <CurrencyFormatter
                          amount={statistics?.totalCorporate}
                        />
                        {/* {statistics?.totalCorporate} */}
                      </>
                    )}

                    {/* {statistics?.totalCorporate} */}
                  </div>
                  <div className={styles.indicator}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <Balance
                          className={styles.balance}
                          value={statistics?.totalCorporate}
                        />
                      </>
                    )}
                    <span>this week</span>
                  </div>
                </div>
                {/* <Chart className={styles.chart} item={x} /> */}
              </div>
            </div>
            <div
              style={{
                backgroundColor: `${bgColors.background}`,

                padding: "10px",
                borderRadius: "10px",
              }}
              className={styles.item}
            >
              <div className={styles.icon}>
                <Icon name="home" size="24" />{" "}
              </div>
              <div className={styles.line}>
                <div className={styles.details}>
                  <div className={styles.category}>
                    Total Schools
                    {/* <Tooltip
                      className={styles.tooltip}
                      title='Small description'
                      icon='info'
                      place='right'
                    /> */}
                    <CustomTooltip
                      content={isLoading ? "*******" : statistics?.totalSchools}
                    >
                      <Icon name="info" />{" "}
                    </CustomTooltip>
                  </div>
                  <div className={styles.counter}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <CurrencyFormatter amount={statistics?.totalSchools} />
                        {/* {statistics?.totalSchools} */}
                      </>
                    )}
                  </div>
                  <div className={styles.indicator}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <Balance
                          className={styles.balance}
                          value={statistics?.totalSchools}
                        />
                      </>
                    )}

                    <span>this week</span>
                  </div>
                </div>
                {/* <Chart className={styles.chart} item={x} /> */}
              </div>
            </div>
            <div
              style={{
                backgroundColor: `${bgColors.background}`,

                padding: "10px",
                borderRadius: "10px",
              }}
              className={styles.item}
            >
              <div className={styles.icon}>
                <Icon name="home" size="24" />{" "}
              </div>
              <div className={styles.line}>
                <div className={styles.details}>
                  <div className={styles.category}>
                    Total Students
                    {/* <Tooltip
                      className={styles.tooltip}
                      title='Small description'
                      icon='info'
                      place='right'
                    /> */}
                    <CustomTooltip
                      content={isLoading ? "*******" : statistics?.totalStudent}
                    >
                      <Icon name="info" />{" "}
                    </CustomTooltip>
                  </div>
                  <div className={styles.counter}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <CurrencyFormatter amount={statistics?.totalStudent} />

                        {/* {statistics?.totalProperties} */}
                      </>
                    )}
                  </div>
                  <div className={styles.indicator}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <Balance
                          className={styles.balance}
                          value={statistics?.totalStudent}
                        />
                      </>
                    )}

                    <span>this week</span>
                  </div>
                </div>
                {/* <Chart className={styles.chart} item={x} /> */}
              </div>
            </div>
            <div
              style={{
                backgroundColor: `${bgColors.background}`,

                padding: "10px",
                borderRadius: "10px",
              }}
              className={styles.item}
            >
              <div className={styles.icon}>
                <Icon name="home" size="24" />{" "}
              </div>
              <div className={styles.line}>
                <div className={styles.details}>
                  <div className={styles.category}>
                    Verified Properties
                    {/* <Tooltip
                      className={styles.tooltip}
                      title='Small description'
                      icon='info'
                      place='right'
                    /> */}
                    <CustomTooltip
                      content={
                        isLoading ? "*******" : statistics?.totalProperties
                      }
                    >
                      <Icon name="info" />{" "}
                    </CustomTooltip>
                  </div>
                  <div className={styles.counter}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <CurrencyFormatter
                          amount={statistics?.totalProperties}
                        />

                        {/* {statistics?.totalProperties} */}
                      </>
                    )}
                  </div>
                  <div className={styles.indicator}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <Balance
                          className={styles.balance}
                          value={statistics?.totalProperties}
                        />
                      </>
                    )}

                    <span>this week</span>
                  </div>
                </div>
                {/* <Chart className={styles.chart} item={x} /> */}
              </div>
            </div>
            <div
              style={{
                backgroundColor: `${bgColors.background}`,

                padding: "10px",
                borderRadius: "10px",
              }}
              className={styles.item}
            >
              <div className={styles.icon}>
                <Icon name="home" size="24" />{" "}
              </div>
              <div className={styles.line}>
                <div className={styles.details}>
                  <div className={styles.category}>
                    Total Properties
                    {/* <Tooltip
                      className={styles.tooltip}
                      title='Small description'
                      icon='info'
                      place='right'
                    /> */}
                    <CustomTooltip
                      content={
                        isLoading
                          ? "*******"
                          : statistics?.totalUnverifiedProperties
                      }
                    >
                      <Icon name="info" />{" "}
                    </CustomTooltip>
                  </div>
                  <div className={styles.counter}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <CurrencyFormatter
                          amount={statistics?.totalUnverifiedProperties}
                        />

                        {/* {statistics?.totalUnverifiedProperties} */}
                      </>
                    )}
                  </div>
                  <div className={styles.indicator}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <Balance
                          className={styles.balance}
                          value={statistics?.totalUnverifiedProperties}
                        />
                      </>
                    )}

                    <span>this week</span>
                  </div>
                </div>
                {/* <Chart className={styles.chart} item={x} /> */}
              </div>
            </div>
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Overview;
