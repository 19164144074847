import "./CustomTooltip.css"; // We'll create this CSS file later

// src/CustomTooltip.js
import React, { useState } from "react";

import Formatter from "../Formatter/Formatter";
import { useLocation } from "react-router";

const CustomTooltip = ({ content, children, symbol }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { pathname } = useLocation();

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  // console.log(pathname)
  return (
    <div
      className="custom-tooltip-wrapper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        <div className="custom-tooltip">
          <Formatter amount={content} currency={symbol} />
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
