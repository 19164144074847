import React from "react";
import cn from "classnames";
import styles from "./Table.module.sass";
import { useNavigate } from "react-router";
import Formatter from "../../../components/Formatter/Formatter";
import TextInput from "../../../components/TextInput";

function Items({ x }) {
  //   console.log(x, 'This is Id')
  const navigate = useNavigate();

  return (
    <>
      {/* {console.log(x, 'Stats')} */}
      <div className={styles.col}>
        <b style={{ color: "white" }}>{x.title}</b>
        <br />
        <p style={{ fontSize: "12px" }}>{x.description}</p>
      </div>
      <div className={styles.col}>
        {/* ${numberWithCommas(x.earnings.toFixed(2))} */}
        {/* ₦ <Formatter amount={x.total_revenue} /> */}
        {/* {x.description} */}
        <TextInput
          className={``}
          name="channel"
          type="text"
          placeholder="Enter MDA Name"
          required
          // icon="payment"
          value={x.value1}
          // onChange={handleChange1}
        />
      </div>
      <div className={styles.col}>
        <TextInput
          className={``}
          name="channel"
          type="text"
          placeholder="Enter MDA Name"
          required
          // icon="profile-circle"
          value={x.value2}
          // onChange={handleChange1}
        />
      </div>
      <div className={styles.col}>
        <button
          className={cn("button")}
          style={{ width: "100%" }}
          // onClick={() => updateRevenueHead(item.revenue_code)}
          // disabled={isLoading1 || dis}
        >
          Save
        </button>
      </div>
    </>
  );
}

export default Items;
