import React, { useState } from 'react'
import styles from '../ProductActivity.module.sass'
import cn from 'classnames'
import Balance from '../../../../../components/Balance'
import TextInput from '../../../../../components/TextInput'
import { makeApiCall } from '../../../../../lib/api'
import toaster from '../../../../../utils/toaster'

const Item = ({ className, item, revenueHead, index }) => {
  const MdaCode = JSON.parse(localStorage.getItem('MdaCode'))
  const MdaName = JSON.parse(localStorage.getItem('MdaName'))
  const [updatedValues, setUpdatedValues] = useState(
    revenueHead.map((item) => item.revenue_amount)
  )
  const [isLoading1, setIsLoading1] = useState(false)
  // setUpdatedValues(response.data.map((item) => item.revenue_amount))

  const [revenueamount, setRevenueAmount] = useState({
    revenue_head: '',
    revenue_amount: '',
    mda_id: '',
  })

  const dis =
    revenueamount.revenue_head === '' ||
    revenueamount.revenue_amount === '' ||
    revenueamount.mda_id === ''
  const handleChange = (e, index, x) => {
    // Update the updatedValues state as the user types
    const newValues = [...updatedValues]
    newValues[index] = e.target.value
    setUpdatedValues(newValues)

    setRevenueAmount({
      revenue_amount: e.target.value,
      revenue_head: x.revenue_head,
      mda_id: MdaCode,
    })
  }
  const updateRevenueHead = async (id) => {
    // e.preventDefault()
    setIsLoading1(true)
    const response = await makeApiCall(
      `/payment/revenue-heads/${id}`,
      'PATCH',
      revenueamount
    )
    //  setProducts(response)
    // console.log(response)
    if (response.status === 200) {
      toaster(`${response.message}`, 'success')

      // getRevenueHeads()
      setIsLoading1(false)
    } else {
      toaster('Something Went Wrong', 'error')
      setIsLoading1(false)
    }
  }
  // console.log(revenueamount, 'revenueamount')
  return (
    <>
      <div className={styles.row} key={index}>
        <div className={styles.col}>
          {/* <div className={styles.label}>Week</div> */}
          {item.revenue_head}
        </div>
        <div className={styles.col}>
          {/* <div className={styles.label}>Products</div> */}
          {item.revenue_code}
          {/* <Item className={styles.item} item={x.revenue_code} /> */}
        </div>
        <div className={styles.col}>
          {/* <div className={styles.label}>Views</div> */}
          <TextInput
            className={``}
            name='revenue_amount'
            type='number'
            required
            value={updatedValues[index]}
            onChange={(e) => handleChange(e, index, item)}
          />
          {/* {x.revenue_amount} */}
          {/* <Item className={styles.item} item={x.revenue_amount} /> */}
        </div>
        {/* <div className={styles.col}>
                <div className={styles.label}>Likes</div>
                <Item className={styles.item} item={x.likes} />
              </div>
              <div className={styles.col}>
                <div className={styles.label}>Comments</div>
                <Item className={styles.item} item={x.comments} />
              </div> */}

        <div className={styles.col}>
          <div className={styles.label}>Action</div>
          <button
            className={cn('button')}
            style={{ width: '100%' }}
            onClick={() => updateRevenueHead(item.revenue_code)}
            disabled={isLoading1 || dis}
          >
            {isLoading1 ? 'Updatating....' : 'Save'}
          </button>
          {/* <Item className={styles.item} item={x.comments} /> */}
        </div>
      </div>
    </>
  )
}

export default Item
