import React, { useState } from "react";
import styles from "./ProductsDashboard.module.sass";
import TooltipGlodal from "../../../../../components/TooltipGlodal";
// import Overview from './Overview'
import ProductActivity from "./ProductActivity";
// import ProductViews from './ProductViews'
// import Products from './Products'
import { NavLink, useLocation } from "react-router-dom";
// import Card from '../../../components/Card'
// import TextInput from '../../../components/TextInput'
// import AddingMdas from './AddingMda'
// import AddingRevenue from './AddingRevenue'
// import RevenueHeads from "../../RevenueHeads";

const SingleRevenue = ({ showRevenueHeads, setShowRevenueHeads }) => {
  const pathname = useLocation();
  // console.log(pathname.pathname)
  // const [showRevenueHeads, setShowRevenueHeads] = useState(false)
  return (
    <>
      <div className={styles.section}>
        <ProductActivity
          setShowRevenueHeads={setShowRevenueHeads}
          showRevenueHeads={showRevenueHeads}
        />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default SingleRevenue;
