import React from 'react'
import ProductsDashboard from './AllRevenue'

function Revenue() {
  return (
    <div>
      <ProductsDashboard />
    </div>
  )
}

export default Revenue
