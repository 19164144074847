import React, { useState } from 'react'
import Card from '../../../components/Card'
import TextInput from '../../../components/TextInput'
import styles from '../ProductsDashboard.module.sass'
import cn from 'classnames'
import { makeApiCall } from '../../../lib/api'
import toaster from '../../../utils/toaster'

function AddPaymentGateway({ setVisibleModal, getRevenueHeads }) {
  const [isLoading, setIsLoading] = useState(false)

  const [newPaymentDetails, setNewPaymentDetails] = useState({
    channel: '',
    channel_title: '',
    channel_desc: '',
    secret_key: '',
    public_key: '',
    channel_code: '',
  })
  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setNewPaymentDetails({
      ...newPaymentDetails,
      [name]: value,
    })
  }
  const createPaymentGateway = async () => {
    setIsLoading(true)
    const response = await makeApiCall(
      `/payment/methods`,
      'POST',
      newPaymentDetails
    )
    if (response.status === 200) {
      toaster(response.message, 'success')
      setVisibleModal(false)
      setIsLoading(false)
      getRevenueHeads()
    } else {
      toaster('Something Went Wrong', 'error')
      setIsLoading(false)
    }
  }
  // console.log(newPaymentDetails, 'newPaymentDetails')
  return (
    <>
      <Card
        className={styles.card}
        title='Add Payment Gateway'
        classTitle='title-blue'
      >
        <div style={{ marginBottom: '12px' }}>
          <TextInput
            className={``}
            name='channel'
            type='text'
            placeholder='Enter Channel Name'
            required
            icon='profile-circle'
            onChange={handleChange}
          />
        </div>
        <div style={{ marginBottom: '12px' }}>
          <TextInput
            className={``}
            name='channel_title'
            type='text'
            placeholder='Enter  Channel Title'
            required
            icon='mail'
            onChange={handleChange}
          />
        </div>
        <div style={{ marginBottom: '12px' }}>
          <TextInput
            className={``}
            name='channel_desc'
            type='text'
            placeholder='Enter Channel Desc'
            required
            icon='mail'
            onChange={handleChange}
          />
        </div>
        <div style={{ marginBottom: '12px' }}>
          <TextInput
            className={``}
            name='secret_key'
            type='text'
            placeholder='Enter Secret Key'
            required
            icon='lock'
            onChange={handleChange}
          />
        </div>
        <div style={{ marginBottom: '12px' }}>
          <TextInput
            className={``}
            name='public_key'
            type='text'
            placeholder='Enter Public Key'
            required
            icon='lock'
            onChange={handleChange}
          />
        </div>
        <div style={{ marginBottom: '12px' }}>
          <TextInput
            className={``}
            name='channel_code'
            type='text'
            placeholder='Enter Channel Code'
            required
            icon='lock'
            onChange={handleChange}
          />
        </div>
        <button
          className={cn('button')}
          style={{ width: '100%' }}
          onClick={() => createPaymentGateway()}
          // disabled={
          //   loginDetails.email === '' ||
          //   loginDetails.password === '' ||
          //   loading === true
          // }
        >
          {isLoading ? 'Please Wait ......' : 'Add'}
        </button>
      </Card>
    </>
  )
}

export default AddPaymentGateway
