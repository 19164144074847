import Cookies from "js-cookie";
import axios from "axios";
import { handleApiError } from "../utils";

// const token = Cookies.get('user_token')

export async function makeReportCall(
  url,
  method = "GET",
  data = {},
  contentType = "application/json",
  headers = {
    "Content-Type": contentType,
    Authorization: `Bearer ${Cookies.get("user_token")}`,
  },
  baseURL = process.env.REACT_APP_BASE_URL_2
) {
  try {
    const options = {
      method,
      url,
      headers,
      baseURL,
      [method === "GET" ? "params" : "data"]: data,
    };

    if (contentType === "multipart/form-data") {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      options.data = formData;
    }

    const response = await axios(options);
    return response.data;
  } catch (error) {
    handleApiError(error);
    console.log(error, "This is api Error");
  }
}
