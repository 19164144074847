import { HTMLProps, ReactNode, useEffect, useRef } from "react";

import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import toaster from "./utils/toaster";

export const numberWithCommas = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const progress = () => {
  return Math.floor(Math.random() * 90) + 10 + "%";
};

// utils/dateUtils.js
export function getCurrentYearAndMonth() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1

  return { currentYear, currentMonth, currentDate };
}

// type ScrollableDivProps = {
// 	children: ReactNode;
// 	whileScrollToEnd?: () => any;
// } & HTMLProps<HTMLDivElement>;

export default function ScrollableDiv(props) {
  const { children, whileScrollToEnd } = props;

  const elementRef = useRef();

  useEffect(() => {
    let element = elementRef.current;

    if (!element) return;

    function handleScroll(e) {
      if (
        Math.abs(
          e.target.clientHeight + e.target.scrollTop - e.target.scrollHeight
        ) < 2
      ) {
        whileScrollToEnd && whileScrollToEnd();
      }
    }
    element.addEventListener("scroll", handleScroll);

    return () => element.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whileScrollToEnd]);

  return (
    <div
      className={`${props.className} overflow-auto`}
      {...props}
      ref={elementRef}
    >
      {children}
    </div>
  );
}

export function handleApiError(error, navigate) {
  // const navigate = useNavigate();

  console.log(error, "This is my error am having");
  if (error?.response?.status !== 200) {
    // Perform logout logic here, e.g., clearing user authentication
    console.log("User is unauthorized. Logging out...");
    Cookies.remove("user_token");
    Cookies.remove("user_details");
    toaster("Oops Something Went Wrong", "error");
    // You might want to redirect the user to a login page or perform other actions.
    // Change the URL
    // window.history.pushState({}, "", "/");
    navigate("/");

    // Navigate to the home page without a full page reload
    // window.location.href = "/";
  } else {
    // Handle other types of errors or show an error message to the user.
    console.error("API error:", error);
    // Change the URL
    // window.history.pushState({}, "", "/");
    navigate("/");

    // Navigate to the home page without a full page reload
    // window.location.href = "/";

    Cookies.remove("user_token");
    Cookies.remove("user_details");
  }
}
