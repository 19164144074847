import React, { useEffect, useState } from "react";
// import Dropdown from '../../../../components/Dropdown'
import { useLocation, useNavigate } from "react-router";
import "react-datepicker/dist/react-datepicker.css";

import Card from "../../../../../../components/Card";
import Form from "../../../../../../components/Form";
import Formatter from "../../../../../../components/Formatter/Formatter";
import DatePicker from "react-datepicker";
import useDarkMode from "use-dark-mode";
import Loader from "../../../../../../components/Loader";
import Modal from "../../../../../../components/Modal";
import Success from "../../../../../Payouts/Overview/Success";
import cn from "classnames";
import dateFormat from "dateformat";
import { makeForestryCall } from "../../../../../../lib/api";

import styles from "./ProductActivity.module.sass";
import toaster from "../../../../../../utils/toaster";
import NotFound from "../../../../../NotFound";

const ProductActivity = ({ showRevenueHeads, setShowRevenueHeads }) => {
  // console.log(MdaName);
  const intervals = ["Last 2 weeks", "Last 7 days"];
  // const pathname = useLocation();
  // const navigate = useNavigate();
  // console.log(pathname.pathname)

  const [activeTab, setActiveTab] = useState(intervals[0]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const darkMode = useDarkMode(false);
  const Mode = localStorage.getItem("darkMode");
  const [selectedDate2, setSelectedDate2] = useState(new Date());

  // const [activeIndex, setActiveIndex] = useState(0);
  // const [search, setSearch] = useState("");
  // const [visible, setVisible] = useState(false);
  // const [citizens, setCitizens] = useState([]);
  // const [business, setBusiness] = useState([]);
  // const navigate = useNavigate();
  // const { pathname } = useLocation();
  // const [isLoading, setIsLoading] = useState(false);
  // const [fetchLimit, setFetchLimit] = useState(10);
  // const [nodata, setNodata] = useState(false);
  // const [totalLength, setTotalLength] = useState(0);
  // const [page, setPage] = useState(0);
  // const [hasMore, setHasMore] = useState(true);
  // const inc = 10;
  const [citizens, setCitizens] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [nodata, setNodata] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const today = startDate.toISOString().slice(0, 10); // Get current date in 'YYYY-MM-DD' format
  const nextDate = selectedDate2.toISOString().slice(0, 10); // Get current date in 'YYYY-MM-DD' format
  // console.log(pathname)

  const getIndividualUsers = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeForestryCall(
      `/forestry/report?type=enumerations&startDate=${today}&endDate=${nextDate}`
    );
    //  co(response)
    if (response.status === 200) {
      let data = response?.data;
      console.log(response);
      // if (citizens.length >= response.meta.total_items) {
      //   setHasMore(false);
      //   setIsLoading(false);
      //   return;
      // }
      if (data.length === 0) {
        setNodata(true);
      }
      setCitizens(data);
      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getIndividualUsers();
  }, [fetchLimit, startDate, selectedDate2]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop ===
  //       document.documentElement.offsetHeight
  //     ) {
  //       if (hasMore === false) return;
  //       // Check if there is more data to fetch
  //       // Increment page and fetchLimit
  //       // setPage((prevPage) => prevPage + 1)
  //       // console.log('ddd', hasMore)
  //       setFetchLimit((prevFetched) => prevFetched + inc);
  //     }
  //   };
  //   // Attach the event listener to the scroll event
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     // Clean up the event listener on unmount
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [hasMore]);

  return (
    <>
      <Card
        className={styles.card}
        title="Most Recent  Reports"
        classTitle="title-green"
        head={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DatePicker
              selected={startDate}
              className={`custom-datepicker-input ${
                Mode === "true" ? "mode-dark" : "mode-light"
              }`}
              onChange={(date) => setStartDate(date)}
            />
            <DatePicker
              selected={selectedDate2}
              className={`custom-datepicker-input ${
                Mode === "true" ? "mode-dark" : "mode-light"
              }`}
              onChange={(date) => setSelectedDate2(date)}
            />
          </div>
        }
      >
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Loader className={styles.loader} />
          </div>
        ) : nodata === true ? (
          <NotFound />
        ) : (
          // Here
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Name</div>
              <div className={styles.col}>Reference</div>
              <div className={styles.col}>Amount</div>
              <div className={styles.col}>Status</div>
              <div className={styles.col}>Date</div>
            </div>
            {citizens?.map((x, index) => (
              <div className={styles.row} key={index}>
                <>
                  <div className={styles.col}>
                    {/* <div className={styles.label}>Name</div> */}

                    {x.payer_name}
                  </div>

                  <div className={styles.col}>
                    {/* <div className={styles.label}>Merchant Name</div> */}
                    {x.reference}
                  </div>
                  <div className={styles.col}>
                    {/* <div className={styles.label}>Code</div> */} ₦
                    <Formatter amount={x?.amount} />
                    {/* {x.amount} */}
                  </div>
                  <div className={styles.col}>
                    {/* <div className={styles.label}>Company ID</div> */}
                    {/* {x?.status} */}
                    {x.status === "Approved" ? (
                      <div
                        className={cn(
                          { "status-green-dark": x.status === "Approved" },
                          styles.status
                        )}
                      >
                        Approved
                      </div>
                    ) : (
                      <div
                        className={cn(
                          {
                            "status-red-dark":
                              x.status === "Payment Reference Generated",
                          },
                          styles.status
                        )}
                      >
                        Payment Reference Generated
                      </div>
                    )}
                  </div>
                  <div className={styles.col}>
                    {/* <div className={styles.label}>Company ID</div> */}
                    {/* {x?.generation_date} */}
                    {dateFormat(x?.generation_date, "dd-mm-yyyy")}
                  </div>
                </>

                {/* <div className={styles.col}>
                  <div className={styles.label}>Comments</div>
                  <Item className={styles.item} item={x.comments} />
                </div> */}
              </div>
            ))}
          </div>
        )}
        <div className={styles.nav}>
          {intervals.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: x === activeTab,
              })}
              onClick={() => setActiveTab(x)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Success />
      </Modal>
    </>
  );
};

export default ProductActivity;
