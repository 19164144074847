import React from "react";
import TextInput from "../../../../../components/TextInput";
import cn from "classnames";
import styles from "./Table.module.sass";
import { useNavigate } from "react-router";

function Items({ x }) {
  //   console.log(x, 'This is Id')
  const navigate = useNavigate();

  return (
    <>
      {/* {console.log(x, 'Stats')} */}
      <div className={styles.col}>
        {x.revenue_head}
        {/* <br />
        <p style={{ fontSize: '12px' }}>{x.mda_code}</p> */}
      </div>
      <div className={styles.col}>
        {/* ${numberWithCommas(x.earnings.toFixed(2))} */}
        {x.revenue_code}
      </div>
      <div className={styles.col}>{x.service_type_id}</div>
      <div className={styles.col}>
        {/* {x.service_type_id} */}
        <TextInput
          className={``}
          name="channel"
          type="text"
          // placeholder='Enter MDA Name'
          required
          // icon='profile-circle'
          value={x?.revenue_amount}
          // onChange={handleChange1}
        />
      </div>
      <div className={styles.col}>
        <button
          className={cn("button-stroke button-small", styles.button)}
          // onClick={() => navigate(`/payment/overview/details/${x.mda_code}`)}
        >
          Save
          {/* <Loader className={styles.loader} /> */}
          {/* <span>Load more</span> */}
        </button>
      </div>
    </>
  );
}

export default Items;
