import React, { useState } from 'react'
import styles from './ProductsDashboard.module.sass'
import TooltipGlodal from '../../components/TooltipGlodal'
import Overview from './Overview'
import ProductActivity from './ProductActivity'
import ProductViews from './ProductViews'
import Products from './Products'
import { NavLink, useLocation } from 'react-router-dom'
import Card from '../../components/Card'
import TextInput from '../../components/TextInput'
import AddingMdas from './AddingTaxOfficers'
import AddingRevenue from './AddingRevenue'
import RevenueHeads from '../RevenueHeads'

const TaxOfficesDash = () => {
  // const pathname = useLocation()
  // // console.log(pathname.pathname)
  return (
    <>
      <div className={styles.section}>
        <ProductActivity />
      </div>
      <TooltipGlodal />
    </>
  )
}

export default TaxOfficesDash
