import "react-datepicker/dist/react-datepicker.css";
import "./customcss.css";

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { useEffect, useState } from "react";

import Card from "../../../components/Card";
import CustomDatePicker from "../../../utils/DatePicker";
import DatePicker from "react-datepicker";
import Dropdown from "../../../components/Dropdown";
import Loader from "../../../components/Loader";
import NotFound from "../../NotFound";
import cn from "classnames";
import { handleApiError } from "../../../utils";
import { makeApiCall } from "../../../lib/api";
import styles from "./ProductViews.module.sass";
import useDarkMode from "use-dark-mode";

// const intervals = ["Last 7 days", "This month", "All time"];

// const data = [
//   {
//     name: "22",
//     views: 27,
//   },
//   {
//     name: "23",
//     views: 22,
//   },
//   {
//     name: "24",
//     views: 32,
//   },
//   {
//     name: "25",
//     views: 18,
//   },
//   {
//     name: "26",
//     views: 27,
//   },
//   {
//     name: "27",
//     views: 15,
//   },
//   {
//     name: "28",
//     views: 21,
//   },
// ];

const ProductViews = ({ className }) => {
  const darkMode = useDarkMode(false);
  const Mode = localStorage.getItem("darkMode");

  // const [selectedDate, setSelectedDate] = useState(today);
  const [startDate, setStartDate] = useState(new Date());

  const [selectedDate2, setSelectedDate2] = useState(new Date());
  const [statistics, setStatistics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noValueData, setNoValueData] = useState(false);
  // function areAllValuesZero(arr) {
  //   return arr?.every((item) => item.value === 0 || item.value === null);
  // }
  const today = startDate.toISOString().slice(0, 10); // Get current date in 'YYYY-MM-DD' format
  const nextDate = selectedDate2.toISOString().slice(0, 10); // Get current date in 'YYYY-MM-DD' format

  const getProducts = async () => {
    try {
      setIsLoading(true);
      const response = await makeApiCall(
        `app-request?date1=${today}&date2=${nextDate}`
      );

      if (response.status === 200) {
        setStatistics(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      // Handle errors here, if necessary
      console.error(error);
      setIsLoading(false);
      handleApiError(error);
    }
  };

  useEffect(() => {
    getProducts();
    // if (areAllValuesZero(statistics)) {
    //   setNoValueData(true);
    // }
  }, [startDate, selectedDate2]);
  useEffect(() => {
    if (statistics.length === 0) {
      setNoValueData(true);
    } else {
      setNoValueData(false);
    }
  }, [statistics]);

  // const handleDateChange = (event) => {
  //   setStartDate(event.target.value);
  //   // console.log(selectedDate, "Date");
  // };
  // const handleDateChange2 = (event) => {
  //   setSelectedDate2(event.target.value);
  //   console.log(selectedDate, "Date2");
  // };
  console.log(noValueData, "noValueData");
  return (
    <Card
      className={cn(styles.card, className)}
      title="Average Daily Usage/API Calls"
      classTitle="title-purple"
      head={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DatePicker
            selected={startDate}
            className={`custom-datepicker-input ${
              Mode === "true" ? "mode-dark" : "mode-light"
            }`}
            onChange={(date) => setStartDate(date)}
          />
          <DatePicker
            selected={selectedDate2}
            className={`custom-datepicker-input ${
              Mode === "true" ? "mode-dark" : "mode-light"
            }`}
            onChange={(date) => setSelectedDate2(date)}
          />
        </div>
      }
    >
      <div className={styles.chart}>
        <ResponsiveContainer width="100%" height="100%">
          {/* {noValueData === true ? <NotFound /> : ""} */}
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Loader className={styles.loader} />
            </div>
          ) : noValueData ? (
            <NotFound />
          ) : (
            <BarChart
              width={500}
              height={300}
              data={statistics}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              barSize={32}
              barGap={8}
            >
              <CartesianGrid
                strokeDasharray="none"
                stroke={darkMode.value ? "#272B30" : "#EFEFEF"}
                vertical={false}
              />
              <XAxis
                dataKey="appName"
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12, fontWeight: "500", fill: "#6F767E" }}
                padding={{ left: 10 }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12, fontWeight: "500", fill: "#6F767E" }}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: "#272B30",
                  borderColor: "rgba(255, 255, 255, 0.12)",
                  borderRadius: 8,
                  boxShadow:
                    "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
                }}
                labelStyle={{ fontSize: 12, fontWeight: "500", color: "#fff" }}
                itemStyle={{
                  padding: 0,
                  textTransform: "capitalize",
                  fontSize: 12,
                  fontWeight: "600",
                  color: "#fff",
                }}
                cursor={{ fill: "#f3f2f3" }}
              />
              <Bar dataKey="request_count" fill="#B5E4CA" />
            </BarChart>
          )}
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default ProductViews;
