import React, { useEffect, useState } from "react";
import styles from "./ProductActivity.module.sass";
import cn from "classnames";

// import Dropdown from '../../../components/Dropdown'
// import { useLocation, useNavigate } from 'react-router'

import { Link } from "react-router-dom";
// import Icon from '../../../components/Icon'


import { makeApiCall } from "../../../../../lib/api";
import toaster from "../../../../../utils/toaster";
import Loader from "../../../../../components/Loader";
import Card from "../../../../../components/Card";
import Modal from "../../../../../components/Modal";
import Success from "../../../../Payouts/Overview/Success";
import Form from "../../../../../components/Form";



// import TextInput from '../../../components/TextInput'
// import Item from './Item'
const ProductActivity = ({ showRevenueHeads, setShowRevenueHeads }) => {
  const MdaCode = JSON.parse(localStorage.getItem("MdaCode"));
  const MdaName = JSON.parse(localStorage.getItem("MdaName"));
  const intervals = ["Last 2 weeks", "Last 7 days"];
  // const pathname = useLocation()
  // const navigate = useNavigate()
  // console.log(pathname.pathname)
  const [activeTab, setActiveTab] = useState(intervals[0]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [revenueHead, setRevenueHead] = useState();
  const [features, setFeatures] = useState([]);
  const [showFeatureUpdateModal, setShowFeatureUpdateModal] = useState(false);
  const filters = [];

  const getRevenueHeads = async () => {
    setIsLoading(true);
    const response = await makeApiCall(`/app-roles?size=20`);
    //  setProducts(response)
    console.log(response, "This is app services response");
    if (response.status === 200) {
      setRevenueHead(response.data);
      // setUpdatedValues(response.data.map((item) => item.revenue_amount))

      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };
  const getFeatures = async () => {
    setIsLoading(true);
    const response = await makeApiCall(`/roles/features/feature`, "GET");
    if (response.status === 200) {
      // toaster(response.message, "success");
      // console.log(response);
      // setFeatures(response.data);
      setIsLoading(false);
      // console.log(response);
      let data = response.data;
      setFeatures(data);
      for (const object of data) {
        filters.push(object.name);
      }
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRevenueHeads();
    getFeatures();
  }, []);

  // const handleChange = (e, index) => {
  //   const { name, value } = e.target
  //   const newValues = [...updatedValues]
  //   newValues[index] = { ...newValues[index], [name]: value }
  //   setUpdatedValues(newValues)
  // }
  // console.log(showFeatureUpdateModal);
  return (
    <>
      <Card
        className={styles.card}
        title="All Services"
        classTitle="title-green"
        head={
          <>
            <Form
              className={styles.form}
              // value={search}
              // setValue={setSearch}
              // onSubmit={() => handleSubmit()}
              placeholder={`Search Roles`}
              type="text"
              name="search"
              icon="search"
            />
            <Link
              className={cn("button-stroke button-small", styles.button)}
              to=""
              style={{ marginLeft: "12px" }}
              // onClick={goBack}
              onClick={() => setVisibleModal(true)}
            >
              {/* <Icon name='arrow-left' size='18' /> */}

              <span>Add Roles</span>
            </Link>
          </>
        }
      >
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Loader className={styles.loader} />
          </div>
        ) : (
          <div className={styles.table}>
            <div className={styles.row}>
              {/* <div className={styles.col}>S/N</div> */}
              <div className={styles.col} style={{ width: "40%" }}>
                Service
              </div>
              <div className={styles.col} style={{ width: "40%" }}>
                Label{" "}
              </div>
              <div className={styles.col} style={{ width: "40%" }}>
                Action
              </div>
            </div>
            {revenueHead?.map(
              (x, index) => (
                <div className={styles.row} key={index}>
                  {/* <div className={styles.col}> */}
                  {/* <div className={styles.label}>Week</div> */}
                  {/* {x.id} */}
                  {/* </div> */}
                  <div className={styles.col} style={{ width: "40%" }}>
                    {/* <div className={styles.label}>Products</div> */}

                    {x?.service}
                    {/* <Item className={styles.item} item={x.revenue_code} /> */}
                  </div>
                  <div className={styles.col} style={{ width: "40%" }}>
                    {/* <div className={styles.label}>Views</div> */}
                    {/* <TextInput
            className={``}
            name='revenue_amount'
            type='number'
            required
            value={updatedValues[index]}
            onChange={(e) => handleChange(e, index, item)}
          /> */}
                    {x?.label}
                    {/* {x.features.length}
                    {"  "}
                    {x.features.length > 1 ? "Features" : "Feature"} */}
                    {/* <Item className={styles.item} item={x.revenue_amount} /> */}
                  </div>
                  {/* <div className={styles.col}>
                <div className={styles.label}>Likes</div>
                <Item className={styles.item} item={x.likes} />
              </div>
              <div className={styles.col}>
                <div className={styles.label}>Comments</div>
                <Item className={styles.item} item={x.comments} />
              </div> */}

                  <div
                    className={styles.col}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // border: "2px solid red",
                      // width: "40%",
                    }}
                  >
                    <button
                      className={cn("button")}
                      // style={{ width: "10rem" }}
                      // onClick={() => updateRevenueHead(item.revenue_code)}
                      onClick={() => (
                        // console.log(x),
                        setVisibleModal(true),
                        setShowFeatureUpdateModal(true),
                        localStorage.setItem("Feature", JSON.stringify(x))
                      )}
                    >
                      {"Edit"}
                    </button>
                    <button
                      className={cn("button")}
                      style={{ background: "red" }}
                      // onClick={() => updateRevenueHead(item.revenue_code)}
                      onClick={() => (
                        // console.log(x),
                        setVisibleModal(true),
                        setShowFeatureUpdateModal(true),
                        localStorage.setItem("Feature", JSON.stringify(x))
                      )}
                    >
                      {"Delete"}
                    </button>
                    {/* <Item className={styles.item} item={x.comments} /> */}
                  </div>
                </div>
              )
              // console.log(x)

              //<Item item={x} index={index} revenueHead={revenueHead} />
            )}
          </div>
        )}
        <div className={styles.nav}>
          {intervals.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: x === activeTab,
              })}
              onClick={() => setActiveTab(x)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => (
          setVisibleModal(false), setShowFeatureUpdateModal(false)
        )}
        setShowFeatureUpdateModal={setShowFeatureUpdateModal}
      >
        <Success
          getRevenueHeads={getRevenueHeads}
          showFeatureUpdateModal={showFeatureUpdateModal}
          setVisibleModal={setVisibleModal}
          filters={features}
        />
      </Modal>
    </>
  );
};

export default ProductActivity;
