import React, { useEffect, useState } from "react";

import Balance from "../../../../../components/Balance";
import Card from "../../../../../components/Card";
import CurrencyFormatter from "../../../../../utils/CurrencyFormatter";
import CustomTooltip from "../../../../../components/CustomTooltip";
import Icon from "../../../../../components/Icon";
import Tooltip from "../../../../../components/Tooltip";
import TooltipGlodal from "../../../../../components/TooltipGlodal";
import cn from "classnames";
import { getCurrentYearAndMonth } from "../../../../../utils";
import { makeApiCall } from "../../../../../lib/api";
import { makeReportCall } from "../../../../../lib/reportsApi";
import { refetchPayment } from "../../../../../firebase";
import styles from "./Overview.module.sass";
import toaster from "../../../../../utils/toaster";
import { useLocation } from "react-router";
import Debounce from "../../../../../utils/debounce";

// import { Tooltip } from 'react-tooltip'
const items = [
  {
    title: "Earning",
    counter: "$128k",
    icon: "activity",
    color: "#B5E4CA",
    tooltip: "Small description Earning",
    value: 37.8,
  },
  {
    title: "Balance",
    counter: "$512.64",
    icon: "pie-chart",
    color: "#CABDFF",
    tooltip: "Small description Balance",
    value: -17.8,
  },
  {
    title: "Total value of sales",
    counter: "$64k",
    icon: "shopping-bag",
    color: "#B1E5FC",
    tooltip: "Small description Total",
    value: 24.3,
  },
];

const Overview = ({ className }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const { currentYear, currentMonth } = getCurrentYearAndMonth();

  // console.log(id, currentMonth, currentYear)
  const [mdaPaymentRecords, setMdaPaymentRecords] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getStatistics = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeReportCall(
      // `/payment/mdaDaily/${id}/?year=${currentYear}&month=${currentMonth}`
      // `formal-sector/formal-statistics?year=${currentYear}`
      // `informal-sector/business-statistics?year=${currentYear}`
      `informal-sector/business-statistics?year=${currentYear}`
    );

    // console.log(response)
    if (response.status === 200) {
      console.log(response, "------");
      setMdaPaymentRecords(response.data);
      // console.log(response);

      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getStatistics();
  }, []);
  Debounce(refetchPayment(getStatistics), 2500);

  return (
    <div style={{ marginBottom: "2rem" }}>
      {/* <div className={styles.counter}>Revenue for the Year {currentYear}</div> */}

      <Card className={cn(styles.card, className)}>
        <div className={styles.overview}>
          <div className={styles.list}>
            <div className={styles.item}>
              <div
                className={styles.icon}
                style={{ backgroundColor: items[0].color }}
              >
                <Icon name="activity" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.label}>
                  Today’s Payments
                  <CustomTooltip
                    content={mdaPaymentRecords?.today_payment}
                    className={styles.tooltip}
                    symbol="NGN"
                  >
                    <Icon name="info" fill="#6F767E" />
                  </CustomTooltip>
                  {/* <Tooltip
                    className={styles.tooltip}
                    title='Hello'
                    icon='info'
                    place='right'
                  /> */}
                </div>
                <div className={styles.counter}>
                  {isLoading ? (
                    "*********"
                  ) : (
                    <CurrencyFormatter
                      amount={mdaPaymentRecords?.today_payment}
                      currencySymbol="₦"
                    />
                  )}
                </div>
                {/* <div className={styles.indicator}>
                  <Balance className={styles.balance} value={x.value} />
                  <span>this week</span>
                </div> */}
              </div>
            </div>
            <div className={styles.item}>
              <div
                className={styles.icon}
                style={{ backgroundColor: items[0].color }}
              >
                <Icon name="pie-chart" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.label}>
                  Overall Payments
                  <CustomTooltip
                    content={mdaPaymentRecords?.total_year_payment}
                    className={styles.tooltip}
                    symbol="NGN"
                  >
                    <Icon name="info" fill="#6F767E" />
                  </CustomTooltip>
                  {/* <Tooltip
                    className={styles.tooltip}
                    title={x.tooltip}
                    icon='info'
                    place='top'
                  /> */}
                </div>
                <div className={styles.counter}>
                  {isLoading ? (
                    "*********"
                  ) : (
                    <CurrencyFormatter
                      amount={mdaPaymentRecords?.total_year_payment}
                      currencySymbol="₦"
                    />
                  )}
                  {/* {mdaPaymentRecords?.revenue_statistics?.sum_total_year_payment} */}
                </div>
                {/* <div className={styles.indicator}>
                  <Balance className={styles.balance} value={x.value} />
                  <span>this week</span>
                </div> */}
              </div>
            </div>
            <div className={styles.item}>
              <div
                className={styles.icon}
                style={{ backgroundColor: items[0].color }}
              >
                <Icon name="activity" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.label}>
                  No. of Payments
                  <CustomTooltip
                    content={mdaPaymentRecords?.number_of_payment}
                    className={styles.tooltip}
                    // symbol="NGN"
                  >
                    <Icon name="info" fill="#6F767E" />
                  </CustomTooltip>
                  {/* <Tooltip
                    className={styles.tooltip}
                    title="Hello sit down were you re"
                    icon="info"
                    place="top"
                  />
                  <Icon name="info" fill="#6F767E" /> */}
                </div>
                <div className={styles.counter}>
                  {isLoading ? (
                    "*********"
                  ) : (
                    // <CurrencyFormatter
                    //   amount={mdaPaymentRecords?.number_of_payment}
                    //   currencySymbol="₦"
                    // />
                    <>{mdaPaymentRecords?.number_of_payment}</>
                  )}
                  {/* {mdaPaymentRecords?.revenue_statistics?.sum_total_year_payment} */}
                </div>
                {/* <div className={styles.indicator}>
                  <Balance className={styles.balance} value={x.value} />
                  <span>this week</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </div>
  );
};

export default Overview;
