import React, { useState } from 'react'
import Card from '../../../components/Card'
import TextInput from '../../../components/TextInput'
import styles from '../ProductsDashboard.module.sass'
import cn from 'classnames'
import Dropdown from '../../../components/Dropdown'
const intervals = ['Select an Option', 'This month', 'This year']

function AddingRevenue() {
  const [sorting, setSorting] = useState(intervals[0])

  return (
    <>
      <Card
        className={styles.card}
        title='Add Revenue Head'
        classTitle='title-blue'
      >
        <div style={{ marginBottom: '12px' }}>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={sorting}
            setValue={setSorting}
            options={intervals}
            small
          />
        </div>
        <div style={{ marginBottom: '12px' }}>
          <TextInput
            className={``}
            name='code'
            type='text'
            placeholder='Revenue Name'
            required
            icon='profile-circle'
            // onChange={handleUserInputs}
          />
        </div>
        <div style={{ marginBottom: '12px' }}>
          <TextInput
            className={``}
            name='code'
            type='text'
            placeholder='Revenue Code'
            required
            icon='lock'
            // onChange={handleUserInputs}
          />
        </div>
        <div style={{ marginBottom: '12px' }}>
          <TextInput
            className={``}
            name='code'
            type='text'
            placeholder='Revenue Amount (Optional)'
            required
            icon='payment'
            // onChange={handleUserInputs}
          />
        </div>
        <button
          className={cn('button')}
          style={{ width: '100%' }}
          // onClick={handleSubmit}
          // disabled={
          //   loginDetails.email === '' ||
          //   loginDetails.password === '' ||
          //   loading === true
          // }
        >
          {/* {loading ? (
            <ScaleLoader color='#fff' speedMultiplier={2} width={4} />
          ) : (
            'Login'
          )} */}
          Add
        </button>
      </Card>
    </>
  )
}

export default AddingRevenue
