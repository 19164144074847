import React, { useState } from "react";
import cn from "classnames";
import styles from "./Filters.module.sass";
import Card from "../../../components/Card";
import Checkbox from "../../../components/Checkbox";
import Radio from "../../../components/Radio";

const Filters = ({
  className,
  filters,
  selectedFilters,
  setSelectedFilters,
  title,
  classTitle,
  assignedFeatures,

  head,
}) => {
  const handleChange = (filter) => {
    // console.log(filter, "This is filters");
    if (selectedFilters.includes(filter.name)) {
      // If the filter is already selected, remove it
      setSelectedFilters(selectedFilters.filter((x) => x !== filter.name));
    } else {
      // If the filter is not selected, add it
      setSelectedFilters([...selectedFilters, filter.name]);
    }
  };

  // const [users, setUsers] = useState(false)

  return (
    // <Card
    //   className={cn(styles.card, className)}
    //   title='Assigned Functionalities'
    //   classTitle='title-purple'
    // >
    <>
      {title && (
        <div
          style={{ marginBottom: "18px", marginTop: "18px", textAlign: "left" }}
        >
          <div className={cn(classTitle, styles.title)}>{title}</div>
          {head && head}
        </div>
      )}
      <div className={cn(styles.filters, className)}>
        <div
          className={styles.group}
          style={{
            // border: '2px solid red',
            height: "300px",
            overflow: "scroll",
          }}
        >
          {filters.map((x, index) => {
            // const checked = assignedFeatures.includes(x?.name)
            return (
              <Checkbox
                className={styles.checkbox}
                content={x}
                // value={checked}
                value={selectedFilters?.includes(x?.name)}
                onChange={() => handleChange(x)}
                key={index}
                assignedFeatures={assignedFeatures}
                reverse
              />
            );
          })}
        </div>
        {/* <div className={styles.btns}>
          <button className={cn("button-stroke button-small", styles.button)}>
            Select all
          </button>
          <button className={cn("button-stroke button-small", styles.button)}>
            Unslect all
          </button>
        </div> */}
        {/* <div className={styles.variants}>
          <Radio
            className={styles.radio}
            name="confirm"
            value={users}
            onChange={() => setUsers(true)}
            content="Everyone"
          />
          <Radio
            className={styles.radio}
            name="confirm"
            value={!users}
            onChange={() => setUsers(false)}
            content="Customers"
          />
        </div> */}
      </div>
    </>
    // </Card>
  );
};

export default Filters;
