import Checkbox from '../../../../../components/Checkbox'
import { customers } from '../../../../../mocks/customers'
import styles from './Row.module.sass'
import cn from 'classnames'
import dateFormat, { masks } from 'dateformat'
import { useNavigate, useLocation, Link } from 'react-router-dom'

const Row = ({
  item,
  value,
  onChange,
  activeTable,
  setActiveTable,
  activeId,
  setActiveId,
}) => {
  const handleClick = (id) => {
    setActiveTable(true)
    setActiveId(id)
  }
  const { pathname } = useLocation()

  // console.log(item.properties, 'Here Items')

  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }
        )}
      >
        <div className={styles.col}>
          <Checkbox
            className={styles.checkbox}
            value={value}
            onChange={onChange}
          />
        </div>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.avatar}>
              <img
                src='	https://cross-river-pay.s3.us-east-2.amazonaws.com/cas-admin/images/content/user.jpg'
                alt='Avatar'
              />
            </div>
            <div className={styles.details}>
              <div className={styles.user}>
                {pathname === '/user-profile'
                  ? item?.properties?.address
                  : item.address}
              </div>
              <div className={styles.login}>
                {pathname === '/user-profile'
                  ? item?.properties?.longitude
                  : item.longitude}
              </div>
              <div className={styles.email}>
                {pathname === '/user-profile'
                  ? item?.properties?.goggleAddress
                  : item.goggleAddress}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.email}>
            {pathname === '/user-profile'
              ? item?.properties?.goggleAddress
              : item.goggleAddress}
          </div>
        </div>
        {/* <div className={styles.col}>
          <div className={cn('status-green-dark', styles.purchase)}>
            {item.latitude}
          </div>
        </div> */}
        <div className={styles.col}>
          <div className={styles.lifetime}>
            <div className={styles.price}>
              {pathname === '/user-profile'
                ? item?.properties?.propertyId
                : item.propertyId}
              {/* {dateFormat(item.createDate, 'dddd, mmmm dS, yyyy')} */}
            </div>
            {/* <Balance className={styles.balance} value={item.balance} /> */}
          </div>
        </div>
        {/* <div className={styles.col}>{item.comments}</div>
        <div className={styles.col}>{item.likes}</div> */}
      </div>
    </>
  )
}

export default Row
