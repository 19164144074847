import React, { useEffect, useState } from "react";
import styles from "./CustomerList.module.sass";
import cn from "classnames";
import Card from "../../components/Card";
import Form from "../../components/Form";
import Filters from "../../components/Filters";
import Settings from "./Settings";
import Table from "./Table";
import Panel from "./Panel";
import Details from "./Details";

import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useLocation, Link } from "react-router-dom";

import { makeApiCall } from "../../lib/api";
import Icon from "../../components/Icon";
import toaster from "../../utils/toaster";
import Loader from "../../components/Loader";
import NotFound from "../NotFound";
const navigation = ["Active", "New"];

const CustomerList = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [visible, setVisible] = useState(false);
  const [citizens, setCitizens] = useState([]);
  const [business, setBusiness] = useState([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [nodata, setNodata] = useState(false);
  const [totalLength, setTotalLength] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const inc = 10;

  // console.log(pathname)
  const goBack = () => {
    navigate(-1);
  };
  const handleSubmit = (e) => {
    alert();
  };
  const getIndividualUsers = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(
      `/users?type=INDIVIDUAL&size=${fetchLimit}&page=${page}`
    );
    //  co(response)
    if (response.status === 200) {
      let data = response?.data;
      if (citizens.length >= response.meta.total_items) {
        setHasMore(false);
        setIsLoading(false);
        return;
      }
      if (data.length === 0) {
        setNodata(true);
      }
      setCitizens(data);
      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };
  const getCoporates = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(
      `/users?type=COMPANY&size=${fetchLimit}&page=${page}`
    );
    // console.log(response, 'Coporate')
    if (response.status === 200) {
      if (citizens.length >= response.meta.total_items) {
        setHasMore(false);
        setIsLoading(false);
        return;
      }
      setCitizens(response?.data);

      // console.log(citizens)
      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };
  const getSchools = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(
      `/school/all?size=${fetchLimit}&page=${page}`
    );
    // console.log(response, 'Coporate')
    if (response.status === 200) {
      if (citizens.length >= response.meta.total_items) {
        setHasMore(false);
        setIsLoading(false);
        return;
      }
      setCitizens(response?.data);

      // console.log(citizens)
      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (pathname === "/business") {
      // getIndividualUsers()
      getCoporates();
    } else if (pathname === "/schools") {
      getSchools();
    } else {
      getIndividualUsers();
    }
  }, [fetchLimit]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        if (hasMore === false) return;
        // Check if there is more data to fetch
        // Increment page and fetchLimit
        // setPage((prevPage) => prevPage + 1)
        // console.log('ddd', hasMore)
        setFetchLimit((prevFetched) => prevFetched + inc);
      }
    };
    // Attach the event listener to the scroll event
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Clean up the event listener on unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasMore]);
  return (
    <>
      <Card
        className={styles.card}
        title={
          pathname === "/business"
            ? "Business"
            : pathname === "/citizens"
            ? "Citizens"
            : "Schools"
        }
        classTitle={cn("title-purple", styles.title)}
        classCardHead={cn(styles.head, { [styles.hidden]: visible })}
        head={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "end",
                width: "100%",
              }}
            >
              <Link
                className={cn("button-stroke button-small", styles.button)}
                to=""
                onClick={goBack}
              >
                <Icon name="arrow-left" size="24" />
                <span>Back</span>
              </Link>
            </div>
          </>
        }
      >
        <div className={cn(styles.row, { [styles.flex]: visible })}>
          {nodata === true ? (
            <NotFound />
          ) : (
            <Table
              className={styles.table}
              activeTable={visible}
              setActiveTable={setVisible}
              citizens={citizens}
              isLoading={isLoading}
            />
          )}
          {/* </InfiniteScroll> */}
          {/* <Details
            className={styles.details}
            onClose={() => setVisible(false)}
          /> */}
        </div>
        {/* <button className={cn("button-stroke button-small", styles.button)}>
          <Loader className={styles.loader} />
          <span>Load more</span>
        </button> */}
        {/* {isLoading ? (
          ""
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              className={cn("button-stroke button-small", styles.button)}
              onClick={handleScroll}
            >
              Load more
            </button>
          </div>
        )} */}
      </Card>
      {/* <Panel /> */}
    </>
  );
};

export default CustomerList;
