import React from 'react'
import ProductsDashboard from '../ProductsDashboard'
import { useLocation } from 'react-router'

function MDAS() {
  const pathname = useLocation()
  //   console.log(pathname)
  return (
    <div>
      <ProductsDashboard />
    </div>
  )
}

export default MDAS
