import React, { useEffect, useState } from 'react'
import Card from '../../../components/Card'
import TextInput from '../../../components/TextInput'
import styles from '../ProductsDashboard.module.sass'
import cn from 'classnames'
import DropdownNew from '../../../components/DropdownNew'
import { makeApiCall } from '../../../lib/api'
import toaster from '../../../utils/toaster'
import { ScaleLoader } from 'react-spinners'

const intervals = ['Select an Option', 'This month', 'This year']

function AddingRevenue({ setVisibleModal }) {
  // console.log(mdas, 'mdas')
  const [sorting, setSorting] = useState()
  const [actualVal, setActualVal] = useState()
  const [revenueData, setRevenueData] = useState({
    revenue_head: '',
    revenue_code: '',
    revenue_amount: '',
    mda_id: '',
  })
  const [isLoading, setIsLoading] = useState(false)

  // console.log(sorting, 'sorting')
  let [loading, setLoading] = useState(false)
  const [mdas, setMdas] = useState([])
  const [hasMore, setHasMore] = useState(true)

  const getMDAs = async () => {
    // e.preventDefault()
    setIsLoading(true)

    const response = await makeApiCall(`/payment/mdas`)
    if (response.status === 200) {
      let data = await response?.data
      // setTotalLength(response?.meta?.total_items)
      if (mdas.length >= response.meta.total_items) {
        setHasMore(false)
        setIsLoading(false)
        return
      }
      setMdas(data)
      setSorting(mdas[0]?.name)
      setActualVal(mdas[0]?.name)
      setIsLoading(false)
    } else {
      toaster('Something Went Wrong', 'error')
      setIsLoading(false)
    }
  }

  const handleInputchange = (e) => {
    const name = e.target.name
    const value = e.target.value

    setRevenueData({
      ...revenueData,
      [name]: value,
      // mda_id: sorting,
    })
  }
  // console.log(revenueData, 'revenueData')
  const createRevenueHead = async () => {
    // e.preventDefault()
    setLoading(true)
    const response = await makeApiCall(
      '/payment/revenue-heads',
      'POST',
      revenueData
    )
    //  setProducts(response)
    // console.log(response)

    if (response.status === 200) {
      //  setMdas(response.data)
      toaster(`${response.message}`, 'success')
      // setRevenueData({
      //   name: '',
      //   code: '',
      // })
      setLoading(false)
      setVisibleModal(false)
    } else {
      toaster('Something Went Wrong', 'error')
      setLoading(false)
    }
  }

  useEffect(() => {
    getMDAs()
  }, [])
  return (
    <>
      <Card
        className={styles.card}
        title='Add Revenue Head'
        classTitle='title-blue'
      >
        <div style={{ marginBottom: '12px' }}>
          <DropdownNew
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={sorting}
            setValue={setSorting}
            options={mdas}
            setActualVal={setActualVal}
            actualVal={actualVal}
            setRevenueData={setRevenueData}
            revenueData={revenueData}
            small
            placeholder='Select Mda'
          />
        </div>
        <div style={{ marginBottom: '12px' }}>
          <TextInput
            className={``}
            name='revenue_head'
            type='text'
            placeholder='Revenue Name'
            required
            icon='profile-circle'
            onChange={handleInputchange}
          />
        </div>
        <div style={{ marginBottom: '12px' }}>
          <TextInput
            className={``}
            name='revenue_code'
            type='text'
            placeholder='Revenue Code'
            required
            icon='lock'
            onChange={handleInputchange}
          />
        </div>
        <div style={{ marginBottom: '12px' }}>
          <TextInput
            className={``}
            name='revenue_amount'
            type='number'
            placeholder='Revenue Amount (Optional)'
            required
            icon='payment'
            onChange={handleInputchange}
          />
        </div>
        <button
          className={cn('button')}
          style={{ width: '100%' }}
          onClick={createRevenueHead}
          disabled={loading}
          // disabled={
          //   loginDetails.email === '' ||
          //   loginDetails.password === '' ||
          //   loading === true
          // }
        >
          {loading ? (
            <ScaleLoader color='#fff' speedMultiplier={2} width={4} />
          ) : (
            'Add'
          )}
        </button>
      </Card>
    </>
  )
}

export default AddingRevenue
