import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../components/Checkbox";
import Balance from "../../../../components/Balance";
import { customers } from "../../../../mocks/customers";
import { useNavigate, useLocation } from "react-router-dom";
import dateFormat, { masks } from "dateformat";

const Row = ({
  item,
  value,
  onChange,
  activeTable,
  setActiveTable,
  activeId,
  setActiveId,
}) => {
  const handleClick = (id) => {
    setActiveTable(true);
    setActiveId(id);
  };
  const { pathname } = useLocation();
  const navigate = useNavigate();
  console.log(item, "Here Items");
  const gotoProfile = () => {
    // localStorage.setItem('user-profile', JSON.stringify(item))
    navigate(`/school-profile?id=${item?.id}`);
  };
  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }
        )}
      >
        <div className={styles.col}>
          <Checkbox
            className={styles.checkbox}
            value={value}
            onChange={onChange}
          />
        </div>
        <div
          className={styles.col}
          onClick={gotoProfile}
          // style={{ border: '1px solid red' }}
        >
          <div className={styles.item}>
            <div className={styles.avatar}>
              <img
                src={
                  pathname === "/citizens"
                    ? "https://cross-river-pay.s3.us-east-2.amazonaws.com/cas-admin/images/content/user.jpg"
                    : "https://cross-river-pay.s3.us-east-2.amazonaws.com/cas-admin/images/content/avatar-1.jpg"
                }
                alt="Avatar"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.user}>{item.name}</div>
            </div>
          </div>
        </div>
        <div className={styles.col} style={{ width: "300px" }}>
          {item.address}
        </div>
        <div className={styles.col}>
          <div className={cn("status-green-dark", styles.purchase)}>
            {item.type}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.email}>{item.lga?.name}</div>
        </div>

        {/* <div className={styles.col}>
          <div className={styles.lifetime}>
            {dateFormat(item.createDate, "dddd, mmmm dS, yyyy")}


          </div>
        </div> */}
        {/* <div className={styles.col}>{item.comments}</div>
        <div className={styles.col}>{item.likes}</div> */}
      </div>
    </>
  );
};

export default Row;
