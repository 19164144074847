import React from 'react'
import RevenueDash from '../Revenue/SingleRevenue'

function RevenueHeads({ showRevenueHeads, setShowRevenueHeads }) {
  return (
    <div>
      <RevenueDash
        setShowRevenueHeads={setShowRevenueHeads}
        showRevenueHeads={showRevenueHeads}
      />
    </div>
  )
}

export default RevenueHeads
