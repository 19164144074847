import React, { useEffect, useState } from "react";

import ActiveCustomers from "./ActiveCustomers";
// import TooltipGlodal from "../../../../components/TooltipGlodal";
import Overview from "./Overview";
import ProductSales from "./ProductSales";
import ProductViews from "../ProductViews";
import Table from "./Table";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import TopCountries from "./TopCountries";
import { makeApiCall } from "../../../../lib/api";
import { makeReportCall } from "../../../../lib/reportsApi";
import { refetchPayment } from "../../../../firebase";
// import styles from "./Earning.module.sass";
import styles from "./Earning.module.sass";
import toaster from "../../../../utils/toaster";
import { getCurrentYearAndMonth } from "../../../../utils";
import Debounce from "../../../../utils/debounce";

const Earning = () => {
  const [reportData, setReportData] = useState();
  const { currentYear, currentMonth } = getCurrentYearAndMonth();

  const [isLoading, setIsLoading] = useState(false);
  const [statistics, setStatistics] = useState();
  const getProducts = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(`/payment/channelAmount/${currentYear}`);
    //  setProducts(response)
    // console.log(response)
    if (response.status === 200) {
      // console.log("re----  ", response);
      setStatistics(response?.data);
      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);
  // refetchPayment(getProducts);
  Debounce(refetchPayment(getProducts), 2500);

  return (
    <>
      <div className={styles.section}>
        <div className={styles.row}>
          <div className={styles.col}>
            <ActiveCustomers isLoading={isLoading} />
          </div>
          <div
            className={styles.col}
            style={{ height: "355px", overflow: "scroll" }}
          >
            <TopCountries />
          </div>
        </div>
        <div style={{ marginTop: "", marginBottom: "2rem" }}>
          <Overview statistics={statistics} />
        </div>
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <ProductViews statistics={statistics} isLoading={isLoading} />
        </div>
        {/* <Table /> */}
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Earning;
