import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Text,
  Tooltip,
} from "recharts";
import React, { useEffect, useState } from "react";

import Card from "../../../../../components/Card";
import Formatter from "../../../../../components/Formatter/Formatter";
import NotFound from "../../../../NotFound";
import cn from "classnames";
import { getCurrentYearAndMonth } from "../../../../../utils";
import { makeApiCall } from "../../../../../lib/api";
import { makeReportCall } from "../../../../../lib/reportsApi";
import { refetchPayment } from "../../../../../firebase";
import styles from "./NewCustomer.module.sass";
import toaster from "../../../../../utils/toaster";
import { useLocation } from "react-router";
import Debounce from "../../../../../utils/debounce";

const legend = [
  {
    title: "New customer",
    color: "#B5E4CA",
  },
  {
    title: "Returning customer",
    color: "#CABDFF",
  },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const NewCustomer = ({ className }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  //  const { currentYear, currentMonth } = getCurrentYearAndMonth()
  const CenteredText = ({ cx, cy }) => (
    <Text
      x={cx}
      y={cy}
      width={30}
      textAnchor="middle"
      dominantBaseline="middle"
      fontSize={24}
      fontWeight="bold"
      fill="#8884d8" // You can adjust the color here
    >
      Center Text
    </Text>
  );
  // const id = params.get("id");
  const id = 4190005;
  // console.log(id, currentMonth, currentYear)
  const [recordsStats, setRecordsStats] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getStatistics = async () => {
    // e.preventDefault()
    setIsLoading(true);
    // const response = await makeApiCall(`/payment/mdaStats/${id}`);
    const response = await makeReportCall(
      // `/formal-sector/business-statistics?year=2023`
      `formal-sector/business-statistics?year=2023`
    );

    // console.log(response)
    if (response.status === 200) {
      console.log(response, " response");
      setRecordsStats(response.data.yearly_overview);
      // console.log(response);

      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getStatistics();
  }, []);
  Debounce(refetchPayment(getStatistics), 2500);

  // const data = recordsStats;
  // console.log(data, "redddd----");
  // const a = recordsStats;
  // const array = [];

  // // for (const property in data.yearly_overview) {
  // //   const newObject = {};
  // //   newObject[property] = data[property];
  // //   array.push(newObject);
  // // }
  // for (const property in a) {
  //   if (typeof a[property] === "object") {
  //     for (const innerProperty in a[property]) {
  //       const newObject = {};
  //       newObject[innerProperty] = a[property][innerProperty];
  //       array.push(newObject);
  //     }
  //   }
  // }
  // console.log(array, "aray----");
  // const data = array;
  // console.log(recordsStats);

  return (
    <div style={{ marginBottom: "2rem" }}>
      <Card
        className={cn(styles.card, className)}
        title="Overview"
        classTitle="title-blue"
      >
        <div
          className={styles.chart}
          // style={{
          //   border: "2px solid red",
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   width: "100%",
          // }}
        >
          {/* <NotFound /> */}

          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
              <Tooltip
                contentStyle={{
                  backgroundColor: "#272B30",
                  borderColor: "rgba(255, 255, 255, 0.12)",
                  borderRadius: 8,
                  boxShadow:
                    "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
                }}
                labelStyle={{
                  fontSize: 12,
                  fontWeight: "500",
                  color: "#fff",
                }}
                itemStyle={{
                  padding: 0,
                  textTransform: "capitalize",
                  fontSize: 12,
                  fontWeight: "600",
                  color: "#fff",
                }}
              />
              <Pie
                data={recordsStats}
                cx={470}
                cy={90}
                innerRadius={10}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
                style={{}}
              >
                {recordsStats?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
                {/* <CenteredText cx={200} cy={200} /> */}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className={styles.legend}>
          {recordsStats?.map((x, index) => (
            <div className={styles.indicator} key={index}>
              <div
                className={styles.color}
                style={{
                  backgroundColor: COLORS[index],
                }}
              ></div>
              <p
                style={{
                  textTransform: "capitalize",
                }}
              >
                {x.name} : ₦ <Formatter amount={x.value} />
              </p>
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default NewCustomer;
