import React from 'react'
import Earning from './Earning'

function Overview() {
  return (
    <div>
      <Earning />
    </div>
  )
}

export default Overview
