import "react-quill/dist/quill.snow.css";
import "./detailsStyle.css";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import Card from "../../../components/Card";
import Cookies from "js-cookie";
import Dropdown from "../../../components/Dropdown";
import Form from "../../../components/Form";
import Icon from "../../../components/Icon";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import ReactQuill from "react-quill";
import Success from "../../Payouts/Overview/Success";
import TextInput from "../../../components/TextInput";
import cn from "classnames";
import { makeApiCall } from "../../../lib/api";
import styles from "./ProductActivity.module.sass";
import toaster from "../../../utils/toaster";

const PaymentDetails = ({ setShowGatewayDetails }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);
  const details = JSON.parse(Cookies.get("paymentDetails"));
  const [disabled, setDisabled] = useState({
    status: details.status === 1 ? 0 : 1,
  });
  const [gatewayDets, setGatewayDets] = useState({
    channel_code: details.channel_code,
  });
  const [sendingData, setSendingData] = useState({
    channel_title: details.channel_title,
    public_key: details.public_key,
    channel: details.channel,
    channel_desc: details.channel_desc,
    secret_key: details.secret_key,
    other_key: details.other_key,
  });
  const updatePayment = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(
      `/payment/methods/${details.channel_code}`,
      "PATCH",
      sendingData
    );
    if (response.status === 200) {
      toaster(response.message, "success");
      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };
  const disablePayment = async () => {
    // e.preventDefault()
    setDisableLoading(true);
    const response = await makeApiCall(
      `/payment/methods/${details.channel_code}`,
      "PATCH",
      disabled
    );
    if (response.status === 200) {
      toaster(response.message, "success");
      setShowGatewayDetails(false);
      setDisableLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setDisableLoading(false);
    }
  };
  const handleChange1 = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSendingData({
      ...sendingData,
      [name]: value,
    });
  };
  const handleChange = (value) => {
    setSendingData({
      ...sendingData,
      channel_desc: value,
    });
  };
  // console.log(sendingData, 'details')
  return (
    <>
      <Card
        className={styles.card}
        title={`${details.channel}`}
        classTitle="title-green"
        head={
          <>
            <Link
              className={cn("button-stroke button-small", styles.button)}
              to=""
              style={{ marginLeft: "12px" }}
              // onClick={goBack}
              onClick={() => setShowGatewayDetails(false)}
            >
              <Icon name="arrow-left" size="18" />

              <span>Back</span>
            </Link>
          </>
        }
      >
        <div className="details-wrapper">
          <TextInput
            className={``}
            name="channel"
            type="text"
            placeholder="Enter MDA Name"
            required
            icon="profile-circle"
            value={sendingData.channel}
            onChange={handleChange1}
          />

          <TextInput
            className={``}
            name="channel_code"
            type="text"
            placeholder="Enter MDA Name"
            required
            icon="profile-circle"
            value={gatewayDets.channel_code}
            disabled
            onChange={handleChange1}
          />
          <ReactQuill
            value={sendingData.channel_desc}
            style={{ marginBottom: "3rem" }}
            name="channel_desc"
            onChange={handleChange}
          />

          <TextInput
            className={``}
            name="channel_title"
            type="text"
            placeholder="Enter MDA Name"
            required
            icon="profile-circle"
            value={sendingData.channel_title}
            onChange={handleChange1}
          />
          <TextInput
            className={``}
            name="public_key"
            type="text"
            placeholder="Enter Public Key"
            required
            icon="profile-circle"
            value={sendingData.public_key}
            onChange={handleChange1}
          />
          <TextInput
            className={``}
            name="secret_key"
            type="text"
            placeholder="Enter Private Key"
            required
            icon="profile-circle"
            value={sendingData.secret_key}
            onChange={handleChange1}
          />
          <TextInput
            className={``}
            name="secret_key"
            type="text"
            // placeholder="Enter Private Key"
            required
            icon="profile-circle"
            value={sendingData.other_key}
            onChange={handleChange1}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "100%",

            marginTop: "2rem",
          }}
        >
          <button
            className={cn("button")}
            style={{
              width: "200px",
              marginRight: "1rem",
              background: `${details.status === 1 ? "red" : "green"}`,
            }}
            disabled={disableLoading}
            onClick={() => disablePayment()}
          >
            {disableLoading
              ? "Please Wait....."
              : details.status === 1
              ? "Disable"
              : "Enable"}
            {}
          </button>
          <button
            className={cn("button")}
            style={{ width: "200px" }}
            onClick={() => updatePayment()}
            disabled={isLoading}
          >
            {isLoading ? "Updating..." : "Save"}
          </button>
        </div>
      </Card>
    </>
  );
};

export default PaymentDetails;
