import { getMessaging, getToken, onMessage } from "firebase/messaging";

import Cookies from "js-cookie";
import { initializeApp } from "firebase/app";
import toaster from "./utils/toaster";

// import toaster from "./utils/toaster";

// const appkey = process.env.REACT_APP_FIREBASE_KEY;
// console.log(appkey, "Firebase");
const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_KEY}`,
  authDomain: "cross-river-pay.firebaseapp.com",
  projectId: "cross-river-pay",
  storageBucket: "cross-river-pay.appspot.com",
  messagingSenderId: "895623107810",
  appId: `${process.env.REACT_APP_FIREBASE_APPID}`,
  measurementId: "G-XMRGQJTJ1G",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestPermission = () => {
  console.log("Requesting User Permission......");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification User Permission Granted.");
      // toaster("Notification User Permission Granted.", "success");
      return getToken(messaging, {
        vapidKey: `${process.env.REACT_APP_FIREBASE_vapidKey}`,
      })
        .then((currentToken) => {
          if (currentToken) {
            Cookies.set("notification_token", currentToken);
            // console.log("Client Token: ", currentToken);
          } else {
            console.log("Failed to generate the app registration token.");
          }
        })
        .catch((err) => {
          console.log(
            "An error occurred when requesting to receive the token.",
            err
          );
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
};

// requestPermission();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      toaster(
        `${payload?.notification?.title}: ${payload?.notification?.body}`,
        "success"
      );
      resolve(payload);
    });
  });
// export refetchPayment;

export const refetchPayment = (apiRequestToBeMade) => {
  // requestPermission();
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      apiRequestToBeMade();
      toaster(
        `${payload?.notification?.title}: ${payload?.notification?.body}`,
        "success"
      );

      resolve(payload);
    });
  });
};
