import Formatter from "../../../../../components/Formatter/Formatter";
import React from "react";
import cn from "classnames";
import styles from "./Table.module.sass";
import { useNavigate } from "react-router";

function Items({ x }) {
  //   console.log(x, 'This is Id')
  const navigate = useNavigate();

  return (
    <>
      {/* {console.log(x, 'Stats')} */}
      <div className={styles.col}>{x.payer_name}</div>
      <div className={styles.col}>
        {/* ${numberWithCommas(x.earnings.toFixed(2))} */}
        {x.orderId}
      </div>
      <div className={styles.col}>
        ₦
        <Formatter amount={x?.amount} />
      </div>
      {/* <div
        className={styles.col}
        style={{ backgroundColor: x.status === 'Approved' ? 'green' : 'white' }}
      >
        {x.status}
      </div> */}
      <div className={styles.col}>
        {/* <div className={styles.details}>
                  <div className={styles.product}>{x.payer_name}</div>
                  <div className={styles.invoice}>{x.status}</div>
                </div> */}
        {x.status === "Approved" ? (
          <div
            className={cn(
              { "status-green-dark": x.status === "Approved" },
              styles.status
            )}
          >
            {x.status}
          </div>
        ) : (
          <div
            className={cn(
              {
                "status-red-dark": x.status === "Payment Reference Generated",
              },
              styles.status
            )}
          >
            {/* Payment Reference Generated */}
            {x.status}
          </div>
        )}
      </div>
    </>
  );
}

export default Items;
