import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Table from "./Table";
import cn from "classnames";

import styles from "./CustomerList.module.sass";
import { makeApiCall } from "../../../../../lib/api";

const CustomerList = ({ userProfile }) => {
  const [visible, setVisible] = useState(false);
  const [citizens, setCitizens] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [fetchLimit, setFetchLimit] = useState(10);

  const [nodata, setNodata] = useState(false);

  const navigate = useNavigate();
  // console.log(navigate)

  // console.log(pathname)
  const inc = 10;

  const getEachUserHouse = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(
      `/school/enrollment-history?schoolId=${userProfile?.school?.id}`
    );
    // console.log(response)
    if (response.status === 200) {
      if (response?.data?.length === 0) {
        setNodata(true);
      }
      if (citizens.length >= response.meta.total_items) {
        setHasMore(false);
        setIsLoading(false);
        return;
      }
      setCitizens(response?.data);
      // console.log(citizens)
      setIsLoading(false);
    }
  };
  // const goBack = () => {
  //   navigate(-1);
  // };

  useEffect(() => {
    getEachUserHouse();
  }, [fetchLimit, userProfile?.school?.id]);
  console.log(citizens, "This is the citizens");

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        if (hasMore === false) return;
        // Check if there is more data to fetch
        // Increment page and fetchLimit
        // setPage((prevPage) => prevPage + 1)
        // console.log('ddd', hasMore)
        setFetchLimit((prevFetched) => prevFetched + inc);
      }
    };
    // Attach the event listener to the scroll event
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Clean up the event listener on unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasMore]);
  return (
    <>
      <div className={cn(styles.row, { [styles.flex]: visible })}>
        <Table
          className={styles.table}
          activeTable={visible}
          setActiveTable={setVisible}
          citizens={citizens}
          isLoading={isLoading}
          nodata={nodata}
          // response={response}
        />
      </div>
    </>
  );
};

export default CustomerList;
