import React from "react";
import styles from "./Success.module.sass";
import cn from "classnames";
import AddingMdas from "../../../ProductsDashboard/AddingMda";
import AddingRevenue from "../../../ProductsDashboard/AddingRevenue";
import { useLocation } from "react-router";
import AddingTaxOfficers from "../../../TaxOfficesDash/AddingTaxOfficers";
import AddBussinessSector from "../../../BusinessSecDash/AddBusinessSector";
import UpdateBusinessSector from "../../../BusinessSecDash/UpdateBusinessSector";

import AddPaymentGateway from "../../../PaymentGateway/AddPaymentGateway";
import AddRoles from "../../../RolesDash/AddRoles";
import ConfirmRegenerate from "../../../AppKeys/ConfirmRegenerate";
import UpdateRoles from "../../../RolesDash/UpdateRoles";
import AddServices from "../../../Report/App-services/ServicesDash/AddService";
import AddTriggers from "../../../TriggerPoints/AddTrigers";
import AcceptNotification from "../../../SignUp/Entry/NotiModal";

const Success = ({
  setVisibleModal,
  mdas,
  getRevenueHeads,
  filters,
  showUpdateModal,
  showFeatureUpdateModal,
  appkeys,
  setKeys,
}) => {
  const pathname = useLocation();

  return (
    <div className={styles.success}>
      {pathname.pathname === "/mdas" ? (
        <AddingMdas setVisibleModal={setVisibleModal} />
      ) : pathname.pathname === "/revenue" ? (
        <AddingRevenue mdas={mdas} setVisibleModal={setVisibleModal} />
      ) : (
        ""
      )}
      {pathname.pathname === "/taxoffices" ? (
        <AddingTaxOfficers setVisibleModal={setVisibleModal} />
      ) : (
        ""
      )}
      {(pathname.pathname === "/business-sector") &
      (showUpdateModal === false) ? (
        <AddBussinessSector setVisibleModal={setVisibleModal} />
      ) : (
        ""
      )}
      {(pathname.pathname === "/roles") & (showFeatureUpdateModal === false) ? (
        <AddRoles
          setVisibleModal={setVisibleModal}
          getRevenueHeads={getRevenueHeads}
          filters={filters}
        />
      ) : (
        ""
      )}
      {pathname.pathname === "/payment-gateway" ? (
        <AddPaymentGateway
          setVisibleModal={setVisibleModal}
          getRevenueHeads={getRevenueHeads}
        />
      ) : (
        ""
      )}
      {pathname.pathname === "/report/App-services" ? (
        <AddServices
          setVisibleModal={setVisibleModal}
          getRevenueHeads={getRevenueHeads}
        />
      ) : (
        ""
      )}
      {/* {pathname.pathname === '/roles' && (
        <AddRoles
          setVisibleModal={setVisibleModal}
          getRevenueHeads={getRevenueHeads}
          filters={filters}
        />
      )} */}
      {showFeatureUpdateModal === true ? (
        <UpdateRoles
          setVisibleModal={setVisibleModal}
          showUpdateModal={showUpdateModal}
          getRevenueHeads={getRevenueHeads}
          filters={filters}
        />
      ) : (
        ""
      )}
      {showUpdateModal === true ? (
        <UpdateBusinessSector
          setVisibleModal={setVisibleModal}
          showUpdateModal={showUpdateModal}
          getRevenueHeads={getRevenueHeads}
        />
      ) : (
        ""
      )}
      {pathname.pathname === "/app-info" && (
        <ConfirmRegenerate
          setVisibleModal={setVisibleModal}
          setKeys={setKeys}
          appkeys={appkeys}
        />
      )}
      {pathname.pathname === "/settings" && (
        <AddTriggers
          setVisibleModal={setVisibleModal}
          getRevenueHeads={getRevenueHeads}
        />
      )}
      {pathname.pathname === "/" && (
        // <AcceptNotification
        //   setVisibleModal={setVisibleModal}
        //   getRevenueHeads={getRevenueHeads}
        // />
        <AcceptNotification setVisibleModal={setVisibleModal} />
      )}
      {/* <div className={styles.icon}>
        <span role="img" aria-label="firework">
          🎉
        </span>
      </div>
      <div className={styles.info}>Success!</div>
      <div className={cn("h2", styles.price)}>$128,000</div>
      <div className={styles.text}>
        Has been sent to your <span>Paypal: tam@ui8.net</span>
      </div>
      <button className={cn("button", styles.button)}>Done!</button> */}
    </div>
  );
};

export default Success;
