import React, { useEffect } from "react";

import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  //   const [isLoggedIn, setIsLoggedIn] = useState(false)
  const checkUserToken = () => {
    const userToken = Cookies.get("user_token");
    if (!userToken || userToken === "undefined") {
      //  setIsLoggedIn(false)
      return navigate("/");
    }
    //     setIsLoggedIn(true)
  };
  useEffect(() => {
    checkUserToken();
  }, []);
  return <React.Fragment>{props.children}</React.Fragment>;
};
export default ProtectedRoute;
