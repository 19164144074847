import React, { useEffect, useState } from "react";
import { onMessageListener, requestPermission } from "../../firebase";

import Comments from "./Comments";
import MoreCustomers from "./MoreCustomers";
import Overview from "./Overview";
import Overview2 from "../ProductsDashboard/Overview";
import PopularProducts from "../../components/PopularProducts";
import ProTips from "./ProTips";
import ProductViews from "./ProductViews";
import RefundRequests from "../../components/RefundRequests";
import TooltipGlodal from "../../components/TooltipGlodal";
import axios from "axios";
import styles from "./Home.module.sass";
import toaster from "../../utils/toaster";

// import MqttConnectionExample from '../../components/MQTTConnector'

const Home = () => {
  return (
    <>
      <div style={{ marginBottom: "2rem" }}>
        <Overview2 className={styles.card} />
      </div>
      <div style={{ marginBottom: "2rem" }}>
        <Overview className={styles.card} />
      </div>
      <ProductViews className={styles.card} />
      <TooltipGlodal />
    </>
  );
};

export default Home;
