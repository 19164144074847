import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { useEffect, useState } from "react";

import Card from "../../../../../components/Card";
import Dropdown from "../../../../../components/Dropdown";
import Loader from "../../../../../components/Loader";
import cn from "classnames";
import { makeApiCall } from "../../../../../lib/api";
import { refetchPayment } from "../../../../../firebase";
import styles from "./ActiveCustomers.module.sass";
import toaster from "../../../../../utils/toaster";
import useDarkMode from "use-dark-mode";
import Debounce from "../../../../../utils/debounce";

// const intervals = ["Last 30 days", "Last 20 days", "Last 10 days"];
const intervals = ["2024", "2023"];

const ActiveCustomers = ({ className, reportData }) => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const darkMode = useDarkMode(false);
  const [sorting, setSorting] = useState(currentYear);
  const [isLoading, setIsLoading] = useState(false);
  // const { currentYear, currentMonth } = getCurrentYearAndMonth();
  // console.log(currentYear, "This is currentYear");
  // const darkMode = JSON.parse(localStorage.getItem("darkMode"));
  // console.log(darkMode)
  const [statistics, setStatistics] = useState();

  // const refinedData = reportData?.formal_collection?.map((d, i) => ({
  //   name: d?.month_name,
  //   moniepoint: d?.payments,
  //   informat_collection_payment: reportData?.informal_collection[i]?.payments,
  // }));
  const getProducts = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(`/payment/graph/${sorting}`);
    //  setProducts(response)
    // console.log(response)
    if (response.status === 200) {
      // console.log("graph----  ", response);
      setStatistics(response?.data);
      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getProducts();
  }, [sorting]);
  // refetchPayment(getProducts);
  Debounce(refetchPayment(getProducts), 2500);

  // console.log(statistics, 'data')
  // console.log('ggggggg', refinedData)

  const refinedData2 = statistics?.map((d, i) => ({
    name: d?.month,
    moniepoint: d?.value[0]?.amount,
    payoutlet: d?.value[1]?.amount,
    credo: d?.value[2]?.amount,
    remita: d?.value[3]?.amount,
    paydirect: d?.value[4]?.amount,
  }));
  // console.log(refinedData2);

  useEffect(() => {
    // Function to update the current year every second (1000ms)
    const updateCurrentYear = () => {
      setCurrentYear(new Date().getFullYear());
    };

    // Set up a timer to update the year every second
    const intervalId = setInterval(updateCurrentYear, 1000);

    // Clean up the timer when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);
  // console.log(refinedData2);
  function formatYAxisTick(value) {
    if (Math.abs(value) >= 1.0e9) {
      return (Math.abs(value) / 1.0e9).toFixed(1) + "B";
    } else if (Math.abs(value) >= 1.0e6) {
      return (Math.abs(value) / 1.0e6).toFixed(1) + "M";
    } else if (Math.abs(value) >= 1.0e3) {
      return (Math.abs(value) / 1.0e3).toFixed(1) + "K";
    } else {
      return value.toString();
    }
  }
  return (
    <div style={{ marginBottom: "2rem" }}>
      <Card
        className={cn(styles.card, className)}
        title={`Monthly Revenue Updates for ${currentYear}`}
        classTitle={cn("title-purple", styles.cardTitle)}
        classCardHead={styles.cardHead}
        head={
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={sorting}
            setValue={setSorting}
            options={intervals}
            small
          />
        }
      >
        {}
        <div className={styles.chart}>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Loader />
            </div>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={300}
                data={refinedData2}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid
                  strokeDasharray="none"
                  stroke={darkMode.value ? "#272B30" : "#EFEFEF"}
                  vertical={false}
                />
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12, fontWeight: "500", fill: "#9A9FA5" }}
                  padding={{ left: 10 }}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12, fontWeight: "500", fill: "#9A9FA5" }}
                  tickFormatter={formatYAxisTick} // Use the custom tick formatter
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "#272B30",
                    borderColor: "rgba(255, 255, 255, 0.12)",
                    borderRadius: 8,
                    boxShadow:
                      "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
                  }}
                  labelStyle={{
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#fff",
                  }}
                  itemStyle={{
                    padding: 0,
                    textTransform: "capitalize",
                    fontSize: 12,
                    fontWeight: "600",
                    color: "#fff",
                  }}
                />
                {/* <Line
              type='monotone'
              dataKey='monthly'
              dot={false}
              strokeWidth={4}
              stroke='#2A85FF'
            /> */}
                <Line
                  type="monotone"
                  dataKey="moniepoint"
                  dot={false}
                  strokeWidth={4}
                  stroke="green"
                />
                <Line
                  type="monotone"
                  dataKey="paydirect"
                  dot={false}
                  strokeWidth={4}
                  stroke="#007BFF"
                />
                <Line
                  type="monotone"
                  dataKey="remita"
                  dot={false}
                  strokeWidth={4}
                  stroke="#007BFF"
                />
                <Line
                  type="monotone"
                  dataKey="credo"
                  dot={false}
                  strokeWidth={4}
                  stroke="#B1E5FC"
                />
                <Line
                  type="monotone"
                  dataKey="payoutlet"
                  dot={false}
                  strokeWidth={4}
                  stroke="green"
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ActiveCustomers;
