import React, { useState } from 'react'
import Card from '../../../components/Card'
import TextInput from '../../../components/TextInput'
import styles from '../ProductsDashboard.module.sass'
import cn from 'classnames'
import { makeApiCall } from '../../../lib/api'
import toaster from '../../../utils/toaster'
import { ScaleLoader } from 'react-spinners'

function AddingMdas({ setVisibleModal }) {
  const [madsData, setMadsData] = useState({
    name: '',
    code: '',
    alias: '',
  })
  let [loading, setLoading] = useState(false)

  const handleInputchange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setMadsData({
      ...madsData,
      [name]: value,
    })
  }
  const createMDa = async () => {
    // e.preventDefault()
    setLoading(true)
    const response = await makeApiCall('/payment/mdas', 'POST', madsData)
    //  setProducts(response)
    // console.log(response)

    if (response.status === 200) {
      //  setMdas(response.data)
      toaster(`${response.message}`, 'success')
      setMadsData({
        name: '',
        code: '',
      })
      setVisibleModal(false)

      setLoading(false)
    } else {
      toaster('Something Went Wrong', 'error')
      setLoading(false)
    }
  }

  // const handleUserInputs = (e) => {
  //   setLoginDetails({
  //     ...loginDetails,
  //     [name]: value,
  //   })
  // }
  // console.log('====================================')
  // console.log(madsData, 'madsData')
  // console.log('====================================')
  return (
    <>
      <Card className={styles.card} title='Add MDAs' classTitle='title-blue'>
        <div style={{ marginBottom: '12px' }}>
          <TextInput
            className={``}
            name='name'
            type='text'
            placeholder='Enter MDA Name'
            required
            icon='profile-circle'
            // value={madsData.name}
            onChange={handleInputchange}
          />
        </div>
        <div style={{ marginBottom: '12px' }}>
          <TextInput
            className={``}
            name='code'
            type='text'
            placeholder='Enter MDA Code'
            required
            icon='lock'
            value={madsData.code}
            onChange={handleInputchange}
          />
        </div>
        <div style={{ marginBottom: '12px' }}>
          <TextInput
            className={``}
            name='alias'
            type='text'
            placeholder='Enter Alias'
            required
            icon='mail'
            value={madsData.alias}
            onChange={handleInputchange}
          />
        </div>
        <button
          className={cn('button')}
          style={{ width: '100%' }}
          onClick={createMDa}
          disabled={loading}
          // disabled={
          //   loginDetails.email === '' ||
          //   loginDetails.password === '' ||
          //   loading === true
          // }
        >
          {loading ? (
            <ScaleLoader color='#fff' speedMultiplier={2} width={4} />
          ) : (
            'Add'
          )}
        </button>
      </Card>
    </>
  )
}

export default AddingMdas
