import React from 'react'
import CustomerList from './CustomerList'

function Houses({ userProfile }) {
  return (
    <div>
      <CustomerList userProfile={userProfile} />
    </div>
  )
}

export default Houses
