import React, { useEffect, useState } from "react";

import ActiveCustomers from "./ActiveCustomers";
import CurrentYearOverview from "./CurrentYearOverview";
import MoreRevenueInfo from "./MoreRevenueInfo";
import Overview from "./Overview";
import ProductSales from "./ProductSales";
import RevHeadMonth from "./RevHeadMonth";
import Table from "./Table";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import TopCountries from "./TopCountries";
import TopRevHead from "./TopRevHead";
import { makeApiCall } from "../../../../lib/api";
import { makeReportCall } from "../../../../lib/reportsApi";
import { refetchPayment } from "../../../../firebase";
import styles from "./Earning.module.sass";
import toaster from "../../../../utils/toaster";
import Debounce from "../../../../utils/debounce";

const Earning = () => {
  const [reportData, setReportDate] = useState();
  const [intervals1, setIntervals1] = useState([]);
  // const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const [isLoading, setIsLoading] = useState(false);

  // Getting all statistics
  const getStatistics = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeReportCall(`/revenue-statistics`);
    // /payment/paymentyears
    // console.log(response)
    if (response.status === 200) {
      // console.log(response);
      setReportDate(response.data);
      // console.log(response);

      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };

  // Get All years from the DB
  const getYearStatistics = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(`/payment/paymentyears`);
    if (response.status === 200) {
      setIntervals1(response?.data);
      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStatistics();
  }, []);

  Debounce(refetchPayment(getStatistics), 2500);

  return (
    <>
      <div className={styles.section}>
        <Overview
          reportData={reportData}
          isLoading={isLoading}
          className={styles.card}
        />
        <CurrentYearOverview
          reportData={reportData}
          isLoading={isLoading}
          className={styles.card}
        />
        {/* <MoreRevenueInfo
          reportData={reportData}
          isLoading={isLoading}
          className={styles.card}
        /> */}
        {/* <ProductSales reportData={reportData} /> */}
        <ActiveCustomers
          isLoading={isLoading}
          reportData={reportData}
          intervals1={intervals1}
        />
        <div style={{ marginBottom: "2rem" }}>
          <Table />
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <TopCountries />
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <TopRevHead />
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <RevHeadMonth />
        </div>

        {/* <div className={styles.row}>
          <div className={styles.col}>
            <Table />
          </div>
          <div className={styles.col}>
            <TopCountries />
          </div>
        </div> */}
        {/* <div className={styles.row}>
          <div className={styles.col}>
            <TopRevHead />

            <TopRevHead />
          </div>
          <div className={styles.col}>
            <RevHeadMonth />
          </div>
        </div> */}
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Earning;
