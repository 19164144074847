import React, { useEffect, useState } from 'react'
import styles from './NewProduct.module.sass'
import TooltipGlodal from '../../components/TooltipGlodal'
import Modal from '../../components/Modal'
import Schedule from '../../components/Schedule'
import NameAndDescription from './NameAndDescription'
import ImagesAndCTA from './ImagesAndCTA'
import Price from './Price'
import CategoryAndAttibutes from './CategoryAndAttibutes'
import ProductFiles from './ProductFiles'
import Discussion from './Discussion'
import Preview from './Preview'
import Panel from './Panel'
import { makeApiCall } from '../../lib/api'
import toaster from '../../utils/toaster'
import cn from 'classnames'
import { ScaleLoader } from 'react-spinners'
import { useNavigate } from 'react-router'

const NewProduct = () => {
  const [visiblePreview, setVisiblePreview] = useState(false)
  const [visibleModal, setVisibleModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [appDetails, setAppDetails] = useState({
    appName: '',
    email: '',
    password: '',
    phoneNumber: '',
  })
  const [startTime, setStartTime] = useState(new Date())
  const navigate = useNavigate()

  const handleUserInputs = (e) => {
    const name = e.target.name
    const value = e.target.value
    setAppDetails({
      ...appDetails,
      [name]: value,
    })
  }
  // console.log(appDetails, 'appDetails')
  const handleSubmit = async () => {
    // e.preventDefault()
    setIsLoading(true)
    const response = await makeApiCall('/apps', 'POST', appDetails)
    // console.log(response)
    if (response.status === 200) {
      toaster(`${response.message}`, 'success')
      setIsLoading(false)
      navigate('/products/dashboard')
      setAppDetails({
        appName: '',
        email: '',
        password: '',
        phoneNumber: '',
      })
    } else {
      toaster(`An Error Occured`, 'error')
      setIsLoading(false)
    }
  }
  const handleClick = () => {
    console.log('Button clicked!')
  }

  useEffect(() => {
    // This effect runs only once after the component is mounted
    console.log('Component mounted')
  }, [])
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <NameAndDescription
            className={styles.card}
            handleUserInputs={handleUserInputs}
            appDetails={appDetails}
          />
          {/* <ImagesAndCTA className={styles.card} />
          <Price className={styles.card} />
          <CategoryAndAttibutes className={styles.card} />
          <ProductFiles className={styles.card} />
          <Discussion className={styles.card} /> */}
        </div>
        <div className={styles.col}>
          <Preview
            visible={visiblePreview}
            onClose={() => setVisiblePreview(false)}
            appDetails={appDetails}
          />
        </div>
      </div>
      <div
        style={{
          background: '#121212',
          width: '100%',
          marginTop: '1rem',
          padding: '1rem',
          borderRadius: '0.5rem',
        }}
      >
        <button className={cn('button', styles.button)} onClick={handleSubmit}>
          {isLoading ? <ScaleLoader color='#fff' /> : 'Publish now'}
        </button>
      </div>
      {/* <Panel
        // setVisiblePreview={setVisiblePreview}
        // setVisibleSchedule={setVisibleModal}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        appDetails={appDetails}
      /> */}
      {/* <TooltipGlodal />
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <Schedule
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          setStartTime={setStartTime}
        />
      </Modal> */}
    </>
  )
}

export default NewProduct
