import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Shop.module.sass'
import Profile from './Profile'
import Settings from './Settings'
import Card from '../../components/Card'
import Dropdown from '../../components/Dropdown'
import Filters from '../../components/Filters'
import Product from '../../components/Product'
import Follower from './Follower'
import UserProTips from '../Home/UserProTips'

// data
import { products } from '../../mocks/products'
import { followers } from '../../mocks/followers'
import Houses from '../Houses'
import Drafts from '../Drafts'
import NotFound from '../NotFound'
import { useLocation, useParams } from 'react-router'
import { makeApiCall } from '../../lib/api'
import Loader from '../../components/Loader'
import UserPayment from './UserPayment'

const navigation = ['User Info', 'Houses', 'Application', 'Payments']
const intervals = ['Most recent', 'Most new', 'Most popular']

const UserProfile = () => {
  // const { id } = useParams()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const id = params.get('id')

  // console.log(id)
  const [activeIndex, setActiveIndex] = useState(0)
  const [sorting, setSorting] = useState(intervals[0])
  // const userProfile = JSON.parse(localStorage.getItem('user-profile'))
  const [isLoading, setIsLoading] = useState(false)
  const [noapps, setNoApps] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [keyword, setKeyword] = useState('')

  const getUserProfile = async () => {
    // e.preventDefault()
    setIsLoading(true)
    const response = await makeApiCall(`/users/profile?userId=${id}`)
    //  co(response)
    // console.log(response)
    if (response.status === 200) {
      //  if (response.data.length === 0) {
      //    setNoApps(true)
      //  }
      setUserProfile(response?.data)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getUserProfile()
  }, [id])
  // console.log(userProfile, 'Users')
  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // border: '2px solid red',
            height: '100vh',
          }}
        >
          <Loader className={styles.loader} />
        </div>
      ) : (
        <div className={styles.shop}>
          <div className={styles.background}>
            <img
              src='https://cross-river-pay.s3.us-east-2.amazonaws.com/cas-admin/images/content/profile-bg.jpg'
              alt='Background'
            />
          </div>
          <Card className={styles.card}>
            <Profile userProfile={userProfile} />
            <div className={styles.control}>
              <div className={styles.nav}>
                {navigation.map((x, index) => (
                  <button
                    className={cn(styles.link, {
                      [styles.active]: index === activeIndex,
                    })}
                    onClick={() => setActiveIndex(index)}
                    key={index}
                  >
                    {x}
                  </button>
                ))}
              </div>
              <div className={styles.dropdownBox}>
                <Dropdown
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  value={sorting}
                  setValue={setSorting}
                  options={intervals}
                  small
                />
              </div>
              <Filters
                className={styles.filters}
                title='Showing 9 of 32 products'
              >
                <Settings />
              </Filters>
            </div>
            <div className={styles.wrap}>
              {activeIndex === 0 && (
                <>
                  <div className={styles.products}>
                    <UserProTips userProfile={userProfile} />
                  </div>
                  {/* <div className={styles.foot}>
                  <button
                    className={cn('button-stroke button-small', styles.button)}
                  >
                    Load more
                  </button>
                </div> */}
                </>
              )}
              {activeIndex === 1 && (
                <>
                  <div className={styles.followers}>
                    {/* {followers.map((x, index) => (
                    <Follower
                      className={styles.follower}
                      item={x}
                      key={index}
                      followers
                    />
                  ))} */}
                    <Houses userProfile={userProfile} />
                  </div>
                  {/* <div className={styles.foot}>
                  <button
                    className={cn('button-stroke button-small', styles.button)}
                  >
                    Load more
                  </button>
                </div> */}
                </>
              )}
              {activeIndex === 2 && (
                <>
                  <div className={styles.followers}>
                    <NotFound />
                  </div>
                  {/* <div className={styles.foot}>
                  <button
                    className={cn('button-stroke button-small', styles.button)}
                  >
                    Load more
                  </button>
                </div> */}
                </>
              )}
              {activeIndex === 3 && (
                <>
                  <div className={styles.followers}>
                    {/* <NotFound /> */}
                    <UserPayment userProfile={userProfile} />
                  </div>
                  {/* <div className={styles.foot}>
                  <button
                    className={cn('button-stroke button-small', styles.button)}
                  >
                    Load more
                  </button>
                </div> */}
                </>
              )}
            </div>
          </Card>
        </div>
      )}
    </>
  )
}

export default UserProfile
