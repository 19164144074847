import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Drafts.module.sass'
import Card from '../../components/Card'
import Form from '../../components/Form'
import Icon from '../../components/Icon'
import Table from '../../components/Table'
import Product from '../../components/Product'
import Loader from '../../components/Loader'
import Panel from './Panel'
import Appkeys from '../AppKeys/Appkeys'
import { useNavigate, useLocation, Link } from 'react-router-dom'

// data
import { products } from '../../mocks/products'
import { makeApiCall } from '../../lib/api'
import NotFound from '../NotFound'

const sorting = ['list', 'grid']

const Drafts = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [allApps, setAllApps] = useState([])
  const [noapps, setNoApps] = useState(false)
  const handleSubmit = (e) => {
    alert()
  }

  const [selectedFilters, setSelectedFilters] = useState([])
  const { pathname } = useLocation()

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id))
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id])
    }
  }
  const getProducts = async () => {
    // e.preventDefault()
    setIsLoading(true)
    const response = await makeApiCall('/apps')
    //  co(response)
    // console.log(response)
    if (response.status === 200) {
      if (response.data.rows.length === 0) {
        setNoApps(true)
      }
      setAllApps(response?.data?.rows)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getProducts()
  }, [])
  // console.log(noapps, 'No apps')
  return (
    <>
      {/* <Overview /> */}

      <Card
        className={styles.card}
        classCardHead={styles.head}
        title={pathname === '/user-profile' ? '' : 'All Apps'}
        classTitle={cn('title-purple', styles.title)}
        head={
          <>
            <Form
              className={styles.form}
              value={search}
              setValue={setSearch}
              onSubmit={() => handleSubmit()}
              placeholder='Search product'
              type='text'
              name='search'
              icon='search'
            />
            {/* <div className={styles.sorting}>
                  {sorting.map((x, index) => (
                    <button
                      className={cn(styles.link, {
                        [styles.active]: index === activeIndex,
                      })}
                      onClick={() => setActiveIndex(index)}
                      key={index}
                    >
                      <Icon name={x} size='24' />
                    </button>
                  ))}
                </div> */}
          </>
        }
      >
        <div className={styles.wrapper}>
          {/* {
                  allApps
                } */}
          {noapps === true ? (
            <NotFound />
          ) : (
            <Table
              items={allApps}
              title='Last edited'
              // setShowKeys={setShowKeys}
              isLoading={isLoading}
            />
          )}

          {activeIndex === 1 && (
            <>
              <div className={styles.list}>
                {products.map((x, index) => (
                  <Product
                    className={styles.product}
                    value={selectedFilters.includes(x.id)}
                    onChange={() => handleChange(x.id)}
                    item={x}
                    key={index}
                    released
                    // noapps={noapps}
                  />
                ))}
              </div>
              {/* {isLoading ? (
                    <div className={styles.foot}>
                      <button
                        className={cn(
                          'button-stroke button-small',
                          styles.button
                        )}
                      >
                        <Loader className={styles.loader} />
                        <span>Load more</span>
                      </button>
                    </div>
                  ) : (
                    ''
                  )} */}
            </>
          )}
        </div>
      </Card>

      {/* <Panel /> */}
    </>
  )
}

export default Drafts
