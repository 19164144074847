import React, { useEffect, useState } from 'react'
import styles from './ProductActivity.module.sass'
import cn from 'classnames'
import Card from '../../../../components/Card'
import Dropdown from '../../../../components/Dropdown'
import { useLocation, useNavigate } from 'react-router'
import Form from '../../../../components/Form'
import { Link } from 'react-router-dom'
import Icon from '../../../../components/Icon'
import Modal from '../../../../components/Modal'
import Success from '../../../Payouts/Overview/Success'
import toaster from '../../../../utils/toaster'
import { makeApiCall } from '../../../../lib/api'
import Loader from '../../../../components/Loader'
import TextInput from '../../../../components/TextInput'
import Item from './Item'
const ProductActivity = ({ showRevenueHeads, setShowRevenueHeads }) => {
  const MdaCode = JSON.parse(localStorage.getItem('MdaCode'))
  const MdaName = JSON.parse(localStorage.getItem('MdaName'))
  const intervals = ['Last 2 weeks', 'Last 7 days']
  const pathname = useLocation()
  const navigate = useNavigate()
  // console.log(pathname.pathname)
  const [activeTab, setActiveTab] = useState(intervals[0])
  const [visibleModal, setVisibleModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoading1, setIsLoading1] = useState(false)
  const [revenueHead, setRevenueHead] = useState()

  const getRevenueHeads = async () => {
    // e.preventDefault()
    setIsLoading(true)
    const response = await makeApiCall(
      `/payment/revenue-heads?size=20&searchBy=mda_id&searchParam=${MdaCode}`
    )
    //  setProducts(response)
    // console.log(response)
    if (response.status === 200) {
      setRevenueHead(response.data)
      // setUpdatedValues(response.data.map((item) => item.revenue_amount))

      setIsLoading(false)
    } else {
      toaster('Something Went Wrong', 'error')
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (showRevenueHeads) {
      getRevenueHeads()
    }
  }, [showRevenueHeads])

  // const handleChange = (e, index) => {
  //   const { name, value } = e.target
  //   const newValues = [...updatedValues]
  //   newValues[index] = { ...newValues[index], [name]: value }
  //   setUpdatedValues(newValues)
  // }

  return (
    <>
      <Card
        className={styles.card}
        title={MdaName}
        classTitle='title-green'
        head={
          <>
            <Form
              className={styles.form}
              // value={search}
              // setValue={setSearch}
              // onSubmit={() => handleSubmit()}
              placeholder={`Search ${MdaName}`}
              type='text'
              name='search'
              icon='search'
            />
            <Link
              className={cn('button-stroke button-small', styles.button)}
              to=''
              style={{ marginLeft: '12px' }}
              // onClick={goBack}
              onClick={() => setShowRevenueHeads(false)}
            >
              <Icon name='arrow-left' size='18' />

              <span>Back</span>
            </Link>
          </>
        }
      >
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <Loader className={styles.loader} />
          </div>
        ) : (
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Revenue Head</div>
              <div className={styles.col}>Revenue Code</div>
              <div className={styles.col}>Revenue Amount</div>
              {/* <div className={styles.col}>Likes</div>
            <div className={styles.col}>Comments</div> */}
              {pathname.pathname === '/mdas' ? (
                ''
              ) : (
                <div className={styles.col}>Actions</div>
              )}
            </div>
            {revenueHead?.map((x, index) => (
              <Item item={x} index={index} revenueHead={revenueHead} />
            ))}
          </div>
        )}
        <div className={styles.nav}>
          {intervals.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: x === activeTab,
              })}
              onClick={() => setActiveTab(x)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Success />
      </Modal>
    </>
  )
}

export default ProductActivity
