import React, { useEffect, useState } from "react";

import Card from "../../../../../components/Card";
import Loader from "../../../../../components/Loader";
import NotFound from "../../../../NotFound";
import cn from "classnames";
import { makeApiCall } from "../../../../../lib/api";
import { refetchPayment } from "../../../../../firebase";
import styles from "./TopCountries.module.sass";
import toaster from "../../../../../utils/toaster";
import Debounce from "../../../../../utils/debounce";

const countries = [
  {
    title: "Nigeria",
    flag: "🇳🇬",
    price: 876.77,
  },
  {
    title: "Germany",
    flag: "🇩🇪",
    price: 128.77,
  },
  {
    title: "Netherlands",
    flag: "🇳🇱",
    price: 124.77,
  },
  {
    title: "United Kingdom",
    flag: "🇬🇧",
    price: 82.36,
  },
  {
    title: "Italy",
    flag: "🇮🇹",
    price: 32,
  },
  {
    title: "Vietnam",
    flag: "🇻🇳",
    price: 41.89,
  },
];

const TopCountries = ({ className }) => {
  const [fetchLimit, setFetchLimit] = useState(5);

  const [totalLength, setTotalLength] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [topMDAs, setTopMDAs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nodat, setNodata] = useState(false);
  const [totalRev, setTotalRev] = useState("");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const inc = 10;

  const getTopMDAs = async () => {
    // e.preventDefault()
    setIsLoading(true);

    const response = await makeApiCall(`payment/channelAmount/${currentYear}`);
    if (response.status === 200) {
      let data = await response?.data;
      // setTotalLength(response?.meta?.total_items)
      // console.log("data", response);
      // if (data.length === 0) {
      //   setNodata(true);
      // }
      // if (topMDAs.length >= response.meta.total_items) {
      //   setHasMore(false);
      //   setIsLoading(false);
      //   return;
      // }

      setTopMDAs(data);
      setTotalRev(data?.totalRevenue);
      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTopMDAs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // refetchPayment(getTopMDAs);
  Debounce(refetchPayment(getTopMDAs), 2500);

  // console.log(topMDAs, 'topMDAs')
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop ===
  //       document.documentElement.offsetHeight
  //     ) {
  //       if (hasMore === false) return;
  //       // Check if there is more data to fetch
  //       // Increment page and fetchLimit
  //       // setPage((prevPage) => prevPage + 1)
  //       // console.log('ddd', hasMore)
  //       setFetchLimit((prevFetched) => prevFetched + inc);
  //     }
  //   };
  //   // Attach the event listener to the scroll event
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     // Clean up the event listener on unmount
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [hasMore]);
  return (
    <Card
      className={cn(styles.card, className)}
      title="Revenue Overview"
      classTitle="title-blue"
    >
      <div className={styles.countries}>
        {topMDAs.map((x, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.flag}>
              <span role="img" aria-label={countries[0]?.title}>
                {countries[0].flag}
              </span>
            </div>
            <div
              className={styles.title}
              style={{ textTransform: "capitalize" }}
            >
              {x.channelCode}
            </div>
            <div className={styles.price}>₦ {x.amount.toLocaleString()}</div>
          </div>
        ))}
      </div>
      {nodat && <NotFound />}
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader className={styles.loader} />
        </div>
      ) : (
        ""
      )}
    </Card>
  );
};

export default TopCountries;
