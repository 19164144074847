// Import the XML parsing library
import React from "react";
import xmlFormat from "xml-formatter";

const EncodedRecordDisplay = ({ encodedRecord, isXML }) => {
  let formattedContent;

  if (isXML) {
    // Parse the JSON-encoded string to extract the XML content
    const jsonParsed = JSON.parse(encodedRecord);
    const innerXMLString = xmlFormat(jsonParsed);
    console.log(innerXMLString, "String");
    // const xmlContent = innerXMLString.replace(/\\/g, ""); // Remove escape characters

    // Format the extracted XML content
    formattedContent = innerXMLString;
  } else {
    // Parse the inner JSON data and format it with indentation
    const jsonParsed = JSON.parse(encodedRecord);
    formattedContent = JSON.stringify(jsonParsed, null, 2);
  }

  return (
    <div>
      <pre>{formattedContent}</pre>
    </div>
  );
};

export default EncodedRecordDisplay;
