import React from 'react'
import BusinessSectorTable from '../BusinessSecDash'

function BusinessSector() {
  return (
    <div>
      <BusinessSectorTable />
    </div>
  )
}

export default BusinessSector
