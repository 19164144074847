import React from 'react'
import Statements from './Statements'

function Transactions() {
  return (
    <div>
      <Statements />
    </div>
  )
}

export default Transactions
