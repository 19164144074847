import React, { useState } from "react";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import styles from "../../ProductsDashboard/ProductsDashboard.module.sass";
import cn from "classnames";
import { makeApiCall } from "../../../lib/api";
import toaster from "../../../utils/toaster";

function AddTriggers({ setVisibleModal, getRevenueHeads }) {
  const [isLoading, setIsLoading] = useState(false);

  const [newPaymentDetails, setNewPaymentDetails] = useState({
    title: "",
    description: "",
    value1: "",
    value2: "",
    // public_key: "",
    // channel_code: "",
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewPaymentDetails({
      ...newPaymentDetails,
      [name]: value,
    });
  };
  const createTriggers = async () => {
    setIsLoading(true);
    const response = await makeApiCall(
      `/notifications`,
      "POST",
      newPaymentDetails
    );
    // console.log(response, "This is trigger logs");
    if (response?.value1 === newPaymentDetails?.value1) {
      toaster("Trigger Created Successfully", "success");
      setVisibleModal(false);
      setIsLoading(false);
      getRevenueHeads();
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };
  // console.log(newPaymentDetails, 'newPaymentDetails')
  return (
    <>
      <Card
        className={styles.card}
        title="Add Triggers"
        classTitle="title-blue"
      >
        <div style={{ marginBottom: "12px" }}>
          <TextInput
            className={``}
            name="title"
            type="text"
            placeholder="Enter Tittle"
            required
            // icon="profile-circle"
            onChange={handleChange}
          />
        </div>
        <div style={{ marginBottom: "12px" }}>
          <TextInput
            className={``}
            name="description"
            type="text"
            placeholder="Enter  Description"
            required
            // icon="mail"
            onChange={handleChange}
          />
        </div>
        <div style={{ marginBottom: "12px" }}>
          <TextInput
            className={``}
            name="value1"
            type="text"
            placeholder="Enter value1"
            required
            // icon="mail"
            onChange={handleChange}
          />
        </div>
        <div style={{ marginBottom: "12px" }}>
          <TextInput
            className={``}
            name="value2"
            type="text"
            placeholder="Enter value2"
            required
            // icon="lock"
            onChange={handleChange}
          />
        </div>
        {/* <div style={{ marginBottom: "12px" }}>
          <TextInput
            className={``}
            name="public_key"
            type="text"
            placeholder="Enter Public Key"
            required
            icon="lock"
            onChange={handleChange}
          />
        </div>
        <div style={{ marginBottom: "12px" }}>
          <TextInput
            className={``}
            name="channel_code"
            type="text"
            placeholder="Enter Channel Code"
            required
            icon="lock"
            onChange={handleChange}
          />
        </div> */}
        <button
          className={cn("button")}
          style={{ width: "100%" }}
          onClick={() => createTriggers()}
          // disabled={
          //   loginDetails.email === '' ||
          //   loginDetails.password === '' ||
          //   loading === true
          // }
        >
          {isLoading ? "Please Wait ......" : "Add"}
        </button>
      </Card>
    </>
  );
}

export default AddTriggers;
