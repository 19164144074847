import React from 'react'
import CustomerList from '../CustomerList'

function UserManagement() {
  return (
    <div>
      <CustomerList />
    </div>
  )
}

export default UserManagement
