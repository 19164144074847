import React, { useEffect, useState } from "react";

import ActiveCustomers from "./ActiveCustomers";
import AllRevenueForMDA from "./AllRevenueforMda";
import NewCustomer from "./NewCustomer";
// import TooltipGlodal from "../../../../components/TooltipGlodal";
import Overview from "./Overview";
import ProductSales from "./ProductSales";
import RevenueDash from "./SingleRevenue";
import Table from "./Table";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import TopCountries from "./TopCountries";
import { makeReportCall } from "../../../../lib/reportsApi";
// import styles from "./Earning.module.sass";
import styles from "./Earning.module.sass";
import toaster from "../../../../utils/toaster";

const Earning = () => {
  // const [reportData, setReportData] = useState();

  // const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <div className={styles.section}>
        <Overview />
        <AllRevenueForMDA />
        {/* <div style={{ marginTop: "10px", marginBottom: "2rem" }}>
          <Table />
        </div> */}
        {/* <div style={{ marginTop: "10px", marginBottom: "2rem" }}>
          <TopCountries />
        </div> */}
        <div>
          <NewCustomer />
        </div>
        <div>
          <RevenueDash />
        </div>
        {/* <div className={styles.row}>
          <div
            className={styles.col}
            style={{ height: "600px", overflow: "scroll" }}
          >
            <RevenueDash />
          </div>
          <div className={styles.col}>
            <NewCustomer />
          </div>
        </div> */}
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Earning;
