import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import dateFormat, { masks } from "dateformat";

import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import { FiCopy } from "react-icons/fi";
import Icon from "../../../components/Icon";
import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import Success from "../../Payouts/Overview/Success";
import TextInput from "../../../components/TextInput";
import cn from "classnames";
import { makeApiCall } from "../../../lib/api";
import { numberWithCommas } from "../../../utils.js";
import styles from "./Transactions.module.sass";
import toaster from "../../../utils/toaster";
import { transactions } from "../../../mocks/transactions";

const intervals = ["Last 30 days", "Last 20 days", "Last 10 days"];
const now = new Date();

const Transactions = ({
  className,
  appkeys,
  setShowKeys,
  dets,
  getProducts,
}) => {
  // const [sorting, setSorting] = useState(intervals[0])
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => setPasswordShown(!passwordShown);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isCopied, setIsCopied] = useState(null);
  const [isCopiedpublicKey, setIsCopiedPublicKey] = useState(null);
  const [isCopiedsecretKey, setIsCopiedSecretKey] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);

  // console.log(dets, 'appkeys')
  const [webHookUrl, setWebHookUrl] = useState(dets?.webhook_url);
  // const appkeys = JSON.parse(localStorage.getItem("appKeys"));

  const navigate = useNavigate();
  // const goBack = () => {
  //   navigate("products/dashboard");
  // };
  const [keys, setKeys] = useState({});
  useEffect(() => {
    if (appkeys) {
      setKeys(appkeys);
    }
  }, []);
  // console.log(isCopied, 'Keys')

  const handleWebHookSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    // console.log(appkeys);
    const response = await makeApiCall(
      `/apps/profile?appId=${appkeys?.appId}`,
      "PATCH",
      {
        webhook_url: webHookUrl,
      }
      // 'multipart/form-data'
    );
    if (response) {
      toaster(response.message, "success");
      getProducts();
      console.log(response);
      //setWebHookUrl("");
      setSaving(false);
    } else {
      toaster("Something Went Wrong", "error");
      setSaving(false);
      //setWebHookUrl("");
    }
  };
  const handleCopy = () => {
    navigator.clipboard
      .writeText(keys.appId)
      .then(() => {
        setIsCopied(keys.appId);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };
  const handleCopy1 = () => {
    navigator.clipboard
      .writeText(keys.publicKey)
      .then(() => {
        setIsCopiedPublicKey(keys.publicKey);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };
  const handleCopy2 = () => {
    navigator.clipboard
      .writeText(keys.secretKey)
      .then(() => {
        setIsCopiedSecretKey(keys.secretKey);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        classCardHead={styles.head}
        title="Keys"
        classTitle={cn("title-blue", styles.title)}
        head={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            >
              {/* <label
              htmlFor='logoUpload'
              className={cn('button-stroke button-small', styles.button)}
            >
              Upload Logo
            </label>
            <input
              type='file'
              id='logoUpload'
              accept='image/*'
              onChange={handleLogoChange}
              style={{ display: 'none' }} // Hide the default file input display
            /> */}

              <button
                className={cn("button-small", styles.button)}
                onClick={() => setVisibleModal(true)}
                disabled={isLoading}
              >
                {isLoading ? "Regenerating..." : "Regenerate Keys"}
              </button>
            </div>
            {/* <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={sorting}
            setValue={setSorting}
            options={intervals}
            small
          /> */}
            {/* <p
            onClick={() => setShowKeys(false)}
            // onClick={togglePassword}
            style={{ cursor: 'pointer', fontSize: '20px', color: '#fff' }}
          >
            <Icon name='arrow-left' size='24' />
            <span>Back</span>
          </p> */}
          </>
        }
      >
        <div className={styles.wrapper}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Name</div>
              <div className={styles.col}>Token</div>
              <div className={styles.col}></div>
              <div className={styles.col}>Created At</div>
              {/* <div className={styles.col}>Price</div>
            <div className={styles.col}>Amount</div> */}
            </div>

            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles.label}>Date</div>
                Public Key
                {/* {appkeys.publicKey} */}
              </div>
              <div className={styles.col}>{keys?.publicKey}</div>
              <div className={styles.col}>
                <div
                  // className={styles.col}
                  style={{ cursor: "pointer" }}
                  onClick={handleCopy1}
                >
                  {isCopiedpublicKey ? "Copied!" : <FiCopy size={20} />}
                </div>

                {/* <button
                className={cn('button-small', styles.button)}
                onClick={togglePassword}
              >
                Show Key
              </button> */}
              </div>
              <div className={styles.col}>
                {/* <div className={styles.label}>Amount</div> */}
                {dateFormat(dets?.createdAt, "dddd, mmmm dS, yyyy, h:MM:ss TT")}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles.label}>Date</div>
                Secret Key
                {/* {appkeys.publicKey} */}
              </div>

              <div className={styles.col}>
                {passwordShown ? keys?.secretKey : "*************************"}
              </div>
              <div
                className={styles.col}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "10px",
                }}
              >
                <div
                  // className={styles.col}

                  style={{ cursor: "pointer", marginRight: "10px" }}
                  onClick={handleCopy2}
                >
                  {isCopiedsecretKey ? "Copied!" : <FiCopy size={20} />}
                </div>
                <p
                  // className={cn('button-small', styles.button)}
                  onClick={togglePassword}
                  style={{
                    cursor: "pointer",
                    border: "1px solid white",
                    borderRadius: "10px",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  {passwordShown ? "Hide Key" : "View Key"}
                </p>
              </div>
              <div className={styles.col}>
                {dateFormat(dets?.createdAt, "dddd, mmmm dS, yyyy, h:MM:ss TT")}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                APP ID
                {/* {appkeys.publicKey} */}
              </div>

              <div className={styles.col}>{keys?.appId}</div>
              <div
                className={styles.col}
                style={{ cursor: "pointer" }}
                onClick={handleCopy}
              >
                {isCopied ? "Copied!" : <FiCopy size={20} />}
              </div>
              <div className={styles.col}>
                {dateFormat(dets?.createdAt, "dddd, mmmm dS, yyyy, h:MM:ss TT")}
              </div>
            </div>
          </div>
        </div>
        {/* {logo && (
        <div>
          <p>Logo Preview:</p>
          <img src={URL.createObjectURL(logo)} alt='Logo Preview' />
          <p
            // className={cn('button-small', styles.button)}
            onClick={handleSubmit}
            style={{
              cursor: 'pointer',
              border: '1px solid white',
              borderRadius: '10px',
              padding: '5px',
              textAlign: 'center',
            }}
          >
            Upload Logo
          </p>
        </div>
      )} */}
        {/* <div className={styles.foot}>
        <button className={cn('button-stroke button-small', styles.button)}>
          <Loader className={styles.loader} />
          <span>Load more</span>
        </button>
      </div> */}
        {/* <div>card</div> */}
      </Card>
      <div style={{ marginTop: "2rem" }}>
        <Card title="WebHook URL" classTitle={cn("title-green", styles.title)}>
          <div
            style={{
              display: "flex",
              // justifyContent: "flex-center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <TextInput
              name="link-product"
              type="text"
              value={webHookUrl || ""}
              //value={dets.webhook_url || ""}
              placeholder="WebHook URL"
              onChange={(e) => {
                setWebHookUrl(e.target.value);
              }}
              // onChange={handleUrlChange}
              required
              style={{ width: "50rem" }}
            />
            <button
              className={cn("button-small", styles.button)}
              style={{ width: "20rem ", textAlign: "center" }}
              onClick={handleWebHookSubmit}
            >
              {saving ? "Saving..." : "Save"}
            </button>
          </div>
        </Card>
      </div>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Success
          setVisibleModal={setVisibleModal}
          appkeys={appkeys}
          setKeys={setKeys}
        />
      </Modal>
    </>
  );
};

export default Transactions;
