import React from 'react'
import TaxOfficesDash from '../TaxOfficesDash'

function TaxOffices() {
  return (
    <div>
      <TaxOfficesDash />
    </div>
  )
}

export default TaxOffices
