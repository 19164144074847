import "./styles.css";

import React, { useEffect, useState } from "react";

import cn from "classnames";

import styles from "./Overview.module.sass";
import toaster from "../../../../utils/toaster";
import { makeApiCall } from "../../../../lib/api";
import { handleApiError } from "../../../../utils";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Icon from "../../../../components/Icon";
import Dropdown from "../../../../components/Dropdown";
import CustomTooltip from "../../../../components/CustomTooltip";
import CurrencyFormatter from "../../../../utils/CurrencyFormatter";
import Card from "../../../../components/Card";
// import Balance from "../../../../components/Balance";

const intervals = ["This week", "This month", "This year"];

const Overview = ({ className, userProfile }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [isLoading, setIsLoading] = useState(false);

  // const navigate = useNavigate()
  // console.log(userProfile, "userProfile");
  // const darkMode = JSON.parse(localStorage.getItem("darkMode"));
  // console.log(darkMode)
  const [statistics, setStatistics] = useState();
  const getProducts = async () => {
    try {
      setIsLoading(true);
      const response = await makeApiCall("/statistics");

      if (response.status === 200) {
        console.log(response);
        setStatistics(response?.data);
        setIsLoading(false);
      } else {
        toaster("Something Went Wrong", "error");
        setIsLoading(false);
        throw new Error("API request failed");
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);
  const bgColors = {
    background: "#edf8f2",
  };
  // console.log(statistics);
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Overview"
        classTitle="title-purple"
        head={
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={sorting}
            setValue={setSorting}
            options={intervals}
            small
          />
        }
      >
        <div className={styles.overview}>
          <div className="over">
            <div
              style={{
                backgroundColor: `${bgColors.background}`,
                padding: "10px",
                borderRadius: "10px",
              }}
              className={styles.item}
            >
              <div className={styles.icon}>
                <Icon name="payment" size="24" />{" "}
              </div>
              <div className={styles.line}>
                <div className={styles.details}>
                  <div className={styles.category}>
                    Total Male Students
                    {/* <Tooltip
                      className={styles.tooltip}
                      title='Small description'
                      icon='info'
                      place='right'
                    /> */}
                    <CustomTooltip content={userProfile?.maleEnrollment}>
                      <Icon name="info" />{" "}
                    </CustomTooltip>
                  </div>
                  <div className={styles.counter}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <CurrencyFormatter
                          amount={userProfile?.maleEnrollment}
                        />
                      </>
                    )}
                  </div>
                  {/* <div
                    className={styles.indicator}
                    style={{ marginTop: "5px" }}
                  >
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <Balance
                          className={styles.balance}
                          value={statistics?.totalUsers}
                        />
                      </>
                    )}
                    <span>this week</span>
                  </div> */}
                </div>
                {/* <Chart className={styles.chart} item={x} /> */}
              </div>
            </div>
            <div
              style={{
                backgroundColor: `${bgColors.background}`,

                padding: "10px",
                borderRadius: "10px",
              }}
              className={styles.item}
            >
              <div className={styles.icon}>
                <Icon name="payment" size="24" />{" "}
              </div>
              <div className={styles.line}>
                <div className={styles.details}>
                  <div className={styles.category}>
                    Total Female Students
                    {/* <Tooltip
                      className={styles.tooltip}
                      title='Small description'
                      icon='info'
                      place='right'
                    /> */}
                    <CustomTooltip content={userProfile?.femaleEnrollment}>
                      <Icon name="info" />{" "}
                    </CustomTooltip>
                  </div>
                  <div className={styles.counter}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <CurrencyFormatter
                          amount={userProfile?.femaleEnrollment}
                        />

                        {/* {statistics?.totalIndividual} */}
                      </>
                    )}
                  </div>
                  {/* <div className={styles.indicator}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <Balance
                          className={styles.balance}
                          value={statistics?.totalIndividual}
                        />
                      </>
                    )}

                    <span>this week</span>
                  </div> */}
                </div>
                {/* <Chart className={styles.chart} item={x} /> */}
              </div>
            </div>
            <div
              style={{
                backgroundColor: `${bgColors.background}`,

                padding: "10px",
                borderRadius: "10px",
              }}
              className={styles.item}
            >
              <div className={styles.icon}>
                <Icon name="shopping-bag" size="24" />{" "}
              </div>
              <div className={styles.line}>
                <div className={styles.details}>
                  <div className={styles.category}>
                    Total Students
                    {/* <Tooltip
                      className={styles.tooltip}
                      title='Small description'
                      icon='info'
                      place='right'
                    /> */}
                    <CustomTooltip content={userProfile?.totalEnrollment}>
                      <Icon name="info" />{" "}
                    </CustomTooltip>
                  </div>
                  <div className={styles.counter}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <CurrencyFormatter
                          amount={userProfile?.totalEnrollment}
                        />
                        {/* {statistics?.totalCorporate} */}
                      </>
                    )}

                    {/* {statistics?.totalCorporate} */}
                  </div>
                  {/* <div className={styles.indicator}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <Balance
                          className={styles.balance}
                          value={statistics?.totalCorporate}
                        />
                      </>
                    )}
                    <span>this week</span>
                  </div> */}
                </div>
                {/* <Chart className={styles.chart} item={x} /> */}
              </div>
            </div>
            <div
              style={{
                backgroundColor: `${bgColors.background}`,

                padding: "10px",
                borderRadius: "10px",
              }}
              className={styles.item}
            >
              <div className={styles.icon}>
                <Icon name="home" size="24" />{" "}
              </div>
              <div className={styles.line}>
                <div className={styles.details}>
                  <div className={styles.category}>
                    Today Enrollment
                    <CustomTooltip content={userProfile?.todaysEnrollment}>
                      <Icon name="info" />{" "}
                    </CustomTooltip>
                  </div>
                  <div className={styles.counter}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <CurrencyFormatter
                          amount={userProfile?.todaysEnrollment}
                        />
                        {/* {statistics?.totalSchools} */}
                      </>
                    )}
                  </div>
                  {/* <div className={styles.indicator}>
                    {isLoading ? (
                      "****"
                    ) : (
                      <>
                        <Balance
                          className={styles.balance}
                          value={statistics?.totalSchools}
                        />
                      </>
                    )}

                    <span>this week</span>
                  </div> */}
                </div>
                {/* <Chart className={styles.chart} item={x} /> */}
              </div>
            </div>
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Overview;
