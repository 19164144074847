import React, { useState } from 'react'
import styles from './Table.module.sass'
import cn from 'classnames'

import Row from './Row'

// data

import Checkbox from '../../../../components/Checkbox'
import Loader from '../../../../components/Loader'
import NotFound from '../../../NotFound'

const Table = ({
  className,
  activeTable,
  setActiveTable,
  citizens,
  isLoading,
  nodata,
}) => {
  // console.log(citizens, 'Here are you')
  const [chooseAll, setСhooseAll] = useState(false)
  const [activeId, setActiveId] = useState(citizens[0]?.id)

  const [selectedFilters, setSelectedFilters] = useState([])

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id))
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id])
    }
  }

  return (
    <>
      {isLoading ? (
        <div className={styles.foot}>
          <button className={cn('button-stroke button-small', styles.button)}>
            <Loader className={styles.loader} />
            {/* <span>Load more</span> */}
          </button>
        </div>
      ) : nodata === true ? (
        <NotFound />
      ) : (
        <div className={cn(styles.wrapper, className)}>
          <div className={cn(styles.table)}>
            <div className={cn(styles.row, { [styles.active]: activeTable })}>
              <div className={styles.col}>
                <Checkbox
                  className={styles.checkbox}
                  value={chooseAll}
                  onChange={() => setСhooseAll(!chooseAll)}
                />
              </div>
              <div className={styles.col}>ADDRESS</div>
              <div className={styles.col}>GOOGLE ADDRESS</div>
              {/* <div className={styles.col}>PROPERTY ID</div> */}
              <div className={styles.col}>PROPERTY ID</div>
              {/* <div className={styles.col}>Comments</div>
          <div className={styles.col}>Likes</div> */}
            </div>
            <>
              {citizens?.map((x, index) => (
                <Row
                  item={x}
                  key={index}
                  activeTable={activeTable}
                  setActiveTable={setActiveTable}
                  activeId={activeId}
                  setActiveId={setActiveId}
                  value={selectedFilters.includes(x.id)}
                  onChange={() => handleChange(x.id)}
                />
              ))}
            </>
          </div>
        </div>
      )}
    </>
  )
}

export default Table
