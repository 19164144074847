import React, { useEffect, useState } from "react";
import styles from "./ProductActivity.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Item from "./Item";
import { useLocation, useNavigate } from "react-router";
import Form from "../../../components/Form";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";
import Modal from "../../../components/Modal";
import Success from "../../Payouts/Overview/Success";
import { makeApiCall } from "../../../lib/api";
import toaster from "../../../utils/toaster";
import Loader from "../../../components/Loader";

import InfiniteScroll from "react-infinite-scroll-component";

const items = [
  {
    title: "25 Sep - 1 Oct",
    products: {
      counter: 8,
      color: "#B5E4CA",
      value: 37.8,
    },
    views: {
      counter: "24k",
      color: "#CABDFF",
      value: 37.8,
    },
    likes: {
      counter: 48,
      color: "#B1E5FC",
      value: -37.8,
    },
    comments: {
      counter: 16,
      color: "#FFD88D",
      value: -56,
    },
  },
  {
    title: "18 Sep - 24 Oct",
    products: {
      counter: 8,
      color: "#EFEFEF",
      value: 37.8,
    },
    views: {
      counter: "24k",
      color: "#EFEFEF",
      value: -37.8,
    },
    likes: {
      counter: 48,
      color: "#EFEFEF",
      value: 12.8,
    },
    comments: {
      counter: 16,
      color: "#EFEFEF",
      value: -14.1,
    },
  },
];

const ProductActivity = () => {
  const intervals = ["Last 2 weeks", "Last 7 days"];
  const pathname = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();
  const [taxOffices, setTaxOffices] = useState([]);
  // console.log(pathname.pathname)
  const [activeTab, setActiveTab] = useState(intervals[0]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [fetchLimit, setFetchLimit] = useState(8);

  const [totalLength, setTotalLength] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const inc = 10;

  const getTaxOffices = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(
      `/region/taxoffice?size=${fetchLimit}`
      // http://localhost:8888/api/v1/region/taxoffice?size=2
    );
    //  setProducts(response)
    // console.log(response)
    if (response.status === 200) {
      // console.log(response);
      // settaxOffices(response?.data);

      // setCitizens(response?.data);
      let data = response?.data;
      if (taxOffices.length >= response.meta.total_items) {
        setHasMore(false);
        setIsLoading(false);
        return;
      }
      setTaxOffices(data);
      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTaxOffices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchLimit]);
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        if (hasMore === false) return;
        // Check if there is more data to fetch
        // Increment page and fetchLimit
        // setPage((prevPage) => prevPage + 1)
        // console.log('ddd', hasMore)
        setFetchLimit((prevFetched) => prevFetched + inc);
      }
    };
    // Attach the event listener to the scroll event
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Clean up the event listener on unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasMore]);

  return (
    <>
      <Card
        className={styles.card}
        title="Tax Offices"
        classTitle="title-green"
        head={
          <>
            {/* <Dropdown
            className={cn(styles.dropdown, 'mobile-hide')}
            classDropdownHead={styles.dropdownHead}
            value={activeTab}
            setValue={setActiveTab}
            options={intervals}
            small
          /> */}
            <Form
              className={styles.form}
              // value={search}
              // setValue={setSearch}
              // onSubmit={() => handleSubmit()}
              placeholder="Search Tax offices"
              type="text"
              name="search"
              icon="search"
            />
            <Link
              className={cn("button-stroke button-small", styles.button)}
              to=""
              style={{ marginLeft: "12px" }}
              // onClick={goBack}
              onClick={() => setVisibleModal(true)}
            >
              {/* <Icon name='plus' size='18' /> */}

              <span>Add Tax Offices</span>
            </Link>
          </>
        }
      >
        {/* <InfiniteScroll
          dataLength={totalLength}
          next={getTaxOffices}
          hasMore={hasMore}
          scrollableTarget="scrollableDiv"
          onScroll={handleScroll}
          itemsPerPage={5}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        > */}
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Name</div>
            <div className={styles.col}>Code</div>
            {/* <div className={styles.col}>Username</div> */}
            {/* <div className={styles.col}>Likes</div>
            <div className={styles.col}>Comments</div> */}
            {/* {pathname.pathname === '/mdas' ? (
              ''
            ) : (
              <div className={styles.col}>Actions</div>
            )} */}
          </div>

          {taxOffices?.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.col}>
                <div className={styles.label}>Name</div>
                {x.name}
              </div>
              <div className={styles.col}>
                <div className={styles.label}>Code</div>
                {x.code}
              </div>
              {/* <div className={styles.col}>
                  <div className={styles.label}>Username</div>
                  {x.username === null ? 'NAN' : x.username}
                </div> */}
            </div>
          ))}
        </div>

        <div className={styles.nav}>
          {intervals.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: x === activeTab,
              })}
              onClick={() => setActiveTab(x)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
        {isLoading ? (
          <div
            // className={styles.foot}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px",
            }}
          >
            <button className={cn("button-stroke button-small", styles.button)}>
              <Loader className={styles.loader} />
              {/* <span>Load more</span> */}
            </button>
          </div>
        ) : (
          ""
        )}
        {/* </InfiniteScroll> */}
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Success setVisibleModal={setVisibleModal} />
      </Modal>
    </>
  );
};

export default ProductActivity;
