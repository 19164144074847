import React from "react";

const Formatter = ({ amount, currency }) => {
  // Create an Intl.NumberFormat instance with the desired options
  const formatter = new Intl.NumberFormat("en-US", {
    style: currency ? "currency" : "decimal",
    currency: currency || undefined,
    minimumFractionDigits: 2, // Number of decimal places to display
  });

  // Format the amount using the formatter
  const formattedAmount = formatter.format(amount);

  return <span> {formattedAmount}</span>;
};

export default Formatter;
