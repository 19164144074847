import React, { useState } from 'react'
import styles from './ProductsDashboard.module.sass'

// import Overview from './Overview'
import ProductActivity from './ProductActivity'
// import ProductViews from './ProductViews'
// import Products from './Products'
import { NavLink, useLocation } from 'react-router-dom'
// import Card from '../../components/Card'
// import TextInput from '../../components/TextInput'
// import AddingMdas from './AddingMda'
// import AddingRevenue from './AddingRevenue'
import RevenueHeads from '../../RevenueHeads'
import TooltipGlodal from '../../../components/TooltipGlodal'

const ProductsDashboard = () => {
  const pathname = useLocation()
  // console.log(pathname.pathname)
  const [showRevenueHeads, setShowRevenueHeads] = useState(false)
  return (
    <>
      <div className={styles.section}>
        {/* {pathname.pathname === '/revenue' ? (
          ''
        ) : (
          <Overview className={styles.card} />
        )} */}
        {showRevenueHeads ? (
          <RevenueHeads
            setShowRevenueHeads={setShowRevenueHeads}
            showRevenueHeads={showRevenueHeads}
          />
        ) : (
          <ProductActivity
            setShowRevenueHeads={setShowRevenueHeads}
            showRevenueHeads={showRevenueHeads}
          />
        )}

        {/* {pathname.pathname === '/revenue' ? (
          <ProductActivity />
        ) : (
          <>
            <div className={styles.row}>
              <div className={styles.col}>
                <ProductActivity />
              </div>
              <div className={styles.col}>
                {pathname?.pathname === '/mdas' ? <AddingMdas /> : ''}
              </div>
            </div>
          </>
        )} */}

        {/* {pathname === '/mdas' ? <Products /> : ''} */}

        {/* <Products /> */}
      </div>
      <TooltipGlodal />
    </>
  )
}

export default ProductsDashboard
