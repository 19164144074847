import React, { useState } from "react";

import ModalProduct from "../../../../../components/ModalProduct";
import cn from "classnames";
import dateFormat from "dateformat";
import styles from "./Transactions.module.sass";

function Items({ x, sorting }) {
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  return (
    <>
      <div className={styles.col}>
        <div className={styles.label}>Type</div>
        {x.type}
      </div>

      <div className={styles.col}>
        <div className={styles.product}>{x.ip_address}</div>
        {/* <div className={styles.invoice}>{x.invoice}</div> */}
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Date</div>
        {dateFormat(x.updated_at, "dd-mm-yyyy")}
        {/* {x.generation_date} */}
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Action</div>
        <button
          className={cn("button", "btn-bb")}
          onClick={() => setVisibleModalProduct(true)}
        >
          View Report
        </button>
        {/* {x.generation_date} */}
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
        records={x?.encoded_record}
        sorting={sorting}
      />
    </>
  );
}

export default Items;
