import React, { useEffect, useState } from "react";

import Card from "../../../../../components/Card";
import CurrencyFormatter from "../../../../../utils/CurrencyFormatter";
import Dropdown from "../../../../../components/Dropdown";
import Items from "./Items";
import Loader from "../../../../../components/Loader";
import ModalProduct from "../../../../../components/ModalProduct";
import NotFound from "../../../../NotFound";
import cn from "classnames";
import debounce from "lodash/debounce";
// date
// import { transactions } from "../../../../../mocks/transactions";
import { makeReportCall } from "../../../../../lib/reportsApi";
import styles from "./Transactions.module.sass";
import throttle from "lodash/throttle";
import toaster from "../../../../../utils/toaster";

// const intervals = ["Last 30 days", "Last 10 days", "Last 10 days"];

const Transactions = ({ className, transactions }) => {
  const [sorting, setSorting] = useState(transactions[0]);

  // const [transactions, setTransactions] = useState();
  const [paymentRecords, setPaymentRecords] = useState([]);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [nodata, setNodata] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  // const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const inc = 10;
  const tolerance = 3; // Adjust this tolerance value as needed

  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const getEncodedType = async () => {
    setIsLoading(true);
    setIsFetching(true); // Set request in progress
    try {
      const response = await makeReportCall(
        `/payment-type/encodeType/${sorting}?size=${fetchLimit}`
      );
      if (response.status === 200) {
        let data = response?.data;
        setTotalItems(response?.meta.total_items);
        setPaymentRecords(data);
        if (data?.length === totalItems) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }

        if (data?.length === 0) {
          setNodata(true);
        }
        setIsLoading(false);
        setIsFetching(false); // Request is complete
      } else {
        toaster("Something Went Wrong", "error");
        setIsLoading(false);
        setIsFetching(false); // Request is complete
      }
    } catch (error) {
      console.error("Error fetching encoded type:", error);
      toaster("An error occurred while fetching data", "error");
      setIsLoading(false);
      setIsFetching(false); // Request is complete
    }
  };

  useEffect(() => {
    setHasMore(true); // Reset hasMore when sorting changes
    // setFetchLimit(10);
    getEncodedType();
  }, [fetchLimit, sorting]);

  console.log(hasMore, "HasMore");
  console.log(paymentRecords.length, "Payment Record Length");
  console.log(totalItems, "RecordingsSS!!!!!!!!!!!!");
  console.log(paymentRecords?.length === totalItems, "This is the info");

  const handleScroll = () => {
    if (isFetching) return; // Return if a request is already in progress

    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - tolerance
    ) {
      if (!hasMore) return;
      setPage((prevPage) => prevPage + 1);
      setFetchLimit((prevFetched) => prevFetched + inc);
    }
  };

  const throttledHandleScroll = throttle(handleScroll, 500);
  const debouncedThrottledHandleScroll = debounce(throttledHandleScroll, 500);
  // Adjust the throttle interval (in milliseconds) as needed

  useEffect(() => {
    // Attach the event listener to the debounced and throttled handlers
    window.addEventListener("scroll", debouncedThrottledHandleScroll);
    return () => {
      // Clean up the event listener on unmount
      window.removeEventListener("scroll", debouncedThrottledHandleScroll);
    };
  }, [hasMore, isFetching, debouncedThrottledHandleScroll]);

  // console.log(sorting, "paymentRecords");
  return (
    <Card
      className={cn(styles.card, className)}
      classCardHead={styles.head}
      title="Transactions"
      classTitle={cn("title-blue", styles.title)}
      head={
        <>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={sorting}
            setValue={setSorting}
            options={transactions}
            small
          />
          {/* <button className={cn("button-small", styles.button)}>
            Download SCV
          </button> */}
        </>
      }
    >
      {nodata === true ? (
        <div
          style={{
            justifyItems: "center",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <NotFound />
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Type</div>
              <div className={styles.col}>IP ADDRESS</div>
              <div className={styles.col}>Date</div>
              <div className={styles.col}>Action</div>
            </div>

            <>
              {paymentRecords?.map((x, index) => (
                <div
                  className={styles.row}
                  key={index}
                  style={{ cursor: "pointer" }}
                >
                  <Items x={x} sorting={sorting} />
                </div>
              ))}
            </>
          </div>
        </div>
      )}
      <div className={styles.foot}>
        {isLoading ? (
          <div
            // className={styles.foot}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px",
            }}
          >
            <button className={cn("button-stroke button-small", styles.button)}>
              <Loader className={styles.loader} />
              {/* <span>Load more</span> */}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </Card>
  );
};

export default Transactions;
