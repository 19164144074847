import React, { useCallback, useEffect, useRef, useState } from "react";

import styles from "../ProductsDashboard.module.sass";
// import styles1 from "../../../../Notification/Notification.module.sass";

import cn from "classnames";

// import Filters from "../Filters";
import Card from "../../../../../components/Card";
import TextInput from "../../../../../components/TextInput";
import { makeApiCall } from "../../../../../lib/api";
import toaster from "../../../../../utils/toaster";

// const filters = []

// console.log(filters);
function AddServices({ setVisibleModal, getRevenueHeads, filters }) {
  console.log(filters, " Filters");
  const [isLoading, setIsLoading] = useState(false);
  // const [selectedFilters, setSelectedFilters] = useState([]);
  const [newPaymentDetails, setNewPaymentDetails] = useState({
    service: "",
    label: "",
  });

  // const dataFetchedRef = useRef(false);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewPaymentDetails({
      ...newPaymentDetails,
      [name]: value,
    });
  };
  // const roleData = {
  //   features: selectedFilters,
  //   name: newPaymentDetails.channel,
  // };
  // console.log(roleData);
  console.log(newPaymentDetails);

  const createRole = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await makeApiCall(`/app-roles`, "POST", newPaymentDetails);
    if (response.status === 200) {
      toaster(response.message, "success");
      setIsLoading(false);
      setVisibleModal(false);
      getRevenueHeads();
      // setFeatures(response.data);
      // console.log(response)
      // let data = response.data;
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };
  // const createPaymentGateway = async () => {
  //   // e.preventDefault()
  //   setIsLoading(true);
  //   const response = await makeApiCall(
  //     `/payment/methods`,
  //     "POST",
  //     newPaymentDetails
  //   );
  //   if (response.status === 200) {
  //     toaster(response.message, "success");
  //     setVisibleModal(false);
  //     setIsLoading(false);
  //     getRevenueHeads();
  //   } else {
  //     toaster("Something Went Wrong", "error");
  //     setIsLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   if (dataFetchedRef.current) return;
  //   dataFetchedRef.current = true;
  // }, []);
  // console.log(features);
  // for (const object of features) {
  //   filters.push(object.name);
  // }
  // console.log(selectedFilters);
  // console.log(roleData, 'roleData')
  return (
    <>
      <Card
        className={styles.card}
        title="Add Service"
        classTitle="title-green"
      >
        <form onSubmit={createRole}>
          <div style={{ marginBottom: "12px" }}>
            <TextInput
              className={``}
              name="service"
              type="text"
              placeholder="Service Name"
              required
              icon="profile-circle"
              onChange={handleChange}
            />
          </div>
          <div style={{ marginBottom: "12px" }}>
            <TextInput
              className={``}
              name="label"
              type="text"
              placeholder="Service Route"
              required
              icon="profile-circle"
              onChange={handleChange}
            />
          </div>

          {/* <div className={styles.col}> */}
          {/* <div
            style={{
              marginBottom: "12px",
              marginTop: "12px",
              // border: '2px solid red',
            }}
          >
            <Filters
              className={styles1.filters}
              filters={filters}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              title="Assign Features"
              classTitle="title-blue"
            />
          </div> */}
          {/* </div> */}

          <button
            className={cn("button")}
            style={{ width: "100%" }}
            // onClick={() => createPaymentGateway()}
            // disabled={
            //   loginDetails.email === '' ||
            //   loginDetails.password === '' ||
            //   loading === true
            // }
          >
            {isLoading ? "Please Wait ......" : "Add"}
          </button>
        </form>
      </Card>
    </>
  );
}

export default AddServices;
