import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignUp.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import Entry from "./Entry";
// import Code from './Code'
import Image from "../../components/Image";
import "./SignUpStyle.css";
import { requestPermission } from "../../firebase";
const items = [
  "Unlimited product uploads",
  "Pro tips",
  "Free forever",
  "Full author options",
];

const SignUp = () => {
  const [visible, setVisible] = useState(true);
  const heightWindow = use100vh();

  return (
    <div className={`home`}>
      <div className={` wrap`}>
        <div className={` wrap`}>
          {/* <div className={styles.preview}>
            <img src='/images/content/login-pic.png' alt='Login' />
          </div>
          <div className={cn('h4', styles.subtitle)}>
            Central Authentication System
          </div> */}
          {/* <ul className={styles.list}>
            {items.map((x, index) => (
              <li key={index}>{x}</li>
            ))}
          </ul> */}
        </div>
      </div>
      <div className={`${styles.col} left`} style={{ minHeight: heightWindow }}>
        <div className={styles.head}>
          <Link className={styles.logo} to="/">
            <Image
              className={styles.pic}
              src="/images/content/login-pic.png"
              srcDark="/images/content/login-pic.png"
              alt="Core"
            />
          </Link>
        </div>
        <div className={`input-wrap`}>
          <div className={cn("h2", styles.title)}>Login</div>
          <Entry onConfirm={() => setVisible(false)} />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
