import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import ActiveCustomers from "./ActiveCustomers";
import AppStatsView from "./AppStatsView";
import Card from "../../components/Card";
// import CurrentYearOverview from "./CurrentYearOverview";
// import Dropdown from "../../components/Dropdown";
import Icon from "../../components/Icon";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import Overview from "./Overview";
import Transactions from "./Transactions";
import cn from "classnames";
import { makeApiCall } from "../../lib/api";
// import { makeReportCall } from "../../lib/reportsApi";
import styles from "../../screens/Statements/Statements.module.sass";
// import styles1 from "./Overview/Overview.module.sass";
import styles2 from "../UserProfile/Profile/Profile.module.sass";
import toaster from "../../utils/toaster";
import TextInput from "../../components/TextInput";
import "./AppkeysStye.css";
import SingleAppService from "../../components/SingleAppServies/SingleAppService";

function Appkeys({ setShowKeys, getProducts, className }) {
  const [intervals1, setIntervals1] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(false);
  const [appDetails, setAppDetails] = useState({});
  const [appStatsDetails, setAppStatsDetails] = useState({});
  const [appStatsGraph, setAppsStatsGraph] = useState([]);
  const [paymentRecordStats, setPaymentRecordStats] = useState([]);
  const [sorting, setSorting] = useState(intervals1[0] || 2023);
  const [graphLoading, setGraphLoading] = useState(false);
  const [secondSorting, setSecondSorting] = useState(intervals1[0] || 2023);
  const [logo, setLogo] = useState(null);
  const appID = params.get("id");

  const getUserProfile = async () => {
    setIsLoading(true);
    const response = await makeApiCall(`/apps/${appID}`);
    if (response.status === 200) {
      setAppDetails(response?.data);
      setIsLoading(false);
    }
  };

  // Getting all statistics
  const getStatistics = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(
      `/app-request/appStats/${appID}?year=${currentYear}`
    );
    if (response.status === 200) {
      setAppStatsDetails(response.data);
      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };

  // Get Statistics Graph
  const getStatisticsGraph = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(`/app-request/appRequestGraph/${appID}`);
    if (response.status === 200) {
      setAppsStatsGraph(response.data);
      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };

  // Get the Payment Records Graphs
  const getPaymentGraph = async () => {
    // e.preventDefault()
    setGraphLoading(true);
    const response = await makeApiCall(
      `/app-request/singleGraph/${appID}?year1=${sorting}&year2=${secondSorting}`
    );
    if (response.status === 200) {
      setPaymentRecordStats(response.data);
      setGraphLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setGraphLoading(false);
    }
  };

  // Getting all the years statistic
  const getYearStatistics = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(`/payment/paymentyears`);
    if (response.status === 200) {
      setIntervals1(response?.data);
      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    setLogo(file);
  };
  const handleSubmit = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(
      `/files/upload/${appDetails?.id}`,
      "POST",
      {
        file: logo,
      },
      "multipart/form-data"
    );
    if (response.status === true) {
      toaster(response.uploadedFile.message, "success");
      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
    // console.log(response)
  };

  // Use Effect For Getting Data at first page load
  useEffect(() => {
    getUserProfile();
    getStatistics();
    getStatisticsGraph();
    getPaymentGraph();
    getYearStatistics();
  }, [appID]);

  useEffect(() => {
    // Function to update the current year every second (1000ms)
    const updateCurrentYear = () => {
      setCurrentYear(new Date().getFullYear());
    };

    // Set up a timer to update the year every second
    const intervalId = setInterval(updateCurrentYear, 1000);

    // Clean up the timer when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <div className={styles.section}>
        {isLoading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // border: '2px solid red',
              height: "100vh",
            }}
          >
            <Loader className={styles.loader} />
          </div>
        ) : (
          <>
            <Card
              head={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {logo && (
                    <div
                      className={cn(
                        "button-stroke button-small",
                        styles.button
                      )}
                      to=""
                      // onClick={goBack}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={handleSubmit}
                    >
                      {isLoading ? (
                        "Uploading"
                      ) : (
                        <>
                          <Icon name="add" size="24" />
                          <span>Upload</span>
                        </>
                      )}
                    </div>
                  )}

                  <Link
                    className={cn("button-stroke button-small", styles.button)}
                    to=""
                    onClick={goBack}
                    style={{ marginRight: "12px" }}
                  >
                    <Icon name="arrow-left" size="24" />
                    <span>Back</span>
                  </Link>
                  {/* <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={sorting}
              setValue={setSorting}
              options={intervals}
              small
            /> */}
                  <div
                    className={styles2.details}
                    style={{ marginLeft: "10px" }}
                  >
                    <div className={styles2.avatar}>
                      {logo ? (
                        <img
                          src={URL.createObjectURL(logo)}
                          alt="Logo Preview"
                        />
                      ) : (
                        <img
                          alt="Avatar"
                          src={
                            appDetails?.appLogo === null
                              ? "/images/content/default.png"
                              : appDetails?.appLogo
                          }
                        />
                      )}

                      <input
                        type="file"
                        id="logoUpload"
                        accept="image/*"
                        onChange={handleLogoChange}
                        style={{ display: "none" }} // Hide the default file input display
                      />
                      <label
                        htmlFor="logoUpload"
                        className={styles2.add}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Icon name="add" />
                      </label>
                    </div>
                  </div>
                </div>
              }
              className={cn(styles.card, className)}
              title={`${appDetails.appName}`}
              classTitle={cn("title-red", styles.cardTitle)}
              classCardHead={styles.cardHead}
            />
            <AppStatsView appStatsDetails={appStatsDetails} />
            {/* <CurrentYearOverview appStatsDetails={appStatsDetails} /> */}
            <ActiveCustomers
              appStatsGraph={appStatsGraph}
              appkeys={appDetails}
              intervals1={intervals1}
            />
            <Overview
              appkeys={appDetails}
              setShowKeys={setShowKeys}
              paymentRecordStats={paymentRecordStats}
              sorting={sorting}
              setSorting={setSorting}
              secondSorting={secondSorting}
              setSecondSorting={setSecondSorting}
              intervals1={intervals1}
              getPaymentGraph={getPaymentGraph}
              isLoading={graphLoading}
            />
            <div style={{ marginTop: "2rem" }}>
              <Transactions
                appkeys={appDetails?.appKeys}
                setShowKeys={setShowKeys}
                dets={appDetails}
                getProducts={getProducts}
              />
            </div>
            <div style={{ marginTop: "2rem" }}>
              <SingleAppService appID={appID} appkeys={appDetails} />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Appkeys;
