import "./styles.css";

import React, { useEffect, useState } from "react";

import Balance from "../../../../../components/Balance";
import Card from "../../../../../components/Card";
import CurrencyFormatter from "../../../../../utils/CurrencyFormatter";
import CustomTooltip from "../../../../../components/CustomTooltip";
import Dropdown from "../../../../../components/Dropdown";
import Icon from "../../../../../components/Icon";
import Items from "./items";
import Loader from "../../../../../components/Loader";
import Tooltip from "../../../../../components/Tooltip";
import TooltipGlodal from "../../../../../components/TooltipGlodal";
import cn from "classnames";
import { makeApiCall } from "../../../../../lib/api";
import styles from "./Overview.module.sass";
import toaster from "../../../../../utils/toaster";

const intervals = ["This week", "This month", "This year"];

const Overview = ({ className, statistics }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [isLoading, setIsLoading] = useState(false);
  const darkMode = JSON.parse(localStorage.getItem("darkMode"));

  const bgColors = {
    background: "#edf8f2",
  };
  // console.log(statistics);
  return (
    <>
      <Card className={cn(styles.card, className)}>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100px",
              justifyItems: "center",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div className="coverring-stage">
            {statistics?.map((stats, index) => (
              <>
                <Items stats={stats} index={index} isLoading={isLoading} />
              </>
            ))}
          </div>
        )}
      </Card>
      <TooltipGlodal />

      {/* <div className={styles.list}>
        {items.map((x, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.icon} style={{ backgroundColor: x.color }}>
              <Icon name={x.icon} size="24" />
            </div>
            <div className={styles.details}>
              <div className={styles.label}>
                {x.title}
                <Tooltip
                  className={styles.tooltip}
                  title={x.tooltip}
                  icon="info"
                  place="top"
                />
              </div>
              <div className={styles.counter}>{x.counter}</div>
              <div className={styles.indicator}>
                <Balance className={styles.balance} value={x.value} />
                <span>this week</span>
              </div>
            </div>
          </div>
        ))}
      </div> */}
    </>
  );
};

export default Overview;
