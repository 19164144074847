import React, { useState } from 'react'
import styles from './Table.module.sass'
import cn from 'classnames'
import Checkbox from '../Checkbox'
import Loader from '../Loader'
import Row from './Row'

const Table = ({ items, title, isLoading }) => {
  const [chooseAll, setСhooseAll] = useState(false)

  const [selectedFilters, setSelectedFilters] = useState([])

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id))
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id])
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Checkbox
              className={styles.checkbox}
              value={chooseAll}
              onChange={() => setСhooseAll(!chooseAll)}
            />
          </div>
          <div className={styles.col}>Product</div>
          {/* <div className={styles.col}>Price</div> */}
          <div className={styles.col}>{title}</div>
        </div>
        {items.map((x, index) => (
          <Row
            item={x}
            key={index}
            index={index}
            value={selectedFilters.includes(x.id)}
            onChange={() => handleChange(x.id)}
          />
        ))}
      </div>
      {isLoading ? (
        <div className={styles.foot}>
          <button className={cn('button-stroke button-small', styles.button)}>
            <Loader className={styles.loader} />
            {/* <span>Load more</span> */}
          </button>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default Table
