import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import Card from "../../../../../components/Card";
import Items from "./items";
import Loader from "../../../../../components/Loader";
import NotFound from "../../../../NotFound/index";
import cn from "classnames";
import { makeApiCall } from "../../../../../lib/api";
import { makeReportCall } from "../../../../../lib/reportsApi";
import { numberWithCommas } from "../../../../../utils.js";
import styles from "./Table.module.sass";
import toaster from "../../../../../utils/toaster";

const items = [
  {
    date: "Fri, Apr 9",
    status: false,
    sales: 28,
    earnings: 3140,
  },
  {
    date: "Sat, Apr 10",
    status: true,
    sales: 24,
    earnings: 2568,
  },
  {
    date: "Sun, Apr 11",
    status: false,
    sales: 16,
    earnings: 1375.88,
  },
  {
    date: "Mon, Apr 12",
    status: true,
    sales: 48,
    earnings: 4955.86,
  },
  {
    date: "Tue, Apr 13",
    status: true,
    sales: 32,
    earnings: 2233.44,
  },
  {
    date: "Wed, Apr 14",
    status: false,
    sales: 64,
    earnings: 6140,
  },
  {
    date: "Thu, Apr 15",
    status: true,
    sales: 8,
    earnings: 789.32,
  },
];

const Table = () => {
  const [fetchLimit, setFetchLimit] = useState(5);

  const [totalLength, setTotalLength] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [topMDAs, setTopMDAs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nodat, setNodata] = useState(false);

  const inc = 10;
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const channel = params.get("channel");
  const getSingleMdaPayments = async () => {
    // e.preventDefault()
    setIsLoading(true);

    const response = await makeReportCall(
      `/payment-gateway/recent-payment/${channel}?size=${fetchLimit}&page=${page}`
    );
    if (response.status === 200) {
      // console.log(response, 'Stats')
      let data = await response?.data;
      // setTotalLength(response?.meta?.total_items)
      // console.log(data);
      setTopMDAs(data);

      if (data.length >= response.meta.total_items) {
        setHasMore(false);
        setIsLoading(false);
        return;
      }
      // console.log(topMDAs?.length, "Top MDas Records");
      // console.log(response?.meta.total_items, "Top MDas Records second");
      if (data.length === 0) {
        setNodata(true);
      }

      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSingleMdaPayments();
  }, [fetchLimit]);

  console.log(topMDAs, "This is No data");

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        // if (hasMore === false) return;
        // setFetchLimit((prevFetched) => prevFetched + inc);
        console.log("I don reach ooo");
      }
    };
    // Attach the event listener to the scroll event
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Clean up the event listener on unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasMore]);

  return (
    <div style={{ marginBottom: "2rem" }}>
      <Card title="Most Recent Payment Report" classTitle="title-blue">
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Name</div>
            <div className={styles.col}>Reference</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Status</div>
          </div>
          {topMDAs.map((x, index) => (
            <div className={styles.row} key={index}>
              <Items x={x} />
            </div>
          ))}
        </div>
        {nodat && <NotFound />}
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader className={styles.loader} />
          </div>
        ) : (
          ""
        )}
      </Card>
    </div>
  );
};

export default Table;
