import React, { useCallback, useEffect, useState } from "react";
import styles from "./ProductActivity.module.sass";
import cn from "classnames";

import { useLocation, useNavigate } from "react-router";

import { Link } from "react-router-dom";

import InfiniteScroll from "react-infinite-scroll-component";
import { makeApiCall } from "../../../../lib/api";
import toaster from "../../../../utils/toaster";
import Card from "../../../../components/Card";
import Form from "../../../../components/Form";
import Loader from "../../../../components/Loader";
import Modal from "../../../../components/Modal";
import Success from "../../../Payouts/Overview/Success";

const ProductActivity = ({ setShowRevenueHeads, showRevenueHeads }) => {
  const intervals = ["Last 2 weeks", "Last 7 days"];
  const pathname = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [mdas, setMdas] = useState([]);
  // console.log(pathname.pathname)
  const [activeTab, setActiveTab] = useState(intervals[0]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [fetchLimit, setFetchLimit] = useState(10);

  const [totalLength, setTotalLength] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const inc = 10;

  const getMDAs = async () => {
    // e.preventDefault()
    setIsLoading(true);

    const response = await makeApiCall(
      `/payment/mdas?size=${fetchLimit}&page=${page}`
    );
    if (response.status === 200) {
      let data = await response?.data;
      // setTotalLength(response?.meta?.total_items)
      // if (mdas.length >= response.meta.total_items) {
      //   setHasMore(false)
      //   setIsLoading(false)
      //   return
      // }
      setMdas(data?.mdas);
      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMDAs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchLimit]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        if (hasMore === false) return;
        // Check if there is more data to fetch
        // Increment page and fetchLimit
        // setPage((prevPage) => prevPage + 1)
        // console.log('ddd', hasMore)
        setFetchLimit((prevFetched) => prevFetched + inc);
      }
    };
    // Attach the event listener to the scroll event
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Clean up the event listener on unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasMore]);
  return (
    <>
      <Card
        className={styles.card}
        title={"Revenue Heads"}
        classTitle="title-green"
        head={
          <>
            {/* <Dropdown
            className={cn(styles.dropdown, 'mobile-hide')}
            classDropdownHead={styles.dropdownHead}
            value={activeTab}
            setValue={setActiveTab}
            options={intervals}
            small
          /> */}
            <Form
              className={styles.form}
              // value={search}
              // setValue={setSearch}
              // onSubmit={() => handleSubmit()}
              placeholder="Search Revenue Heads"
              type="text"
              name="search"
              icon="search"
            />
            <Link
              className={cn("button-stroke button-small", styles.button)}
              to=""
              style={{ marginLeft: "12px" }}
              // onClick={goBack}
              onClick={() => setVisibleModal(true)}
            >
              <span>Add Revenue</span>
            </Link>
          </>
        }
      >
        {/* <InfiniteScroll
          dataLength={totalLength}
          // next={getMDAs}
          hasMore={hasMore}
          scrollableTarget='scrollableDiv'
          onScroll={handleScroll}
          itemsPerPage={10}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        > */}
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Name</div>
            <div className={styles.col}>Merchant Name</div>
            <div className={styles.col}>Code</div>
            <div className={styles.col}>Company ID</div>
            <div className={styles.col}>Actions</div>
          </div>

          {mdas?.map((x, index) => (
            <div className={styles.row} key={index}>
              <>
                <div className={styles.col}>
                  {/* <div className={styles.label}>Name</div> */}

                  {x.name}
                </div>

                <div className={styles.col}>
                  {/* <div className={styles.label}>Merchant Name</div> */}
                  {x.merchant_name}
                </div>
                <div className={styles.col}>
                  {/* <div className={styles.label}>Code</div> */}
                  {x.code}
                </div>
                <div className={styles.col}>
                  {/* <div className={styles.label}>Company ID</div> */}
                  {x?.company_id}
                </div>
              </>

              {/* <div className={styles.col}>
                  <div className={styles.label}>Comments</div>
                  <Item className={styles.item} item={x.comments} />
                </div> */}
              {pathname.pathname === "/mdas" ? (
                ""
              ) : (
                <div className={styles.col}>
                  <div className={styles.label}>Action</div>
                  {/* <p onClick={() => alert('Yooooo')}>Hello</p> */}
                  <button
                    className={cn("button")}
                    style={{ width: "100%" }}
                    onClick={() => {
                      setShowRevenueHeads(true);
                      localStorage.setItem("MdaCode", JSON.stringify(x.code));
                      localStorage.setItem("MdaName", JSON.stringify(x.name));
                    }}
                    // disabled={
                    //   loginDetails.email === '' ||
                    //   loginDetails.password === '' ||
                    //   loading === true
                    // }
                  >
                    {/* {loading ? (
              <ScaleLoader color='#fff' speedMultiplier={2} width={4} />
            ) : (
              'Login'
            )} */}
                    View
                  </button>
                  {/* <Item className={styles.item} item={x.comments} /> */}
                </div>
              )}
            </div>
          ))}
        </div>
        {isLoading ? (
          <div
            // className={styles.foot}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px",
            }}
          >
            <button className={cn("button-stroke button-small", styles.button)}>
              <Loader className={styles.loader} />
              {/* <span>Load more</span> */}
            </button>
          </div>
        ) : (
          ""
        )}
        {/* </InfiniteScroll> */}

        <div className={styles.nav}>
          {intervals.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: x === activeTab,
              })}
              onClick={() => setActiveTab(x)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
        {/* {isLoading ? (
          ""
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <button
              className={cn("button-stroke button-small", styles.button)}
              onClick={handleScroll}
            >
              Load more
            </button>
          </div>
        )} */}
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Success setVisibleModal={setVisibleModal} mdas={mdas} />
      </Modal>
    </>
  );
};

export default ProductActivity;
