import React from 'react'
import cn from 'classnames'
import styles from './Balance.module.sass'
import Icon from '../Icon'
import CurrencyFormatter from '../../utils/CurrencyFormatter'

const Balance = ({ className, value, background }) => {
  return value > 0 ? (
    <div
      className={cn(
        styles.positive,
        { [styles.background]: background },
        className
      )}
    >
      <Icon name='arrow-top' />
      <CurrencyFormatter amount={value} />%
    </div>
  ) : (
    <div
      className={cn(
        styles.negative,
        { [styles.background]: background },
        className
      )}
    >
      <Icon name='arrow-bottom' />
      <CurrencyFormatter amount={value} />%{/* {String(value).slice(1)}% */}
      {/* {value}% */}
    </div>
  )
}

export default Balance
