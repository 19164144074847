import React, { useState } from "react";
import Card from "../../../components/Card";
import styles from "../../ProductsDashboard/ProductsDashboard.module.sass";
import cn from "classnames";
import { requestPermission } from "../../../firebase";

function AcceptNotification({ setVisibleModal }) {
  // console.log(newPaymentDetails, 'newPaymentDetails')
  const whatToDo = () => {
    // requestPermission();
    setVisibleModal(false);
  };
  return (
    <>
      <Card
        className={styles.card}
        // title="Add Triggers"
        // classTitle="title-blue"
      >
        <h2>Allow Push Notification</h2>
        <button
          className={cn("button")}
          style={{ width: "100%", marginTop: "2rem" }}
          onClick={whatToDo}
        >
          Ok
        </button>
      </Card>
    </>
  );
}

export default AcceptNotification;
