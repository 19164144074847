import React, { useEffect, useState } from "react";

import Card from "../../../../../components/Card";
import CurrencyFormatter from "../../../../../utils/CurrencyFormatter";
import Dropdown from "../../../../../components/Dropdown";
import Loader from "../../../../../components/Loader";
import NotFound from "../../../../NotFound";
import cn from "classnames";
import dateFormat from "dateformat";
// date
// import { transactions } from "../../../../../mocks/transactions";
import { makeReportCall } from "../../../../../lib/reportsApi";
import { numberWithCommas } from "../../../../../utils.js";
import { refetchPayment } from "../../../../../firebase";
import styles from "./Transactions.module.sass";
import toaster from "../../../../../utils/toaster";
import Debounce from "../../../../../utils/debounce.js";

// const intervals = ["Last 30 days", "Last 20 days", "Last 10 days"];
const intervals = ["Most Recent"];

const Transactions = ({ className }) => {
  const [sorting, setSorting] = useState(intervals[0]);

  const [transactions, setTransactions] = useState();
  const [fetchLimit, setFetchLimit] = useState(10);
  const [nodata, setNodata] = useState(false);

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const inc = 10;

  const [isLoading, setIsLoading] = useState(false);
  const getReports = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeReportCall(
      `/formal-sector/recent-payment?size=${fetchLimit}`
    );

    if (response.status === 200) {
      let data = response?.data;
      // console.log(data, "data....");
      if (transactions?.length >= response?.meta.total_items) {
        setHasMore(false);
        setIsLoading(false);
        return;
      }
      if (data?.length === 0) {
        setNodata(true);
      }
      setTransactions(data);
      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getReports();
  }, [fetchLimit]);
  // refetchPayment(getReports);
  Debounce(refetchPayment(getReports), 2500);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        if (hasMore === false) return;
        // Check if there is more data to fetch
        // Increment page and fetchLimit
        // setPage((prevPage) => prevPage + 1)
        // console.log('ddd', hasMore)
        setFetchLimit((prevFetched) => prevFetched + inc);
      }
    };
    // Attach the event listener to the scroll event
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Clean up the event listener on unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasMore]);
  // console.log(transactions);
  return (
    <Card
      className={cn(styles.card, className)}
      classCardHead={styles.head}
      title="Transactions"
      classTitle={cn("title-blue", styles.title)}
      head={
        <>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={sorting}
            setValue={setSorting}
            options={intervals}
            small
          />
          {/* <button className={cn("button-small", styles.button)}>
            Download SCV
          </button> */}
        </>
      }
    >
      {nodata === true ? (
        <div
          style={{
            justifyItems: "center",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <NotFound />
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Name</div>
              <div className={styles.col}>Amount</div>
              <div className={styles.col}>Reference</div>
              <div className={styles.col}>TIN</div>
              <div className={styles.col}>Status</div>
              <div className={styles.col}>Generation Date</div>
            </div>

            <>
              {transactions?.map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    <div className={styles.label}>Name</div>
                    {x.payer_name}
                  </div>

                  <div className={styles.col}>
                    <div className={styles.product}>
                      ₦{x.amount.toLocaleString("en-US")}
                    </div>
                    {/* <div className={styles.invoice}>{x.invoice}</div> */}
                  </div>
                  <div className={styles.col}>
                    <div className={styles.label}>Price</div>
                    {x.reference}
                  </div>
                  <div className={styles.col}>
                    <div className={styles.label}>Amount</div>
                    {x.tin}
                    {/* {x.earnings > 0 ? (
                  <div>+${numberWithCommas(x.earnings.toFixed(2))}</div>
                ) : (
                  <div className={styles.negative}>
                    -${(numberWithCommas(x.earnings) * -1).toFixed(2)}
                  </div>
                )} */}
                  </div>
                  <div className={styles.col}>
                    {/* <div className={styles.details}>
                  <div className={styles.product}>{x.payer_name}</div>
                  <div className={styles.invoice}>{x.status}</div>
                </div> */}
                    {x.status === "Approved" ? (
                      <div
                        className={cn(
                          { "status-green-dark": x.status === "Approved" },
                          styles.status
                        )}
                      >
                        Approved
                      </div>
                    ) : (
                      <div
                        className={cn(
                          {
                            "status-red-dark":
                              x.status === "Payment Reference Generated",
                          },
                          styles.status
                        )}
                      >
                        Payment Reference Generated
                      </div>
                    )}
                  </div>
                  <div className={styles.col}>
                    <div className={styles.label}>Date</div>
                    {dateFormat(x.generation_date, "dd-mm-yyyy")}
                    {/* {x.generation_date} */}
                  </div>
                </div>
              ))}
            </>
          </div>
        </div>
      )}
      <div className={styles.foot}>
        {isLoading ? (
          <div
            // className={styles.foot}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px",
            }}
          >
            <button className={cn("button-stroke button-small", styles.button)}>
              <Loader className={styles.loader} />
              {/* <span>Load more</span> */}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </Card>
  );
};

export default Transactions;
