import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import dateFormat, { masks } from "dateformat";

import Balance from "../../../components/Balance";
import Card from "../../../components/Card";
import Chart from "./Chart";
import Dropdown from "../../../components/Dropdown";
import Icon from "../../../components/Icon";
import cn from "classnames";
import { makeApiCall } from "../../../lib/api";
import styles from "./Overview.module.sass";
import styles1 from "../../UserProfile/Profile/Profile.module.sass";
import toaster from "../../../utils/toaster";

const intervals = ["Last 28 days", "Last 14 days", "Last 7 days"];

const Overview = ({
  className,
  paymentRecordStats,
  sorting,
  setSorting,
  secondSorting,
  setSecongSorting,
  intervals1,
  getPaymentGraph,
  isLoading,
}) => {
  const refinedData = paymentRecordStats?.graphValues?.firstYear?.map(
    (d, i) => ({
      name: d?.month_name,
      [sorting]: d?.totalAmount,
      [secondSorting]:
        paymentRecordStats?.graphValues?.secondYear[i]?.totalAmount,
    })
  );

  return (
    <Card
      head={
        <>
          <div style={{ marginRight: "1rem" }}>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={sorting}
              setValue={setSorting}
              options={intervals1}
              small
            />
          </div>
          <div style={{ marginRight: "1rem" }}>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={secondSorting}
              setValue={setSecongSorting}
              options={intervals1}
              small
            />
          </div>
          <button
            className={cn("button", styles.button)}
            onClick={() => getPaymentGraph()}
          >
            Compare
          </button>
        </>
      }
      className={cn(styles.card, className)}
      title={`Payments Records`}
      classTitle={cn("title-red", styles.cardTitle)}
      classCardHead={styles.cardHead}
    >
      <div className={styles.overview}>
        <div className={styles.details}>
          {/* <div className={cn("h4", styles.title)}>1,509 Users</div> */}
          {/* <div className={styles.line}>
            <Balance className={styles.balance} value="37.8" background /> vs.
            {dateFormat(now, "dddd, mmmm dS, yyyy, h:MM:ss TT")}
          </div> */}
        </div>
        <Chart
          refinedData={refinedData}
          sorting={sorting}
          secondSorting={secondSorting}
          isLoading={isLoading}
        />
        {/* <Users className={styles.users} /> */}
      </div>
    </Card>
  );
};

export default Overview;
