import React, { useState } from "react";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import styles from "../ProductsDashboard.module.sass";
import cn from "classnames";
import { makeApiCall } from "../../../lib/api";
import toaster from "../../../utils/toaster";
import Loader from "../../../components/Loader";
import { ScaleLoader } from "react-spinners";
import Business from "../../Business/Index";

function AddBussinessSector({ setVisibleModal, getRevenueHeads }) {
  const item = JSON.parse(localStorage.getItem("Business"));
  //   console.log(item);

  const [businessName, setBusinessName] = useState(item.name);
  const [businessType, setBusinessType] = useState(item.type);
  const [businessVersion, setBusinessVersion] = useState(item.version);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateBusiness = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // http://localhost:8888/api/v1/region/sector/20
    const response = await makeApiCall(
      `/region/sector/${item?.id}`,
      "PATCH",
      {
        name: businessName,
        type: businessType,
        version: businessVersion,
      }
      // 'multipart/form-data'
    );

    if (response.status === 200) {
      toaster(response.message, "success");
      getRevenueHeads();

      //   console.log(response);
      setIsLoading(false);
      //setWebHookUrl("");
      setVisibleModal(false);
      //   setSaving(false)
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
      //   setSaving(false)
      //setWebHookUrl("");
    }
  };
  return (
    <>
      <Card
        className={styles.card}
        title="Update Business Sector"
        classTitle="title-blue"
      >
        <form onSubmit={handleUpdateBusiness}>
          <div style={{ marginBottom: "12px" }}>
            <TextInput
              className={``}
              name="name"
              type="text"
              placeholder="Enter Business Name"
              required
              icon="profile-circle"
              value={businessName || ""}
              onChange={(e) => {
                setBusinessName(e.target.value);
              }}
              // onChange={handleUserInputs}
            />
          </div>
          <div style={{ marginBottom: "12px" }}>
            <TextInput
              className={``}
              name="type"
              type="text"
              placeholder="Enter  Business Type"
              // required
              icon="lock"
              value={businessType || ""}
              onChange={(e) => {
                setBusinessType(e.target.value);
              }}
              // onChange={handleUserInputs}
            />
          </div>
          <div style={{ marginBottom: "12px" }}>
            <TextInput
              className={``}
              name="version"
              type="number"
              placeholder="Enter Version (OPTIONAL)"
              //   required
              icon="mobile"
              value={businessVersion || ""}
              onChange={(e) => {
                setBusinessVersion(e.target.value);
              }}
              // onChange={handleUserInputs}
            />
          </div>
          {/* <div style={{ marginBottom: '12px' }}>
          <TextInput
            className={``}
            name='username'
            type='text'
            placeholder='Enter Username'
            required
            icon='lock'
            // onChange={handleUserInputs}
          />
        </div>
        <div style={{ marginBottom: '12px' }}>
          <TextInput
            className={``}
            name='password'
            type='password'
            placeholder='Enter password'
            required
            icon='lock'
            // onChange={handleUserInputs}
          />
        </div> */}
          <button
            className={cn("button")}
            style={{ width: "100%" }}
            // onClick={handleSubmit}
            disabled={
              //   // loginDetails.email === '' ||
              //   // loginDetails.password === '' ||
              isLoading
            }
          >
            {isLoading ? (
              <ScaleLoader color="#fff" speedMultiplier={2} width={4} />
            ) : (
              "Update"
            )}
            {/* Add */}
          </button>
        </form>
      </Card>
    </>
  );
}

export default AddBussinessSector;
