import React, { useEffect, useState } from "react";

import Loader from "../../../../components/Loader";
import Overview from "./Overview";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Transactions from "./Transactions";
import { makeReportCall } from "../../../../lib/reportsApi";
import styles from "./Statements.module.sass";
import toaster from "../../../../utils/toaster";

const Statements = () => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getEncodedType = async () => {
    // e.preventDefault()
    setIsLoading(true);
    try {
      const response = await makeReportCall(`/payment-type/encodeType`);
      if (response.status === 200) {
        const data = response?.data;
        setTransactions(data);
      } else {
        toaster("Something Went Wrong", "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toaster("An error occurred while fetching data", "error");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getEncodedType();
  }, []);
  return (
    <>
      <div className={styles.section}>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Loader className={styles.loader} />
            {/* <Loader /> */}
          </div>
        ) : (
          <Transactions transactions={transactions} />
        )}
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Statements;
