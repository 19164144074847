import React, { useEffect, useState } from 'react'
import styles from './Table.module.sass'
import cn from 'classnames'
import { numberWithCommas } from '../../../../../utils.js'
import NotFound from '../../../../NotFound/index'
import Card from '../../../../../components/Card'
import toaster from '../../../../../utils/toaster'
import { makeApiCall } from '../../../../../lib/api'
import Loader from '../../../../../components/Loader'
import Items from './items'
import { useNavigate } from 'react-router'

const items = [
  {
    date: 'Fri, Apr 9',
    status: false,
    sales: 28,
    earnings: 3140,
  },
  {
    date: 'Sat, Apr 10',
    status: true,
    sales: 24,
    earnings: 2568,
  },
  {
    date: 'Sun, Apr 11',
    status: false,
    sales: 16,
    earnings: 1375.88,
  },
  {
    date: 'Mon, Apr 12',
    status: true,
    sales: 48,
    earnings: 4955.86,
  },
  {
    date: 'Tue, Apr 13',
    status: true,
    sales: 32,
    earnings: 2233.44,
  },
  {
    date: 'Wed, Apr 14',
    status: false,
    sales: 64,
    earnings: 6140,
  },
  {
    date: 'Thu, Apr 15',
    status: true,
    sales: 8,
    earnings: 789.32,
  },
]

const Table = () => {
  const [fetchLimit, setFetchLimit] = useState(5)

  const [totalLength, setTotalLength] = useState(0)
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [topMDAs, setTopMDAs] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [nodat, setNodata] = useState(false)

  const inc = 10

  const getTopMDAs = async () => {
    // e.preventDefault()
    setIsLoading(true)

    const response = await makeApiCall(`/payment/topMDAs?size=${fetchLimit}`)
    if (response.status === 200) {
      let data = await response?.data
      // setTotalLength(response?.meta?.total_items)
      if (data.length === 0) {
        setNodata(true)
      }
      if (topMDAs.length >= response.meta.total_items) {
        setHasMore(false)
        setIsLoading(false)
        return
      }

      setTopMDAs(data)
      setIsLoading(false)
    } else {
      toaster('Something Went Wrong', 'error')
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getTopMDAs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchLimit])
  // console.log(topMDAs, 'topMDAs')
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        if (hasMore === false) return
        // Check if there is more data to fetch
        // Increment page and fetchLimit
        // setPage((prevPage) => prevPage + 1)
        // console.log('ddd', hasMore)
        setFetchLimit((prevFetched) => prevFetched + inc)
      }
    }
    // Attach the event listener to the scroll event
    window.addEventListener('scroll', handleScroll)
    return () => {
      // Clean up the event listener on unmount
      window.removeEventListener('scroll', handleScroll)
    }
  }, [hasMore])
  const navigate = useNavigate()
  // const viewInfo = (id) => {
  //   navigate(`/payment/overview/details/${id}`)
  // }
  return (
    <Card title='MDAs IGR Performance Report' classTitle='title-blue'>
      {/* <div className={styles.wrapper}> */}
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>MDA</div>
          <div className={styles.col}>Amount Generated</div>
          <div className={styles.col}>No Of Payments </div>
          <div className={styles.col}>Action</div>
        </div>
        {topMDAs.map((x, index) => (
          <div className={styles.row} key={index}>
            <Items x={x} />
          </div>
        ))}
      </div>
      {nodat && <NotFound />}
      {/* </div> */}
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader className={styles.loader} />
        </div>
      ) : (
        ''
      )}
    </Card>
  )
}

export default Table
