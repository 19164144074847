import "./styles/app.sass";

import { Route, Routes } from "react-router-dom";
import {
  onMessageListener,
  requestFirebaseNotificationPermission,
  requestPermission,
} from "./firebase";
import { useEffect, useState } from "react";

// import { requestFirebaseNotificationPermission } from "./firebaseInit";
import AffiliateCenter from "./screens/AffiliateCenter";
import Appkeys from "./screens/AppKeys/Appkeys";
import Business from "./screens/Business/Index";
import BusinessSector from "./screens/BusinessSector";
import Citizens from "./screens/Citizens";
import Comments from "./screens/Comments";
import CustomerList from "./screens/CustomerList";
import Customers from "./screens/Customers";
import Details from "./screens/Payment/Overview/Details";
import Drafts from "./screens/Drafts";
import Earning from "./screens/Earning";
import ExploreCreators from "./screens/ExploreCreators";
import FireNotification from "./components/Notification";
import FormalReport from "./screens/Report/FormalReport";
import ForestryOverview from "./screens/Forestry/FormalReport";
import ForestryReports from "./screens/Forestry/FormalReport/Earning/SingleRevenue";
import GatewayReports from "./screens/Payment/GatewayReport";
import Home from "./screens/Home";
import Houses from "./screens/Houses";
import IndividualGatewayReports from "./screens/Payment/GatewayReport/Details";
import InformalReport from "./screens/Report/InformalReport";
import MDAS from "./screens/Mda";
import MessageCenter from "./screens/MessageCenter";
import NewProduct from "./screens/NewProduct";
import Notification from "./screens/Notification";
import Overview from "./screens/Payment/Overview";
import Page from "./components/Page";
// import PageList from "./screens/PageList";
import PaymentGateway from "./screens/PaymentGateway";
import PaymentRecords from "./screens/Payment/Payment-records";
import Payouts from "./screens/Payouts";
import ProductsDashboard from "./screens/ProductsDashboard";
// import Promote from "./screens/Promote";
import ProtectedRoute from "./utils/ProtectedRoute";
import Refunds from "./screens/Refunds";
import Released from "./screens/Released";
import Revenue from "./screens/Revenue";
import RevenueHeadDetails from "./screens/Payment/Overview/Revenue-head";
import RevenueHeads from "./screens/RevenueHeads";
import Roles from "./screens/Roles";
import Scheduled from "./screens/Scheduled";
import School from "./screens/Schools";
// import Settings from "./screens/Settings";
import Shop from "./screens/Shop";
import SignIn from "./screens/SignIn";
import SignUp from "./screens/SignUp";
import Statements from "./screens/Statements";
import TaxOffices from "./screens/TaxOffices";
import Transactions from "./screens/Payment/Transactions";
import UpgradeToPro from "./screens/UpgradeToPro";
import UserManagement from "./screens/UserManagement";
import UserProfile from "./screens/UserProfile";
import AppServices from "./screens/Report/App-services";
import TriggerPoints from "./screens/TriggerPoints";
import SchoolProfiles from "./screens/SchoolsProfile";
import { getOS } from "./utils/userAgent";

function App() {
  // requestPermission();
  const os = getOS();
  // console.log(os, "This is OS");
  if (os !== "ios") {
    // return;
    requestPermission();
    console.log("This is working");
  }
  onMessageListener()
    .then((payload) => {
      // setShow(true);
      // setNotification({
      //   title: payload.notification.title,
      //   body: payload.notification.body,
      // });
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <>
      <FireNotification />
      <Routes>
        <Route path="/">
          <Route
            index
            element={
              <SignUp />
              // <Page title='Dashboard'>
              // </Page>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Page title="Dashboard">
                  <Home />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="products/dashboard"
            element={
              <ProtectedRoute>
                <Page title="">
                  <Drafts />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="products/add"
            element={
              <ProtectedRoute>
                <Page title="New App">
                  <NewProduct />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="products/drafts"
            element={
              <ProtectedRoute>
                <Page title="Drafts">
                  <ProductsDashboard />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="products/released"
            element={
              <ProtectedRoute>
                <Page title="Released">
                  <Released />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="products/comments"
            element={
              <ProtectedRoute>
                <Page title="Comments">
                  <Comments />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="products/scheduled"
            element={
              <ProtectedRoute>
                <Page title="Scheduled">
                  <Scheduled />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="customers/overview"
            element={
              <ProtectedRoute>
                <Page title="Customers">
                  <Customers />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="user/management"
            element={
              <ProtectedRoute>
                <Page title="User Management">
                  <UserManagement />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="citizens"
            element={
              <ProtectedRoute>
                <Page title="Citizens">
                  <Citizens />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="mdas"
            element={
              <ProtectedRoute>
                <Page title="MDAS">
                  <MDAS />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="revenue"
            element={
              <ProtectedRoute>
                <Page title="Revenue Heads">
                  <Revenue />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="all-revenue-head"
            element={
              <ProtectedRoute>
                <Page title="All Revenue Heads">
                  <RevenueHeads />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="taxoffices"
            element={
              <ProtectedRoute>
                <Page title="Tax Offices">
                  <TaxOffices />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="business-sector"
            element={
              <ProtectedRoute>
                <Page title="Business Sector">
                  <BusinessSector />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="schools"
            element={
              <ProtectedRoute>
                <Page title="Schools">
                  <School />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="business"
            element={
              <ProtectedRoute>
                <Page title="Business">
                  <Business />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="houses"
            element={
              <ProtectedRoute>
                <Page title="Houses">
                  <Houses />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="customers/customer-list"
            element={
              <ProtectedRoute>
                <Page title="Customer list">
                  <CustomerList />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="shop"
            element={
              <ProtectedRoute>
                <Page wide>
                  <Shop />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="user-profile"
            element={
              <ProtectedRoute>
                <Page wide>
                  <UserProfile />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="school-profile"
            element={
              <ProtectedRoute>
                <Page wide>
                  <SchoolProfiles />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="app-info"
            element={
              <ProtectedRoute>
                <Page wide>
                  <Appkeys />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="income/earning"
            element={
              <ProtectedRoute>
                <Page title="Earning">
                  <Earning />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="payment-gateway"
            element={
              <ProtectedRoute>
                <Page title="Payment Gateway">
                  <PaymentGateway />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="income/refunds"
            element={
              <ProtectedRoute>
                <Page title="Refunds">
                  <Refunds />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="income/payouts"
            element={
              <ProtectedRoute>
                <Page title="Payouts">
                  <Payouts />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="income/statements"
            element={
              <ProtectedRoute>
                <Page title="Statements">
                  <Statements />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="payment/transactions"
            element={
              <ProtectedRoute>
                <Page title="Transactions">
                  <Transactions />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="payment/gateway-report"
            element={
              <ProtectedRoute>
                <Page title="Gateway Reports">
                  <GatewayReports />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="payment/gateway-report/details"
            element={
              <ProtectedRoute>
                <Page title="">
                  <IndividualGatewayReports />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="payment/overview"
            element={
              <ProtectedRoute>
                <Page title="Overview">
                  <Overview />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="payment/webbook-reports"
            element={
              <ProtectedRoute>
                <Page title="Webbook Reports">
                  <PaymentRecords />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="payment/overview/details"
            element={
              <ProtectedRoute>
                <Page title="">
                  <Details />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="payment/overview/revenue-head"
            element={
              <ProtectedRoute>
                <Page title="">
                  <RevenueHeadDetails />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="roles"
            element={
              <ProtectedRoute>
                <Page title="Roles">
                  <Roles />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="/report/formal-report"
            element={
              <ProtectedRoute>
                <Page title="Formal Report">
                  <FormalReport />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="/report/informal-report"
            element={
              <ProtectedRoute>
                <Page title="Informal Report">
                  <InformalReport />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="/report/App-services"
            element={
              <ProtectedRoute>
                <Page title="Services">
                  <AppServices />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="/forestry/overview"
            element={
              <ProtectedRoute>
                <Page title="Forestry Overview">
                  <ForestryOverview />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="/forestry/reports"
            element={
              <ProtectedRoute>
                <Page title="Forestry Reports">
                  <ForestryReports />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="notification"
            element={
              <ProtectedRoute>
                <Page title="Notification">
                  <Notification />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="settings"
            element={
              <ProtectedRoute>
                <Page title="">
                  <TriggerPoints />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="upgrade-to-pro"
            element={
              <ProtectedRoute>
                <Page title="Upgrade to Pro">
                  <UpgradeToPro />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="message-center"
            element={
              <ProtectedRoute>
                <Page title="Message center">
                  <MessageCenter />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="explore-creators"
            element={
              <ProtectedRoute>
                <Page title="Explore creators">
                  <ExploreCreators />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route
            path="affiliate-center"
            element={
              <ProtectedRoute>
                <Page title="Affiliate center">
                  <AffiliateCenter />
                </Page>
              </ProtectedRoute>
            }
          />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="sign-in" element={<SignIn />} />
          {/* <Route path='pagelist' element={<PageList />} /> */}
        </Route>
      </Routes>
    </>
  );
}

export default App;
