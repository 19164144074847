import React from 'react'
import RolesDash from '../RolesDash'

function Roles() {
  return (
    <div>
      <RolesDash />
    </div>
  )
}

export default Roles
