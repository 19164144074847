import React, { useState } from 'react'
import Card from '../../../components/Card'
import cn from 'classnames'
import { makeApiCall } from '../../../lib/api'
import toaster from '../../../utils/toaster'

function ConfirmRegenerate({ appkeys, setKeys, setVisibleModal }) {
  const [isLoading, setIsLoading] = useState(false)
  const handleSubmit = async () => {
    setIsLoading(true)
    const response = await makeApiCall(
      `/apps/${appkeys?.appId}/regenerate`,
      'POST'
    )
    if (response.status === 200) {
      toaster(`${response.message}`, 'success')
      setVisibleModal(false)
      localStorage.setItem('appKeys', JSON.stringify(response?.data))
      setKeys(response?.data)
      setIsLoading(false)
    } else {
      toaster(`An Error Occured`, 'error')
      setIsLoading(false)
    }
  }
  return (
    <>
      <Card title='Are you Sure You' classTitle='title-blue'>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '4rem',
          }}
        >
          <button
            className={cn('button')}
            style={{ width: '100%' }}
            onClick={() => setVisibleModal(false)}
            // onClick={() => createPaymentGateway()}
            // disabled={
            //   loginDetails.email === '' ||
            //   loginDetails.password === '' ||
            //   loading === true
            // }
          >
            {/* {isLoading ? 'Please Wait ......' : 'Add'} */}
            No
          </button>
          <button
            className={cn('button')}
            style={{ width: '100%', background: 'red' }}
            onClick={() => handleSubmit()}
            disabled={isLoading}
            // disabled={
            //   loginDetails.email === '' ||
            //   loginDetails.password === '' ||
            //   loading === true
            // }
          >
            {isLoading ? 'Wait ......' : 'Yes'}
          </button>
        </div>
      </Card>
    </>
  )
}

export default ConfirmRegenerate
