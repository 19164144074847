import React from 'react'
import CustomerList from '../CustomerList'

function Business() {
  return (
    <div>
      <CustomerList />
    </div>
  )
}

export default Business
