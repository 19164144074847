import Formatter from "../../../../../components/Formatter/Formatter";
import React from "react";
import cn from "classnames";
import styles from "./Table.module.sass";
import { useNavigate } from "react-router";

function Items({ x }) {
  //   console.log(x, 'This is Id')
  const navigate = useNavigate();

  return (
    <>
      {/* {console.log(x, 'Stats')} */}
      <div className={styles.col}>
        {x.revenue_head}
        <br />
        <p style={{ fontSize: "12px" }}>{x.revenue_code}</p>
      </div>
      <div className={styles.col}>
        {/* ${numberWithCommas(x.earnings.toFixed(2))} */}
        ₦ <Formatter amount={x.total_revenue} />
        {/* {x.total_revenue} */}
      </div>
      <div className={styles.col}>{x.payment_count}</div>
      <div className={styles.col}>
        <button
          className={cn("button-stroke button-small", styles.button)}
          onClick={() => (
            navigate(`/payment/overview/revenue-head?id=${x?.revenue_code}`),
            localStorage.setItem("revName", JSON.stringify(x.revenue_head))
          )}
        >
          Expand
          {/* <Loader className={styles.loader} /> */}
          {/* <span>Load more</span> */}
        </button>
      </div>
    </>
  );
}

export default Items;
