import React, { useEffect, useState } from "react";
import Card from "../Card";
import cn from "classnames";
import toaster from "../../utils/toaster";
import { makeApiCall } from "../../lib/api";
// import Filters from "../../screens/RolesDash/Filters";
import styles from "../../screens/ProductsDashboard/ProductsDashboard.module.sass";
import Checkbox from "./Checkbox";
import Loader from "../Loader";

function SingleAppService({ appID, appkeys }) {
  // console.log(appkeys, "Keys");
  const [appServices, setAppServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addingService, setAddingService] = useState(false);
  const [removingService, setRemovingService] = useState(false);
  const [serviceTracker, setServiceTracker] = useState({
    servicesToDelete: [],
    servicesToAdd: [],
  });

  // Getting all the Services
  const getServices = async () => {
    try {
      // e.preventDefault()
      setIsLoading(true);
      const response = await makeApiCall(`/app-roles?searchParam=${appID}`);

      if (response.status === 200) {
        setAppServices(response?.data);
        //   setSelectedFilters(response?.data);
      } else {
        throw new Error("Something Went Wrong");
      }
    } catch (error) {
      toaster(error.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getServices();
    console.log(appkeys);
  }, []);

  const handleCheckboxChange = (appService) => {
    if (appService?.appName === appkeys?.appName) {
      setServiceTracker((prevServiceTracker) => {
        const updatedServicesToDelete = [
          ...prevServiceTracker?.servicesToDelete,
        ];

        // Check if the service already exists in the array
        const index = updatedServicesToDelete?.indexOf(appService?.name);

        if (index !== -1) {
          // If it exists, remove it
          updatedServicesToDelete?.splice(index, 1);
        } else {
          // If it doesn't exist, add it
          updatedServicesToDelete?.push(appService?.name);
        }

        return {
          ...prevServiceTracker,
          servicesToDelete: updatedServicesToDelete,
        };
      });
    } else {
      setServiceTracker((prevServiceTracker) => {
        const updatedServicesToAdd = [...prevServiceTracker?.servicesToAdd];

        // Check if the service already exists in the array
        const index = updatedServicesToAdd?.indexOf(appService?.name);

        if (index !== -1) {
          // If it exists, remove it
          updatedServicesToAdd?.splice(index, 1);
        } else {
          // If it doesn't exist, add it
          updatedServicesToAdd?.push(appService?.name);
        }

        return {
          ...prevServiceTracker,
          servicesToAdd: updatedServicesToAdd,
        };
      });
    }

    setAppServices((prev) =>
      prev.map((service) =>
        service?.serviceId === appService?.serviceId
          ? {
              ...service,
              appName:
                appkeys?.appName === service.appName ? "" : appkeys?.appName,
            }
          : service
      )
    );
  };
  // console.log(serviceTracker, "ServiceTracker");

  // Adding Services
  const handleAssignService = async (e) => {
    try {
      e.preventDefault();
      setAddingService(true);
      const response = await makeApiCall(
        `app-roles/app`,
        "POST",
        {
          appId: appkeys?.id,
          services: serviceTracker.servicesToAdd,
        }
        // 'multipart/form-data'
      );

      if (response) {
        toaster(response.message, "success");
        // getProducts();
        console.log(response);
        setAddingService(false);
        // setServiceTracker({
        //   servicesToAdd: [],
        // });
        //setWebHookUrl("");
      } else {
        throw new Error("Something Went Wrong");
      }
    } catch (error) {
      toaster(error.message, "error");
    } finally {
      setAddingService(false);
      // setSaving(false);
    }
  };

  // Removing Services
  const handleUnAssignService = async (e) => {
    try {
      e.preventDefault();
      setRemovingService(true);
      const response = await makeApiCall(
        `app-roles/app`,
        "DELETE",
        {
          appId: appkeys?.id,
          services: serviceTracker.servicesToDelete,
        }
        // 'multipart/form-data'
      );

      if (response) {
        toaster(response.message, "success");
        // getProducts();
        console.log(response);
        //setWebHookUrl("");
        setRemovingService(false);
        // setServiceTracker({
        //   servicesToDelete: [],
        // });
      } else {
        throw new Error("Something Went Wrong");
      }
    } catch (error) {
      toaster(error.message, "error");
    } finally {
      // setSaving(false);
      setRemovingService(false);
    }
  };

  return (
    <div>
      {" "}
      <Card title="App Services" classTitle={cn("title-green")}>
        {isLoading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // border: '2px solid red',
              height: "100vh",
            }}
          >
            <Loader className={styles.loader} />
          </div>
        ) : (
          <>
            <div className="services-keys">
              {appServices?.map((appService, index) => {
                return (
                  <Checkbox
                    className={styles.checkbox}
                    content={appService}
                    value={appService.appName === appkeys.appName}
                    onChange={() => handleCheckboxChange(appService)}
                    key={index}
                  />
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <button
                className={cn("button-small", styles.button)}
                style={{
                  width: "20rem ",
                  textAlign: "center",
                  marginTop: "1rem",
                }}
                onClick={handleAssignService}
              >
                {addingService ? "Adding..." : "Assign Service"}
              </button>
              <button
                className={cn("button-small", styles.button)}
                style={{
                  width: "20rem ",
                  textAlign: "center",
                  marginTop: "1rem",
                  background: "red",
                }}
                // disabled={serviceTracker?.servicesToDelete?.length === 0}
                onClick={handleUnAssignService}
              >
                {removingService ? "Removing..." : "Unassign Service"}
              </button>
            </div>
          </>
        )}
      </Card>
    </div>
  );
}

export default SingleAppService;
