import styles from "./Row.module.sass";
import cn from "classnames";

import { useLocation } from "react-router-dom";
import Checkbox from "../../../../../../../components/Checkbox";

const Row = ({
  item,
  value,
  onChange,
  activeTable,
  setActiveTable,
  activeId,
  setActiveId,
}) => {
  const handleClick = (id) => {
    setActiveTable(true);
    setActiveId(id);
  };
  const { pathname } = useLocation();

  console.log(item, "Here Items");

  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }
        )}
      >
        <div className={styles.col}>
          <Checkbox
            className={styles.checkbox}
            value={value}
            onChange={onChange}
          />
        </div>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.avatar}>
              <img
                src={
                  item?.image === null
                    ? "	https://cross-river-pay.s3.us-east-2.amazonaws.com/cas-admin/images/content/user.jpg"
                    : item?.image
                }
                alt="Avatar"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.user}>{item?.name}</div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.email}>{item?.class}</div>
        </div>
        {/* <div className={styles.col}>
          <div className={cn('status-green-dark', styles.purchase)}>
            {item.latitude}
          </div>
        </div> */}
        <div className={styles.col}>
          <div className={styles.lifetime}>
            <div className={styles.price} style={{ fontStyle: "Capitalize" }}>
              {item?.gender}
              {/* {dateFormat(item.createDate, 'dddd, mmmm dS, yyyy')} */}
            </div>
            {/* <Balance className={styles.balance} value={item.balance} /> */}
          </div>
        </div>
        {/* <div className={styles.col}>{item.comments}</div>
        <div className={styles.col}>{item.likes}</div> */}
      </div>
    </>
  );
};

export default Row;
