import React, { useEffect, useState } from "react";

import AllRevenueForMDA from "./AllRevenueforMda";
// import NewCustomer from "../../../Customers/NewCustomer";
import NewCustomer from "./NewCustomer";
import Overview from "./Overview";
// import TooltipGlodal from "../../../../components/TooltipGlodal";
import Overview2 from "./Overview2";
import ProductSales from "./ProductSales";
import RevenueDash from "./SingleRevenue";
import Table from "./Table";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import TopCountries from "./TopCountries";
// import styles from "./Earning.module.sass";
import styles from "./Earning.module.sass";
// import ActiveCustomers from "./ActiveCustomers";
// import { makeReportCall } from "../../../../lib/reportsApi";
import toaster from "../../../../utils/toaster";

const Earning = () => {
  // const [reportData, setReportData] = useState();

  // const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <div className={styles.section}>
        <Overview className={styles.card} />
        {/* <ActiveCustomers isLoading={isLoading} /> */}
        {/* <AllRevenueForMDA /> */}

        <div className={styles.row}>
          <div className={styles.col}>
            {/* <ActiveCustomers isLoading={isLoading} /> */}
            {/* <ProductSales /> */}
          </div>

          <div className={styles.col}>{/* <TopCountries /> */}</div>
        </div>
        {/* <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Overview />
        </div> */}
        {/* <div>
          <NewCustomer />
        </div>
        <div>
          <RevenueDash />
        </div> */}
        {/* <div className={styles.row}>
          <div className={styles.col}>
          <div
            className={styles.col}
            style={{ height: "600px", overflow: "scroll" }}
          >
            <RevenueDash />
          </div>
          <RevenueDash />
          <ProductSales />
          </div>

          <div className={styles.col}>
            <TopCountries />
            <NewCustomer />
          </div>
        </div> */}
        {/* <Table /> */}
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Earning;
