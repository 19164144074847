import React, { useEffect, useState } from "react";

import Card from "../../../../../components/Card";
import CurrencyFormatter from "../../../../../utils/CurrencyFormatter";
import CustomTooltip from "../../../../../components/CustomTooltip";
import Icon from "../../../../../components/Icon";
import Tooltip from "../../../../../components/Tooltip";
import TooltipGlodal from "../../../../../components/TooltipGlodal";
import cn from "classnames";
import { makeApiCall } from "../../../../../lib/api";
import { makeReportCall } from "../../../../../lib/reportsApi";
import { refetchPayment } from "../../../../../firebase";
import styles from "./Overview.module.sass";
import toaster from "../../../../../utils/toaster";
import Debounce from "../../../../../utils/debounce";

const Overview = ({ className }) => {
  const [reportData, setReportDate] = useState();

  const [isLoading, setIsLoading] = useState("*****");

  const getAllTransactionsDataintheDatabase = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeReportCall(
      // `/formal-sector/business-statistics?year=${2023}`
      `formal-sector/formal-statistics?year=${2023}`

      // `/payment/mdaDaily/4190005/?year=2023&month=7`
      //`/formal-sector/business-statistics?year=${2017}`
    );

    // console.log(response)
    if (response.status === 200) {
      console.log(response);
      setReportDate(response.data);
      // console.log(response);

      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllTransactionsDataintheDatabase();
  }, []);
  // refetchPayment(getAllTransactionsDataintheDatabase);
  Debounce(refetchPayment(getAllTransactionsDataintheDatabase), 2500);

  const date = new Date().getFullYear();
  const items = [
    {
      title: "Today's Payment",
      // counter: "$128,789",
      counter: isLoading ? (
        "****"
      ) : (
        <CurrencyFormatter
          amount={reportData?.today_payment}
          currencySymbol="₦"
        />
      ),

      icon: "pie-chart",
      color: "#CABDFF",
      tooltip: (
        <CustomTooltip
          content={reportData?.today_payment}
          className={styles.tooltip}
          symbol="NGN"
        >
          <Icon name="info" fill="#6F767E" />
        </CustomTooltip>
      ),
    },
    {
      title: `Total Year Payment  (${date})`,
      // counter: "$128,789",
      counter: isLoading ? (
        "****"
      ) : (
        <CurrencyFormatter
          amount={reportData?.total_year_payment}
          currencySymbol="₦"
        />
      ),

      icon: "activity",
      color: "#B5E4CA",
      tooltip: (
        <CustomTooltip
          content={reportData?.total_year_payment}
          className={styles.tooltip}
          symbol="NGN"
        >
          <Icon name="info" fill="#6F767E" />
        </CustomTooltip>
      ),
    },
    {
      title: `Number of Payment  (${date})`,
      //counter: "$338.98",
      counter: isLoading
        ? "****"
        : reportData?.number_of_payment.toLocaleString("en-US"),
      icon: "donut-chart",
      color: "#B1E5FC",
      tooltip: (
        <CustomTooltip
          content={reportData?.number_of_payment}
          className={styles.tooltip}
          // symbol="NGN"
        >
          <Icon name="info" fill="#6F767E" />
        </CustomTooltip>
      ),
    },
  ];
  return (
    <>
      <Card className={cn(styles.card, className)}>
        <div className={styles.overview}>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: x.color }}
                >
                  <Icon name={x.icon} size="24" />
                </div>
                <div className={styles.details}>
                  <div className={styles.label}>
                    {x.title}
                    {/* <Tooltip
                      className={styles.tooltip}
                      title={x.tooltip}
                      icon="info"
                      place="top"
                    /> */}
                    {x.tooltip}
                  </div>
                  <div className={styles.counter}> {x.counter}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Overview;
