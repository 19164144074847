import React, { useState } from 'react'
import styles from './ProductsDashboard.module.sass'
import TooltipGlodal from '../../components/TooltipGlodal'
// import Overview from './Overview'
import ProductActivity from './ProductActivity'
// import ProductViews from './ProductViews'
// import Products from './Products'
import { NavLink, useLocation } from 'react-router-dom'
import Card from '../../components/Card'
import TextInput from '../../components/TextInput'
// import AddingMdas from './AddingMda'
// import AddingRevenue from './AddingRevenue'
import RevenueHeads from '../RevenueHeads'
import PaymentDetails from './PaymentDetails'

const PaymentGateway = () => {
  const pathname = useLocation()
  // console.log(pathname.pathname)
  const [gatewaydetails, setShowGatewayDetails] = useState(false)
  return (
    <>
      <div className={styles.section}>
        {gatewaydetails ? (
          <PaymentDetails setShowGatewayDetails={setShowGatewayDetails} />
        ) : (
          <ProductActivity setShowGatewayDetails={setShowGatewayDetails} />
        )}
      </div>
      <TooltipGlodal />
    </>
  )
}

export default PaymentGateway
