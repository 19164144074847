import React, { useState } from "react";
import dateFormat, { masks } from "dateformat";
import { useLocation, useNavigate } from "react-router-dom";

import Actions from "../../Actions";
import Checkbox from "../../Checkbox";
import Control from "./Control";
import Icon from "../../Icon";
import Modal from "../../Modal";
import ModalProduct from "../../ModalProduct";
import Schedule from "../../Schedule";
import styles from "./Row.module.sass";

const Row = ({ item, value, onChange }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const [visibleModalSchedule, setVisibleModalSchedule] = useState(false);
  const navigate = useNavigate();

  const actions = [
    {
      title: "Schedule product",
      icon: "calendar",
      action: () => setVisibleModalSchedule(true),
    },
    {
      title: "Edit title & description",
      icon: "edit",
      action: () => console.log("Edit title & description"),
    },
    {
      title: "Delete forever",
      icon: "trash",
      action: () => console.log("Delete forever"),
    },
  ];
  const gotoProfile = () => {
    // localStorage.setItem('user-profile', JSON.stringify(item))
    navigate(`/app-info?id=${item?.appKeys?.appId}`);
  };
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Checkbox
            className={styles.checkbox}
            value={value}
            onChange={onChange}
          />
        </div>
        <div className={styles.col}>
          <div className={styles.item} onClick={gotoProfile}>
            <div className={styles.preview}>
              <img
                // srcSet={`${item.image2x} 2x`}
                src={
                  item.appLogo === null
                    ? "/images/content/default.png"
                    : item.appLogo
                }
                alt="Product"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.product}>{item.appName}</div>
              <div className={styles.link}>{item.email}</div>
              <div className={styles.date}>
                <Icon name="clock" size="20" /> {item.createdAt}
              </div>
              {/* {item.length > 0 ? ( */}
              <div className={styles.price}>${item.createdAt}</div>
              {/* ) : ( */}
              {/* <div className={styles.empty}>${item.createdAt}</div> */}
              {/* )} */}
            </div>
          </div>
          <Actions
            className={styles.actions}
            classActionsHead={styles.actionsHead}
            items={actions}
          />
        </div>
        {/* <div className={styles.col}>
          {item.price > 0 ? (
            <div className={styles.price}>${item.price}</div>
          ) : (
            <div className={styles.empty}>${item.price}</div>
          )}
        </div> */}
        <div className={styles.col}>
          {dateFormat(item.createdAt, "dddd, mmmm dS, yyyy, h:MM:ss TT")}

          {/* <Control
            className={styles.control}
            startDate={startDate}
            setStartDate={setStartDate}
            startTime={startTime}
            setStartTime={setStartTime}
            items={item}
          /> */}
        </div>
      </div>
      {/* <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
      <Modal
        visible={visibleModalSchedule}
        onClose={() => setVisibleModalSchedule(false)}
      >
        <Schedule
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          setStartTime={setStartTime}
        />
      </Modal> */}
    </>
  );
};

export default Row;
