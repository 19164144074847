import React, { useEffect, useState } from 'react'
import styles from './ProductActivity.module.sass'
import cn from 'classnames'
import Card from '../../../components/Card'
import Dropdown from '../../../components/Dropdown'
import Item from './Item'
import { useLocation, useNavigate } from 'react-router'
import Form from '../../../components/Form'
import { Link } from 'react-router-dom'
import Icon from '../../../components/Icon'
import Modal from '../../../components/Modal'
import Success from '../../Payouts/Overview/Success'
import toaster from '../../../utils/toaster'
import { makeApiCall } from '../../../lib/api'
import Loader from '../../../components/Loader'
import TextInput from '../../../components/TextInput'

import InfiniteScroll from 'react-infinite-scroll-component'
const inc = 10

const items = [
  {
    title: '25 Sep - 1 Oct',
    products: {
      counter: 8,
      color: '#B5E4CA',
      value: 37.8,
    },
    views: {
      counter: '24k',
      color: '#CABDFF',
      value: 37.8,
    },
    likes: {
      counter: 48,
      color: '#B1E5FC',
      value: -37.8,
    },
    comments: {
      counter: 16,
      color: '#FFD88D',
      value: -56,
    },
  },
  {
    title: '18 Sep - 24 Oct',
    products: {
      counter: 8,
      color: '#EFEFEF',
      value: 37.8,
    },
    views: {
      counter: '24k',
      color: '#EFEFEF',
      value: -37.8,
    },
    likes: {
      counter: 48,
      color: '#EFEFEF',
      value: 12.8,
    },
    comments: {
      counter: 16,
      color: '#EFEFEF',
      value: -14.1,
    },
  },
]

const ProductActivity = ({ showRevenueHeads, setShowRevenueHeads }) => {
  const intervals = ['Last 2 weeks', 'Last 7 days']
  const pathname = useLocation()
  const navigate = useNavigate()
  // console.log(pathname.pathname)
  const [activeTab, setActiveTab] = useState(intervals[0])
  const [visibleModal, setVisibleModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [revenueHead, setRevenueHead] = useState([])
  const [updatedValues, setUpdatedValues] = useState([])
  const [fetchLimit, setFetchLimit] = useState(10)

  const [totalLength, setTotalLength] = useState(0)
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [showUpdateModal, setShowUpdateModal] = useState(false)

  const getRevenueHeads = async () => {
    setIsLoading(true)
    try {
      const response = await makeApiCall(
        `/region/sector?size=${fetchLimit}&page=${page}`
      )

      if (response.status === 200) {
        const data = response?.data
        // console.log(data)
        setRevenueHead(data)

        if (revenueHead.length >= response.meta.total_items) {
          setHasMore(false)
          setIsLoading(false)
          return
        }
        setIsLoading(false)
        // setUpdatedValues(data.map((item) => item.revenue_amount))
        // setTotalLength(response?.meta?.total_items)
        // if (response?.meta?.total_items === data.length) {
        //   setHasMore(false)
        // }
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error fetching revenue heads:', error)
      toaster('Something Went Wrong', 'error')
    }
  }
  useEffect(() => {
    getRevenueHeads()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchLimit])
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        if (hasMore === false) return
        // Check if there is more data to fetch
        // Increment page and fetchLimit
        // setPage((prevPage) => prevPage + 1)
        // console.log('ddd', hasMore)
        setFetchLimit((prevFetched) => prevFetched + inc)
      }
    }
    // Attach the event listener to the scroll event
    window.addEventListener('scroll', handleScroll)
    return () => {
      // Clean up the event listener on unmount
      window.removeEventListener('scroll', handleScroll)
    }
  }, [hasMore])
  // console.log(showUpdateModal);
  return (
    <>
      <Card
        className={styles.card}
        title='Business Sectors'
        classTitle='title-green'
        head={
          <>
            {/* <Dropdown
            className={cn(styles.dropdown, 'mobile-hide')}
            classDropdownHead={styles.dropdownHead}
            value={activeTab}
            setValue={setActiveTab}
            options={intervals}
            small
          /> */}
            <Form
              className={styles.form}
              // value={search}
              // setValue={setSearch}
              // onSubmit={() => handleSubmit()}
              placeholder='Search Sect'
              type='text'
              name='search'
              icon='search'
            />
            {/* <Link
              className={cn('button-stroke button-small', styles.button)}
              to=''
              style={{ marginLeft: '12px' }}
              // onClick={goBack}
              onClick={() => setShowRevenueHeads(false)}
            >
              <Icon name='arrow-left' size='18' />

              <span>Back</span>
            </Link> */}
            {/* <Link
              className={cn('button-stroke button-small', styles.button)}
              to=''
              style={{ marginLeft: '12px' }}
              onClick={goBack}
              onClick={() => setVisibleModal(true)}
            >
              <Icon name='plus' size='18' />
              {pathname.pathname === '/mdas' ? (
                <span>Add MDAs</span>
              ) : (
                <span>Add Revenue Head</span>
              )}
            </Link> */}
            <Link
              className={cn('button-stroke button-small', styles.button)}
              to=''
              style={{ marginLeft: '12px' }}
              // onClick={goBack}
              onClick={() => setVisibleModal(true)}
            >
              {/* <Icon name='plus' size='18' /> */}

              <span>Add Business Sector</span>
            </Link>
          </>
        }
      >
        {/* <InfiniteScroll
          dataLength={totalLength}
          // next={getRevenueHeads}
          hasMore={hasMore}
          scrollableTarget='scrollableDiv'
          onScroll={handleScroll}
          itemsPerPage={10}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        > */}
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Name</div>
            <div className={styles.col}>Type</div>
            {/* <div className={styles.col}>Parent Id</div> */}
            {/* <div className={styles.col}>Likes</div>
            <div className={styles.col}>Comments</div> */}
            {pathname.pathname === '/mdas' ? (
              ''
            ) : (
              <div className={styles.col}>Actions</div>
            )}
          </div>
          {revenueHead?.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.col}>
                {/* <div className={styles.label}>Week</div> */}
                {x.name}
              </div>
              <div className={styles.col}>
                {/* <div className={styles.label}>Products</div> */}
                {x.type}
                {/* <Item className={styles.item} item={x.revenue_code} /> */}
              </div>
              {/* <div className={styles.col}>


                  {x.parent_id === null ? 'NAN' : x.parent_id}
                </div> */}
              {/* <div className={styles.col}>
                <div className={styles.label}>Likes</div>
                <Item className={styles.item} item={x.likes} />
              </div>
              <div className={styles.col}>
                <div className={styles.label}>Comments</div>
                <Item className={styles.item} item={x.comments} />
              </div> */}
              {pathname.pathname === '/mdas' ? (
                ''
              ) : (
                <div className={styles.col}>
                  <div className={styles.label}>Action</div>
                  <button
                    className={cn('button')}
                    style={{ width: '100%' }}
                    onClick={() => (
                      // eslint-disable-next-line no-sequences
                      setVisibleModal(true),
                      setShowUpdateModal(true),
                      localStorage.setItem('Business', JSON.stringify(x))
                    )}

                    // onClick={() => updateRevenueHead(x.revenue_code)}
                    // onClick={handleSubmit}
                    // disabled={
                    //   loginDetails.email === '' ||
                    //   loginDetails.password === '' ||
                    //   loading === true
                    // }
                  >
                    {/* {loading ? (
            <ScaleLoader color='#fff' speedMultiplier={2} width={4} />
          ) : (
            'Login'
          )} */}
                    Update
                  </button>
                  {/* <Item className={styles.item} item={x.comments} /> */}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className={styles.nav}>
          {intervals.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: x === activeTab,
              })}
              onClick={() => setActiveTab(x)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
        {isLoading ? (
          <div
            // className={styles.foot}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '20px',
            }}
          >
            <button className={cn('button-stroke button-small', styles.button)}>
              <Loader className={styles.loader} />
              {/* <span>Load more</span> */}
            </button>
          </div>
        ) : (
          ''
        )}
        {/* </InfiniteScroll> */}
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => (setVisibleModal(false), setShowUpdateModal(false))}
        setShowUpdateModal={setShowUpdateModal}
      >
        <Success
          setVisibleModal={setVisibleModal}
          showUpdateModal={showUpdateModal}
          getRevenueHeads={getRevenueHeads}
        />
      </Modal>
    </>
  )
}

export default ProductActivity
