import "../SignUpStyle.css";

// import Home from '../../Home'
// import Image from '../../../components/Image'
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";

import Cookies from "js-cookie";
import { ScaleLoader } from "react-spinners";
import TextInput from "../../../components/TextInput";
import cn from "classnames";
import { makeApiCall } from "../../../lib/api";
import styles from "./Entry.module.sass";
import toaster from "../../../utils/toaster";

import Modal from "../../../components/Modal";
import Success from "../../Payouts/Overview/Success";

const Entry = ({ onConfirm }) => {
  const [visibleModal, setVisibleModal] = useState(false);

  const notification_token = Cookies.get("notification_token");
  // console.log(notification_token, " This is token");
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
    device_token: notification_token,
  });
  let [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleUserInputs = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLoginDetails({
      ...loginDetails,
      [name]: value,
    });
  };
  const handleSubmit = async () => {
    // e.preventDefault()
    setLoading(true);
    const response = await makeApiCall("/auth/login", "POST", loginDetails);
    console.log(response);

    if (response?.status === 200) {
      toaster("Login Successful", "success");
      Cookies.set("user_token", response?.data?.token);
      Cookies.set("user_details", JSON.stringify(response));
      setLoading(false);
      navigate("/dashboard");
    }

    if (response.response.status === 401) {
      setLoading(false);
      toaster(`${response?.response?.data?.error}`, "error");
      // setLoading(false)
      // toaster(`${response?.error}`, 'error')
      // console.log(response.response.data.email)
    }
  };
  // console.log(loginDetails)

  const v = React.useRef();

  // React.useEffect(() => {
  //   setVisibleModal(true);
  // }, []);

  return (
    <>
      <div className={` input-wrap`}>
        {/* <div className={styles.info}>Or continue with email address</div> */}
        <TextInput
          className={` input-field`}
          name="email"
          type="email"
          placeholder="Your email"
          required
          icon="mail"
          onChange={handleUserInputs}
        />
        <TextInput
          className={` input-field`}
          name="password"
          type="password"
          placeholder="Enter your Password"
          required
          icon="lock"
          onChange={handleUserInputs}
        />
        <button
          className={cn("button", "btn-bb")}
          onClick={handleSubmit}
          disabled={
            loginDetails.email === "" ||
            loginDetails.password === "" ||
            loading === true
          }
        >
          {loading ? (
            <ScaleLoader color="#fff" speedMultiplier={2} width={4} />
          ) : (
            "Login"
          )}
        </button>
        <Link
          to={"https://nugitech.com"}
          target="_blank"
          // ref={'no-ref'}
          className={styles.note}
        >
          Powered By Nugi Technologies
        </Link>
      </div>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
        // setShowFeatureUpdateModal={setShowFeatureUpdateModal}
      >
        <Success
          // getRevenueHeads={getTripperPoints}
          // showFeatureUpdateModal={showFeatureUpdateModal}
          setVisibleModal={setVisibleModal}
          // filters={features}
        />
      </Modal>
    </>
  );
};

export default Entry;
