import React, { useEffect, useState } from "react";

import Balance from "../../../../../components/Balance";
import Card from "../../../../../components/Card";
import CurrencyFormatter from "../../../../../utils/CurrencyFormatter";
import CustomTooltip from "../../../../../components/CustomTooltip";
import Icon from "../../../../../components/Icon";
import Tooltip from "../../../../../components/Tooltip";
import TooltipGlodal from "../../../../../components/TooltipGlodal";
import cn from "classnames";
import { getCurrentYearAndMonth, handleApiError } from "../../../../../utils";
import { makeApiCall, makeForestryCall } from "../../../../../lib/api";
import { makeReportCall } from "../../../../../lib/reportsApi";
import { refetchPayment } from "../../../../../firebase";
import styles from "./Overview.module.sass";
import toaster from "../../../../../utils/toaster";
import { useLocation, useNavigate } from "react-router-dom";
import Debounce from "../../../../../utils/debounce";

// import { Tooltip } from 'react-tooltip'
const items = [
  {
    title: "Earning",
    counter: "$128k",
    icon: "activity",
    color: "#B5E4CA",
    tooltip: "Small description Earning",
    value: 37.8,
  },
  {
    title: "Balance",
    counter: "$512.64",
    icon: "pie-chart",
    color: "#CABDFF",
    tooltip: "Small description Balance",
    value: -17.8,
  },
  {
    title: "Total value of sales",
    counter: "$64k",
    icon: "shopping-bag",
    color: "#B1E5FC",
    tooltip: "Small description Total",
    value: 24.3,
  },
];

const Overview = ({ className }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  // const id = params.get("id");
  const { currentYear, currentMonth } = getCurrentYearAndMonth();

  // console.log(id, currentMonth, currentYear)
  const [mdaPaymentRecords, setMdaPaymentRecords] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getStatistics = async () => {
    try {
      setIsLoading(true);
      const response = await makeForestryCall(`forestry/forestry-overview`);
      if (response.status === 200) {
        console.log(response);
        setMdaPaymentRecords(response?.data?.forestryStats);
      } else {
        throw new Error("Something Went Wrong, Please try Again");
        return;
      }
    } catch (error) {
      handleApiError(error, navigate);
      // toaster(error.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStatistics();
  }, []);
  refetchPayment(getStatistics);
  Debounce(refetchPayment(getStatistics), 2500);

  return (
    <div style={{ marginBottom: "2rem" }}>
      {/* <div className={styles.counter}>Revenue for the Year {currentYear}</div> */}

      <Card className={cn(styles.card, className)}>
        <div className={styles.overview}>
          <div className={styles.list}>
            <div className="over">
              <div className={styles.item}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: items[0].color }}
                >
                  <Icon name="activity" size="24" />
                </div>
                <div className={styles.details}>
                  <div className={styles.label}>
                    Today Trucks Enumerated
                    <CustomTooltip
                      content={mdaPaymentRecords?.today_trucks_enumerated}
                      className={styles.tooltip}
                    >
                      <Icon name="info" fill="#6F767E" />
                    </CustomTooltip>
                    {/* <Tooltip
                    className={styles.tooltip}
                    title='Hello'
                    icon='info'
                    place='right'
                  /> */}
                  </div>
                  <div className={styles.counter}>
                    {isLoading ? (
                      "*********"
                    ) : (
                      // <CurrencyFormatter
                      //   amount={mdaPaymentRecords?.dailyAmount}
                      //   currencySymbol="₦"
                      // />
                      <>{mdaPaymentRecords?.today_trucks_enumerated}</>
                    )}
                  </div>
                  {/* <div className={styles.indicator}>
                  <Balance className={styles.balance} value={x.value} />
                  <span>this week</span>
                </div> */}
                </div>
              </div>
              <div className={styles.item}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: items[0].color }}
                >
                  <Icon name="activity" size="24" />
                </div>
                <div className={styles.details}>
                  <div className={styles.label}>
                    Today Verified Payments
                    <CustomTooltip
                      content={mdaPaymentRecords?.today_verified_payments}
                      className={styles.tooltip}
                    >
                      <Icon name="info" fill="#6F767E" />
                    </CustomTooltip>
                  </div>
                  <div className={styles.counter}>
                    <div className={styles.counter}>
                      {isLoading ? (
                        "*********"
                      ) : (
                        // <CurrencyFormatter
                        //   amount={mdaPaymentRecords?.monthlyAmount}
                        // />
                        <>{mdaPaymentRecords?.today_verified_payments}</>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.item}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: items[0].color }}
                >
                  <Icon name="pie-chart" size="24" />
                </div>
                <div className={styles.details}>
                  <div className={styles.label}>
                    Total Agents
                    <CustomTooltip
                      content={mdaPaymentRecords?.total_agents}
                      className={styles.tooltip}
                    >
                      <Icon name="info" fill="#6F767E" />
                    </CustomTooltip>
                    {/* <Tooltip
                    className={styles.tooltip}
                    title={x.tooltip}
                    icon='info'
                    place='top'
                  /> */}
                  </div>
                  <div className={styles.counter}>
                    {isLoading ? (
                      "*********"
                    ) : (
                      // <CurrencyFormatter
                      //   amount={mdaPaymentRecords?.totalAmount}
                      //   currencySymbol="₦"
                      // />
                      <>{mdaPaymentRecords?.total_agents}</>
                    )}
                    {/* {mdaPaymentRecords?.revenue_statistics?.sum_total_year_payment} */}
                  </div>
                  {/* <div className={styles.indicator}>
                  <Balance className={styles.balance} value={x.value} />
                  <span>this week</span>
                </div> */}
                </div>
              </div>
              <div style={{ marginTop: "2rem" }}>
                <div className={styles.item}>
                  <div
                    className={styles.icon}
                    style={{ backgroundColor: items[0].color }}
                  >
                    <Icon name="activity" size="24" />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.label}>
                      Total Trucks Enumerated
                      <CustomTooltip
                        content={mdaPaymentRecords?.total_trucks_enumerated}
                        className={styles.tooltip}
                      >
                        <Icon name="info" fill="#6F767E" />
                      </CustomTooltip>
                    </div>
                    <div className={styles.counter}>
                      {/* {mdaPaymentRecords?.numberOfPayments} */}
                      <div className={styles.counter}>
                        {isLoading ? (
                          "*********"
                        ) : (
                          // <CurrencyFormatter
                          //   amount={mdaPaymentRecords?.numberOfPayments}
                          // />
                          <>{mdaPaymentRecords?.total_trucks_enumerated}</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.item} style={{ marginTop: "2rem" }}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: items[0].color }}
                >
                  <Icon name="activity" size="24" />
                </div>
                <div className={styles.details}>
                  <div className={styles.label}>
                    Verified Payments
                    <CustomTooltip
                      content={mdaPaymentRecords?.verified_payments}
                      className={styles.tooltip}
                    >
                      <Icon name="info" fill="#6F767E" />
                    </CustomTooltip>
                  </div>
                  <div className={styles.counter}>
                    <div className={styles.counter}>
                      {isLoading ? (
                        "*********"
                      ) : (
                        // <CurrencyFormatter
                        //   amount={mdaPaymentRecords?.monthlyAmount}
                        // />
                        <>{mdaPaymentRecords?.verified_payments}</>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </div>
  );
};

export default Overview;
