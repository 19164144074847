import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { useEffect, useState } from "react";

import Card from "../../../../../components/Card";
import Dropdown from "../../../../../components/Dropdown";
import Loader from "../../../../../components/Loader";
import cn from "classnames";
import styles from "./ActiveCustomers.module.sass";
import useDarkMode from "use-dark-mode";

// const intervals = ["Last 30 days", "Last 20 days", "Last 10 days"];

const ActiveCustomers = ({ className, reportData, isLoading, intervals1 }) => {
  const darkMode = useDarkMode(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const [sorting, setSorting] = useState(currentYear);

  const refinedData = reportData?.formal_collection?.map((d, i) => ({
    name: d?.month_name,
    formal_collection_payment: d?.payments,
    informat_collection_payment: reportData?.informal_collection[i]?.payments,
  }));

  // To format the Yaxis values
  function formatYAxisTick(value) {
    if (Math.abs(value) >= 1.0e9) {
      return (Math.abs(value) / 1.0e9).toFixed(1) + "B";
    } else if (Math.abs(value) >= 1.0e6) {
      return (Math.abs(value) / 1.0e6).toFixed(1) + "M";
    } else if (Math.abs(value) >= 1.0e3) {
      return (Math.abs(value) / 1.0e3).toFixed(1) + "K";
    } else {
      return value.toString();
    }
  }
  useEffect(() => {
    // Function to update the current year every second (1000ms)
    const updateCurrentYear = () => {
      setCurrentYear(new Date().getFullYear());
    };

    // Set up a timer to update the year every second
    const intervalId = setInterval(updateCurrentYear, 1000);

    // Clean up the timer when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);
  // console.log(refinedData)
  return (
    <div style={{ marginBottom: "2rem" }}>
      <Card
        className={cn(styles.card, className)}
        title={`Monthly Revenue Updates for ${currentYear}`}
        classTitle={cn("title-purple", styles.cardTitle)}
        classCardHead={styles.cardHead}
        head={
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={sorting}
            setValue={setSorting}
            options={intervals1}
            small
          />
        }
      >
        {}
        <div className={styles.chart}>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Loader />
            </div>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={300}
                data={refinedData}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid
                  strokeDasharray="none"
                  stroke={darkMode.value ? "#272B30" : "#EFEFEF"}
                  vertical={false}
                />
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12, fontWeight: "500", fill: "#9A9FA5" }}
                  padding={{ left: 10 }}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12, fontWeight: "500", fill: "#9A9FA5" }}
                  tickFormatter={formatYAxisTick} // Use the custom tick formatter
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "#272B30",
                    borderColor: "rgba(255, 255, 255, 0.12)",
                    borderRadius: 8,
                    boxShadow:
                      "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
                  }}
                  labelStyle={{
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#fff",
                  }}
                  itemStyle={{
                    padding: 0,
                    textTransform: "capitalize",
                    fontSize: 12,
                    fontWeight: "600",
                    color: "#fff",
                  }}
                />
                {/* <Line
              type='monotone'
              dataKey='monthly'
              dot={false}
              strokeWidth={4}
              stroke='#2A85FF'
            /> */}
                <Line
                  type="monotone"
                  dataKey="formal_collection_payment"
                  dot={false}
                  strokeWidth={4}
                  stroke="#B5E4CA"
                />
                <Line
                  type="monotone"
                  dataKey="informat_collection_payment"
                  dot={false}
                  strokeWidth={4}
                  stroke="#B1E5FC"
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ActiveCustomers;
