import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { useEffect, useState } from "react";

import Card from "../../../../../components/Card";
import Dropdown from "../../../../../components/Dropdown";
import Loader from "../../../../../components/Loader";
import NotFound from "../../../../NotFound";
import cn from "classnames";
import { getCurrentYearAndMonth } from "../../../../../utils";
import { makeApiCall } from "../../../../../lib/api";
import { makeReportCall } from "../../../../../lib/reportsApi";
import styles from "./ActiveCustomers.module.sass";
import toaster from "../../../../../utils/toaster";
import useDarkMode from "use-dark-mode";
import { useLocation } from "react-router";

const AllRevenueForMDA = ({ className }) => {
  const { currentYear, currentMonth } = getCurrentYearAndMonth();
  const darkMode = useDarkMode(false);
  const [intervals1, setIntervals1] = useState([]);
  const [sorting, setSorting] = useState(intervals1[0] || 2023);
  const [secondSorting, setSecongSorting] = useState(currentYear);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const revCode = id.replace("/", "");

  // console.log(id, currentMonth, currentYear)
  const [mdaPaymentRecords, setMdaPaymentRecords] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // Year Statistics
  const getYearStatistics = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(`/payment/paymentyears`);
    // /payment/paymentyears
    // console.log(response)
    if (response.status === 200) {
      // console.log(response.data, "year");
      setIntervals1(response?.data);
      // setSecongSorting(response.data[0]);
      // setReportDate(response.data);
      // console.log(response);

      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };
  // Grapgh Statistics
  const getStatistics = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeReportCall(
      `/revenueHead/revenue_head_graph/${revCode}?year1=${sorting}&year2=${secondSorting}`
    );

    // console.log(response)
    if (response.status === 200) {
      // console.log(response)
      setMdaPaymentRecords(response.data);
      setIsLoading(false);
    } else {
      toaster("Something Went Wrong", "error");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getStatistics();
    getYearStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refinedData = mdaPaymentRecords?.graphValues?.firstYear?.map(
    (d, i) => ({
      name: d?.month,
      [sorting]: d?.totalAmount,
      [secondSorting]:
        mdaPaymentRecords?.graphValues?.secondYear[i]?.totalAmount,
    })
  );
  function formatYAxisTick(value) {
    if (Math.abs(value) >= 1.0e9) {
      return (Math.abs(value) / 1.0e9).toFixed(1) + "B";
    } else if (Math.abs(value) >= 1.0e6) {
      return (Math.abs(value) / 1.0e6).toFixed(1) + "M";
    } else if (Math.abs(value) >= 1.0e3) {
      return (Math.abs(value) / 1.0e3).toFixed(1) + "K";
    } else {
      return value.toString();
    }
  }
  return (
    <div style={{ marginBottom: "2rem" }}>
      <Card
        className={cn(styles.card, className)}
        title={`Monthly Revenue Updates for ${currentYear}`}
        classTitle={cn("title-purple", styles.cardTitle)}
        classCardHead={styles.cardHead}
        head={
          <>
            <div style={{ marginRight: "1rem" }}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={sorting}
                setValue={setSorting}
                options={intervals1}
                small
              />
            </div>
            <div style={{ marginRight: "1rem" }}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={secondSorting}
                setValue={setSecongSorting}
                options={intervals1}
                small
              />
            </div>
            <button
              className={cn("button", styles.button)}
              onClick={() => getStatistics()}
            >
              Compare
            </button>
          </>
        }
      >
        {}
        <div className={styles.chart}>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Loader />
            </div>
          ) : (
            // <NotFound />
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={300}
                data={refinedData}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid
                  strokeDasharray="none"
                  stroke={darkMode.value ? "#272B30" : "#EFEFEF"}
                  vertical={false}
                />
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12, fontWeight: "500", fill: "#9A9FA5" }}
                  padding={{ left: 10 }}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12, fontWeight: "500", fill: "#9A9FA5" }}
                  tickFormatter={formatYAxisTick} // Use the custom tick formatter
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "#272B30",
                    borderColor: "rgba(255, 255, 255, 0.12)",
                    borderRadius: 8,
                    boxShadow:
                      "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
                  }}
                  labelStyle={{
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#fff",
                  }}
                  itemStyle={{
                    padding: 0,
                    textTransform: "capitalize",
                    fontSize: 12,
                    fontWeight: "600",
                    color: "#fff",
                  }}
                />
                {/* <Line
              type='monotone'
              dataKey='monthly'
              dot={false}
              strokeWidth={4}
              stroke='#2A85FF'
            /> */}
                <Line
                  type="monotone"
                  dataKey={sorting}
                  dot={false}
                  strokeWidth={4}
                  stroke="#B5E4CA"
                />
                <Line
                  type="monotone"
                  dataKey={secondSorting}
                  dot={false}
                  strokeWidth={4}
                  stroke="#B1E5FC"
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
      </Card>
    </div>
  );
};

export default AllRevenueForMDA;
