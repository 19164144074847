import React, { useEffect, useState } from 'react'
import styles from './ProductActivity.module.sass'
import cn from 'classnames'
import Card from '../../../components/Card'
import Dropdown from '../../../components/Dropdown'
import { useLocation, useNavigate } from 'react-router'
import Form from '../../../components/Form'
import { Link } from 'react-router-dom'
import Icon from '../../../components/Icon'
import Modal from '../../../components/Modal'
import Success from '../../Payouts/Overview/Success'
import toaster from '../../../utils/toaster'
import { makeApiCall } from '../../../lib/api'
import Loader from '../../../components/Loader'
import TextInput from '../../../components/TextInput'
import Item from './Item'
import { makeReportCall } from '../../../lib/reportsApi'
const ProductActivity = ({ showRevenueHeads, setShowRevenueHeads }) => {
  const MdaCode = JSON.parse(localStorage.getItem('MdaCode'))
  const MdaName = JSON.parse(localStorage.getItem('MdaName'))
  const intervals = ['Last 2 weeks', 'Last 7 days']
  const pathname = useLocation()
  const navigate = useNavigate()
  // console.log(pathname.pathname)
  const [activeTab, setActiveTab] = useState(intervals[0])
  const [visibleModal, setVisibleModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoading1, setIsLoading1] = useState(false)
  const [revenueHead, setRevenueHead] = useState()
  const [features, setFeatures] = useState([])
  const [showFeatureUpdateModal, setShowFeatureUpdateModal] = useState(false)
  const filters = []

  const getRevenueHeads = async () => {
    // e.preventDefault()
    setIsLoading(true)
    // http://localhost:8888/api/v1/roles?size=2
    // const response = await makeApiCall(`/payment/revenue-heads?size=5`);
    const response = await makeApiCall(`/roles?size=20`)
    //  setProducts(response)
    // console.log(response)
    if (response.status === 200) {
      setRevenueHead(response.data)
      // setUpdatedValues(response.data.map((item) => item.revenue_amount))

      setIsLoading(false)
    } else {
      toaster('Something Went Wrong', 'error')
      setIsLoading(false)
    }
  }
  const getFeatures = async () => {
    setIsLoading(true)
    const response = await makeApiCall(`/roles/features/feature`, 'GET')
    if (response.status === 200) {
      // toaster(response.message, "success");
      // console.log(response);
      // setFeatures(response.data);
      setIsLoading(false)
      // console.log(response);
      let data = response.data
      setFeatures(data)

      for (const object of data) {
        filters.push(object.name)
      }
    } else {
      toaster('Something Went Wrong', 'error')
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getRevenueHeads()
    getFeatures()
  }, [])

  // const handleChange = (e, index) => {
  //   const { name, value } = e.target
  //   const newValues = [...updatedValues]
  //   newValues[index] = { ...newValues[index], [name]: value }
  //   setUpdatedValues(newValues)
  // }
  // console.log(showFeatureUpdateModal);
  return (
    <>
      <Card
        className={styles.card}
        title='All Roles'
        classTitle='title-green'
        head={
          <>
            <Form
              className={styles.form}
              // value={search}
              // setValue={setSearch}
              // onSubmit={() => handleSubmit()}
              placeholder={`Search Roles`}
              type='text'
              name='search'
              icon='search'
            />
            <Link
              className={cn('button-stroke button-small', styles.button)}
              to=''
              style={{ marginLeft: '12px' }}
              // onClick={goBack}
              onClick={() => setVisibleModal(true)}
            >
              {/* <Icon name='arrow-left' size='18' /> */}

              <span>Add Roles</span>
            </Link>
          </>
        }
      >
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <Loader className={styles.loader} />
          </div>
        ) : (
          <div className={styles.table}>
            <div className={styles.row}>
              {/* <div className={styles.col}>S/N</div> */}
              <div className={styles.col}>Role</div>
              <div className={styles.col}>No. of Features </div>
              <div className={styles.col}>Action</div>
            </div>
            {revenueHead?.map(
              (x, index) => (
                <div className={styles.row} key={index}>
                  {/* <div className={styles.col}> */}
                  {/* <div className={styles.label}>Week</div> */}
                  {/* {x.id} */}
                  {/* </div> */}
                  <div className={styles.col}>
                    {/* <div className={styles.label}>Products</div> */}

                    {x.name}
                    {/* <Item className={styles.item} item={x.revenue_code} /> */}
                  </div>
                  <div className={styles.col}>
                    {/* <div className={styles.label}>Views</div> */}
                    {/* <TextInput
            className={``}
            name='revenue_amount'
            type='number'
            required
            value={updatedValues[index]}
            onChange={(e) => handleChange(e, index, item)}
          /> */}
                    {x.features.length}
                    {'  '}
                    {x.features.length > 1 ? 'Features' : 'Feature'}
                    {/* <Item className={styles.item} item={x.revenue_amount} /> */}
                  </div>
                  {/* <div className={styles.col}>
                <div className={styles.label}>Likes</div>
                <Item className={styles.item} item={x.likes} />
              </div>
              <div className={styles.col}>
                <div className={styles.label}>Comments</div>
                <Item className={styles.item} item={x.comments} />
              </div> */}

                  <div className={styles.col}>
                    <button
                      className={cn('button')}
                      style={{ width: '100%' }}
                      // onClick={() => updateRevenueHead(item.revenue_code)}
                      onClick={() => (
                        // console.log(x),
                        setVisibleModal(true),
                        setShowFeatureUpdateModal(true),
                        localStorage.setItem('Feature', JSON.stringify(x))
                      )}
                    >
                      {'Update'}
                    </button>
                    {/* <Item className={styles.item} item={x.comments} /> */}
                  </div>
                </div>
              )
              // console.log(x)

              //<Item item={x} index={index} revenueHead={revenueHead} />
            )}
          </div>
        )}
        <div className={styles.nav}>
          {intervals.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: x === activeTab,
              })}
              onClick={() => setActiveTab(x)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => (
          setVisibleModal(false), setShowFeatureUpdateModal(false)
        )}
        setShowFeatureUpdateModal={setShowFeatureUpdateModal}
      >
        <Success
          getRevenueHeads={getRevenueHeads}
          showFeatureUpdateModal={showFeatureUpdateModal}
          setVisibleModal={setVisibleModal}
          filters={features}
        />
      </Modal>
    </>
  )
}

export default ProductActivity