import React, { useEffect, useState } from "react";

import Balance from "../../../../../components/Balance";
import Card from "../../../../../components/Card";
import CurrencyFormatter from "../../../../../utils/CurrencyFormatter";
import CustomTooltip from "../../../../../components/CustomTooltip";
import Icon from "../../../../../components/Icon";
import Tooltip from "../../../../../components/Tooltip";
import TooltipGlodal from "../../../../../components/TooltipGlodal";
import cn from "classnames";
import styles from "./Overview.module.sass";

const items = [
  {
    title: "Earning",
    counter: "$128k",
    icon: "activity",
    color: "#B5E4CA",
    tooltip: "Small description Earning",
    value: 37.8,
  },
  {
    title: "Balance",
    counter: "$512.64",
    icon: "pie-chart",
    color: "#CABDFF",
    tooltip: "Small description Balance",
    value: -17.8,
  },
  {
    title: "Total value of sales",
    counter: "$64k",
    icon: "shopping-bag",
    color: "#B1E5FC",
    tooltip: "Small description Total",
    value: 24.3,
  },
];

const CurrentYearOverview = ({ className, reportData, isLoading }) => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    // Function to update the current year every second (1000ms)
    const updateCurrentYear = () => {
      setCurrentYear(new Date().getFullYear());
    };

    // Set up a timer to update the year every second
    const intervalId = setInterval(updateCurrentYear, 1000);

    // Clean up the timer when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div style={{ marginBottom: "2rem" }}>
      <div className={styles.counter}>{currentYear} Revenue</div>

      <Card className={cn(styles.card, className)}>
        <div className={styles.overview}>
          <div className={styles.list}>
            <div className={styles.item}>
              <div
                className={styles.icon}
                style={{ backgroundColor: items[0].color }}
              >
                <Icon name="activity" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.label}>
                  Current Year Formal Payment
                  <CustomTooltip
                    content={
                      reportData?.current_year_revenue
                        ?.sum_total_year_payment_formal
                    }
                    className={styles.tooltip}
                    symbol="NGN"
                  >
                    <Icon name="info" fill="#6F767E" />
                  </CustomTooltip>
                  {/* <Tooltip
                    className={styles.tooltip}
                    title={x.tooltip}
                    icon='info'
                    place='top'
                  /> */}
                </div>
                <div className={styles.counter}>
                  {isLoading ? (
                    "********"
                  ) : (
                    <CurrencyFormatter
                      amount={
                        reportData?.current_year_revenue
                          ?.sum_total_year_payment_formal
                      }
                      currencySymbol="₦"
                    />
                  )}
                </div>
                {/* <div className={styles.indicator}>
                  <Balance className={styles.balance} value={x.value} />
                  <span>this week</span>
                </div> */}
              </div>
            </div>
            <div className={styles.item}>
              <div
                className={styles.icon}
                style={{ backgroundColor: items[0].color }}
              >
                <Icon name="pie-chart" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.label}>
                  Current Year Informal Payment
                  <CustomTooltip
                    content={
                      reportData?.current_year_revenue
                        ?.sum_total_year_payment_informal
                    }
                    className={styles.tooltip}
                    symbol="NGN"
                  >
                    <Icon name="info" fill="#6F767E" />
                  </CustomTooltip>
                  {/* <Tooltip
                    className={styles.tooltip}
                    title={x.tooltip}
                    icon='info'
                    place='top'
                  /> */}
                </div>
                <div className={styles.counter}>
                  {isLoading ? (
                    "*******"
                  ) : (
                    <CurrencyFormatter
                      amount={
                        reportData?.current_year_revenue
                          ?.sum_total_year_payment_informal
                      }
                      currencySymbol="₦"
                    />
                  )}
                  {/* {reportData?.revenue_statistics?.sum_total_year_payment} */}
                </div>
                {/* <div className={styles.indicator}>
                  <Balance className={styles.balance} value={x.value} />
                  <span>this week</span>
                </div> */}
              </div>
            </div>
            <div className={styles.item}>
              <div
                className={styles.icon}
                style={{ backgroundColor: items[0].color }}
              >
                <Icon name="pie-chart" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.label}>
                  Current Year Total Revenue
                  <CustomTooltip
                    content={
                      reportData?.revenue_statistics?.sum_total_year_payment
                    }
                    className={styles.tooltip}
                    symbol="NGN"
                  >
                    <Icon name="info" fill="#6F767E" />
                  </CustomTooltip>
                  {/* <Tooltip
                    className={styles.tooltip}
                    title={x.tooltip}
                    icon='info'
                    place='top'
                  /> */}
                </div>
                <div className={styles.counter}>
                  {isLoading ? (
                    "********"
                  ) : (
                    <CurrencyFormatter
                      amount={reportData?.current_year_revenue?.total_revenue}
                      currencySymbol="₦"
                    />
                  )}
                  {/* {reportData?.revenue_statistics?.sum_total_year_payment} */}
                </div>
                {/* <div className={styles.indicator}>
                  <Balance className={styles.balance} value={x.value} />
                  <span>this week</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </div>
  );
};

export default CurrentYearOverview;
