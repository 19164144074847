import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { useState } from "react";

import Card from "../../../../components/Card";
import Dropdown from "../../../../components/Dropdown";
import Loader from "../../../../components/Loader";
import cn from "classnames";
import styles from "./ProductViews.module.sass";
import useDarkMode from "use-dark-mode";

const intervals = ["Last 7 days", "This month", "All time"];

const ProductViews = ({ className, statistics, isLoading }) => {
  const darkMode = useDarkMode(false);
  const [sorting, setSorting] = useState(intervals[0]);
  function formatYAxisTick(value) {
    if (Math.abs(value) >= 1.0e9) {
      return (Math.abs(value) / 1.0e9).toFixed(1) + "B";
    } else if (Math.abs(value) >= 1.0e6) {
      return (Math.abs(value) / 1.0e6).toFixed(1) + "M";
    } else if (Math.abs(value) >= 1.0e3) {
      return (Math.abs(value) / 1.0e3).toFixed(1) + "K";
    } else {
      return value.toString();
    }
  }

  return (
    <Card
      className={cn(styles.card, className)}
      title="Gateway Performance Report"
      classTitle="title-purple"
      // head={
      //   <Dropdown
      //     className={styles.dropdown}
      //     classDropdownHead={styles.dropdownHead}
      //     value={sorting}
      //     setValue={setSorting}
      //     options={intervals}
      //     small
      //   />
      // }
    >
      <div className={styles.chart}>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100px",
              justifyItems: "center",
            }}
          >
            <Loader />
          </div>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={statistics}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              barSize={32}
              barGap={8}
            >
              <CartesianGrid
                strokeDasharray="none"
                stroke={darkMode.value ? "#272B30" : "#EFEFEF"}
                vertical={false}
              />
              <XAxis
                dataKey="channelCode"
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12, fontWeight: "500", fill: "#6F767E" }}
                padding={{ left: 10 }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12, fontWeight: "500", fill: "#6F767E" }}
                tickFormatter={formatYAxisTick} // Use the custom tick formatter
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: "#272B30",
                  borderColor: "rgba(255, 255, 255, 0.12)",
                  borderRadius: 8,
                  boxShadow:
                    "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
                }}
                labelStyle={{ fontSize: 12, fontWeight: "500", color: "#fff" }}
                itemStyle={{
                  padding: 0,
                  textTransform: "capitalize",
                  fontSize: 12,
                  fontWeight: "600",
                  color: "#fff",
                }}
                cursor={{ fill: "#f3f2f3" }}
              />
              <Bar dataKey="amount" fill="#B5E4CA" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </Card>
  );
};

export default ProductViews;
