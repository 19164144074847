import { NavLink, useLocation } from "react-router-dom";
import React, { useState } from "react";

import Cookies from "js-cookie";
import Icon from "../../Icon";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import { makeApiCall } from "../../../lib/api";
import styles from "./User.module.sass";
import { useNavigate } from "react-router-dom";

const items = [
  // {
  //   menu: [
  //     {
  //       title: 'Profile',
  //       url: '/shop',
  //     },
  //     {
  //       title: 'Edit profile',
  //       url: '/settings',
  //     },
  //   ],
  // },
  {
    menu: [
      {
        title: "Analytics",
        icon: "bar-chart",
        url: "/dashboard",
      },
      // {
      //   title: 'Affiliate center',
      //   icon: 'ticket',
      //   url: '/affiliate-center',
      // },
      {
        title: "All Apps",
        icon: "grid",
        url: "/products/dashboard",
      },
    ],
  },
  // {
  //   menu: [
  //     {
  //       title: 'Upgrade to Pro',
  //       icon: 'leaderboard',
  //       color: true,
  //       url: '/upgrade-to-pro',
  //     },
  //   ],
  // },
  // {
  //   menu: [
  //     {
  //       title: 'Account settings',
  //       url: '/settings',
  //     },
  //   ],
  // },
];

const User = ({ className }) => {
  const navigate = useNavigate();
  const notification_token = Cookies.get("notification_token");

  const [visible, setVisible] = useState(false);
  const { pathname } = useLocation();
  const handleLogout = () => {
    navigate("/");
    const response = makeApiCall("/auth/logout", "POST", {
      device_token: notification_token,
    });

    if (response?.status === 200 || 201) {
      Cookies.remove("user_token");
      Cookies.remove("user_details");

      // handleLogout();
    }

    // if (response?.response?.status === 401) {
    //   handleLogout();

    //   // setLoading(false);
    //   // toaster(`${response?.response?.data?.error}`, "error");
    //   // setLoading(false)
    //   // toaster(`${response?.error}`, 'error')
    //   // console.log(response.response.data.email)
    // }
  };
  // const logOut = async () => {
  //   const response = await makeApiCall("/auth/logout", "POST", {
  //     device_token: notification_token,
  //   });

  //   if (response?.status === 200) {
  //     handleLogout()
  //   }

  //   if (response.response.status === 401) {
  //     handleLogout();

  //     // setLoading(false);
  //     // toaster(`${response?.response?.data?.error}`, "error");
  //     // setLoading(false)
  //     // toaster(`${response?.error}`, 'error')
  //     // console.log(response.response.data.email)
  //   }
  // };
  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.user, className, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <img
            src="https://cross-river-pay.s3.us-east-2.amazonaws.com/cas-admin/images/content/user.jpg"
            alt="Avatar"
          />
        </button>
        <div className={styles.body}>
          {items.map((item, index) => (
            <div className={styles.menu} key={index}>
              {item.menu.map((x, index) =>
                x.url ? (
                  <NavLink
                    className={cn(styles.item, {
                      [styles.color]: x.color,
                      [styles.active]: pathname === x.url,
                    })}
                    to={x.url}
                    onClick={() => setVisible(false)}
                    key={index}
                  >
                    {x.icon && <Icon name={x.icon} size="24" />}
                    {x.title}
                  </NavLink>
                ) : (
                  <button
                    className={styles.item}
                    onClick={() => setVisible(false)}
                    key={index}
                  >
                    {x.title}
                  </button>
                )
              )}
            </div>
          ))}
          <div
            className={styles.item}
            style={{ cursor: "pointer" }}
            onClick={handleLogout}
          >
            Log Out
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
