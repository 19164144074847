import React from "react";
import Earning from "./Earning";

function GatewayReports() {
  return (
    <div>
      <Earning />
    </div>
  );
}

export default GatewayReports;
