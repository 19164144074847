import React, { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import styles from "./Modal.module.sass";
import Icon from "../Icon";
import { useLocation } from "react-router";

const Modal = ({
  outerClassName,
  visible,
  onClose,
  children,
  setShowUpdateModal,
  setShowFeatureUpdateModal,
}) => {
  const { pathname } = useLocation();

  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal");
      disableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [visible]);

  return createPortal(
    visible && (
      <div id="modal" className={styles.modal}>
        <div className={cn(styles.outer, outerClassName)}>
          <OutsideClickHandler
            onOutsideClick={() => (
              // eslint-disable-next-line no-sequences
              onClose(),
              pathname.pathname === "/business-sector"
                ? setShowUpdateModal(false)
                : onClose(),
              pathname.pathname === "/roles"
                ? setShowFeatureUpdateModal(false)
                : onClose()
            )}
          >
            {children}
            <button
              className={styles.close}
              onClick={() => (
                onClose(),
                pathname.pathname === "/business-sector"
                  ? setShowUpdateModal(false)
                  : onClose(),
                pathname.pathname === "/roles"
                  ? setShowFeatureUpdateModal(false)
                  : onClose()
              )}
            >
              <Icon name="close" size="20" />
            </button>
          </OutsideClickHandler>
        </div>
      </div>
    ),
    document.body
  );
};

export default Modal;
