import React from "react";
import RolesDash from "./ServicesDash";

function AppServices() {
  return (
    <div>
      <RolesDash />
    </div>
  );
}

export default AppServices;
