import React, { useState } from "react";
import cn from "classnames";
import styles from "./ProTips.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import ModalPreview from "../../../components/ModalPreview";

const SchoolDetails = ({ className, userProfile }) => {
  // const [visibleModalPreview, setVisibleModalPreview] = useState(false);
  console.log(userProfile);
  console.log("====================================");

  return (
    <>
      <div
        // className={cn(styles.card, className)}
        // title='Pro tips'
        // classTitle='title-green'
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <div className={styles.tips} style={{ marginTop: "20px" }}>
          {/* <div className={styles.info}>
            Need some ideas for the next product?
          </div> */}
          <div
          // className={styles.list}
          >
            <div
              // className={styles.item}
              // key={index}
              // onClick={() => setVisibleModalPreview(true)}
              style={{
                width: "100%",
                display: "flex",
                // justifyContent: 'center',
                alignItems: "center",
              }}
            >
              <div className={styles.icon}>
                <Icon name="profile-circle" size="24" />
              </div>
              <div
                className={styles.details}
                style={{ width: "100% !important" }}
              >
                <div className={styles.title}>{userProfile?.school?.name}</div>
                <div className={styles.line}>
                  <div
                    className={cn(
                      "status-purple"
                      // styles.status
                    )}
                  >
                    Name
                  </div>

                  {/* <div className={styles.user}>
                    <div className={styles.avatar}>
                      <img src={tips[0].avatar} alt='Avatar' />
                    </div>
                    <div className={styles.action}>{x.action}</div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles.tips} style={{ marginTop: "20px" }}>
          <div className={styles.list}>
            <div
              className={styles.item}

            >
              <div className={styles.icon}>
                <Icon name="mail" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.title} style={{ width: "100%" }}>
                  {userProfile[0]?.email === "" ? "NA" : userProfile[0]?.email}
                </div>
                <div className={styles.line}>
                  <div
                    className={cn(
                      "status-purple"
                      // styles.status
                    )}
                  >
                    Email
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className={styles.tips} style={{ marginTop: "20px" }}>
          {/* <div className={styles.info}>
            Need some ideas for the next product?
          </div> */}
          <div
          // className={styles.list}
          >
            <div
              // className={styles.item}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              // key={index}
              // onClick={() => setVisibleModalPreview(true)}
            >
              <div className={styles.icon}>
                <Icon name="diamond" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.title} style={{ width: "100%" }}>
                  {userProfile?.school?.address}
                </div>
                <div className={styles.line}>
                  <div
                    className={cn(
                      "status-purple"
                      // styles.status
                    )}
                  >
                    Address
                  </div>

                  {/* <div className={styles.user}>
                    <div className={styles.avatar}>
                      <img src={tips[0].avatar} alt='Avatar' />
                    </div>
                    <div className={styles.action}>{x.action}</div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles.tips} style={{ marginTop: "20px" }}>
          <div className={styles.list}>
            <div
              className={styles.item}

            >
              <div className={styles.icon}>
                <Icon name="diamond" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.title} style={{ width: "100%" }}>
                  {userProfile[0]?.tin}
                </div>
                <div className={styles.line}>
                  <div
                    className={cn(
                      "status-purple"
                    )}
                  >
                    TIN
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className={styles.tips} style={{ marginTop: "20px" }}>
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.icon}>
                <Icon name="diamond" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.title} style={{ width: "100%" }}>
                  {userProfile?.school?.type}
                </div>
                <div className={styles.line}>
                  <div className={cn("status-purple")}>TYPE</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.tips} style={{ marginTop: "20px" }}>
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.icon}>
                <Icon name="diamond" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.title} style={{ width: "100%" }}>
                  {userProfile?.school?.lga?.name === ""
                    ? "NA"
                    : userProfile?.school?.lga?.name}
                </div>
                <div className={styles.line}>
                  <div
                    className={cn(
                      "status-purple"
                      // styles.status
                    )}
                  >
                    LGA
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ModalPreview
        visible={visibleModalPreview}
        onClose={() => setVisibleModalPreview(false)}
        video='/images/content/video.mp4'
        title='Use guidelines'
      /> */}
    </>
  );
};

export default SchoolDetails;
