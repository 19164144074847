import React from 'react'

function NotFound() {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '300px',
      }}
    >
      <img
        src='/images/nodata.jpg'
        alt=''
        srcset=''
        style={{ height: '50%' }}
      />
      <h1>No Data</h1>
    </div>
  )
}

export default NotFound
