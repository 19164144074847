import AllRevenueForMDA from "./AllRevenueforMda";
import NewCustomer from "./NewCustomer";
import Overview from "./Overview";
import React from "react";
import Table from "./Table";
import TableForRevHead from "./TableForRevHead";
import styles from "./Earning.module.sass";

function IndividualGatewayReports() {
  const revName = JSON.parse(localStorage.getItem("revName"));

  return (
    <div>
      {/* IndividualGatewayReports */}
      <h1 style={{ marginBottom: "2rem", textTransform: "capitalize" }}>
        {revName}
      </h1>

      <Overview />
      <AllRevenueForMDA />
      {/* <div style={{ height: '400px', overflow: 'scroll' }}></div> */}
      {/* <OverviewCard /> */}
      <NewCustomer />
      <Table />

      {/* <div className={styles.row}>
        <div className={styles.col}>
          <div
            style={{
              height: '350px',
              overflow: 'scroll',
            }}
          ></div>
        </div>
        <div className={styles.col}></div>
      </div> */}

      {/* <TableForRevHead /> */}
    </div>
  );
}

export default IndividualGatewayReports;
