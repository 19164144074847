import React, { useEffect, useState } from "react";
import styles from "./Table.module.sass";
import Items from "./items";
import { useNavigate } from "react-router";
import { makeApiCall } from "../../../lib/api";
import toaster from "../../../utils/toaster";
import NotFound from "../../NotFound";
import Loader from "../../../components/Loader";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import cn from "classnames";
import Modal from "../../../components/Modal";
import Success from "../../Payouts/Overview/Success";

const Table = () => {
  const [fetchLimit, setFetchLimit] = useState(5);
  const [visibleModal, setVisibleModal] = useState(false);

  const [totalLength, setTotalLength] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [topMDAs, setTopMDAs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nodat, setNodata] = useState(false);
  const [triggerPoints, setTriggerPoints] = useState([]);

  const inc = 10;

  const getTripperPoints = async () => {
    try {
      // e.preventDefault()
      setIsLoading(true);
      const response = await makeApiCall(`/notifications/triggers`);

      if (response.status === 200) {
        setTriggerPoints(response?.data);
        //   setSelectedFilters(response?.data);
      } else {
        throw new Error("Something Went Wrong");
      }
    } catch (error) {
      toaster(error.message, "error");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTripperPoints();
  }, []);
  // useEffect(() => {
  //   getTopMDAs();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fetchLimit]);
  // console.log(topMDAs, 'topMDAs')
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        if (hasMore === false) return;
        // Check if there is more data to fetch
        // Increment page and fetchLimit
        // setPage((prevPage) => prevPage + 1)
        // console.log('ddd', hasMore)
        setFetchLimit((prevFetched) => prevFetched + inc);
      }
    };
    // Attach the event listener to the scroll event
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Clean up the event listener on unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasMore]);
  const navigate = useNavigate();
  // const viewInfo = (id) => {
  //   navigate(`/payment/overview/details/${id}`)
  // }
  return (
    <>
      <Card
        title="All Triggers"
        classTitle="title-blue"
        head={
          <>
            {/* <Form
            className={styles.form}
            // value={search}
            // setValue={setSearch}
            // onSubmit={() => handleSubmit()}
            placeholder={`Search Roles`}
            type="text"
            name="search"
            icon="search"
          /> */}
            <Link
              className={cn("button-stroke button-small", styles.button)}
              to=""
              style={{ marginLeft: "12px" }}
              // onClick={goBack}
              onClick={() => setVisibleModal(true)}
            >
              {/* <Icon name='arrow-left' size='18' /> */}

              <span>Add Triggers</span>
            </Link>
          </>
        }
      >
        {/* <div className={styles.wrapper}> */}
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Title</div>
            {/* <div className={styles.col}>Description</div> */}
            <div className={styles.col}>Value 1 </div>
            <div className={styles.col}>Value 2</div>
            <div className={styles.col}>Action</div>
          </div>
          {triggerPoints?.map((x, index) => (
            <div className={styles.row} key={index}>
              <Items x={x} />
            </div>
          ))}
        </div>
        {nodat && <NotFound />}
        {/* </div> */}
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader className={styles.loader} />
          </div>
        ) : (
          ""
        )}
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
        // setShowFeatureUpdateModal={setShowFeatureUpdateModal}
      >
        <Success
          getRevenueHeads={getTripperPoints}
          // showFeatureUpdateModal={showFeatureUpdateModal}
          setVisibleModal={setVisibleModal}
          // filters={features}
        />
      </Modal>
    </>
  );
};

export default Table;
