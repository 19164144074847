import { Link, NavLink, useLocation } from "react-router-dom";
import React, { useState } from "react";

import Dropdown from "./Dropdown";
import Help from "./Help";
import Icon from "../Icon";
import Image from "../Image";
import Theme from "../Theme";
import cn from "classnames";
import styles from "./Sidebar.module.sass";

// import { GrMoney } from 'react-icons/gr'

const navigation = [
  {
    title: "Home",
    icon: "home",
    url: "/dashboard",
  },
  {
    title: "Apps",
    slug: "products",
    icon: "grid",
    add: true,
    dropdown: [
      {
        title: "All Apps",
        url: "/products/dashboard",
      },
      // {
      //   title: 'Drafts',
      //   url: '/products/drafts',
      //   counter: '2',
      //   colorCounter: '#FFBC99',
      // },
      // {
      //   title: 'Released',
      //   url: '/products/released',
      // },
      // {
      //   title: 'Comments',
      //   url: '/products/comments',
      // },
      // {
      //   title: 'Scheduled',
      //   url: '/products/scheduled',
      //   counter: '8',
      //   colorCounter: '#B5E4CA',
      // },
    ],
  },
  {
    title: "Entities",
    slug: "customers",
    icon: "diamond",
    dropdown: [
      {
        title: "Citizens",
        url: "/citizens",
      },
      {
        title: "Business",
        url: "/business",
      },
      {
        title: "Houses",
        url: "/houses",
      },
      {
        title: "Schools",
        url: "/schools",
      },
    ],
  },
  {
    title: "Revenue Manager",
    slug: "revenue-manager",
    icon: "diamond",
    dropdown: [
      {
        title: "MDAs",
        url: "/mdas",
      },
      {
        title: "Revenue Heads",
        url: "/revenue",
      },
      {
        title: "Tax Offices",
        url: "/taxoffices",
      },
      {
        title: "Business Sectors",
        url: "/business-sector",
      },
    ],
  },

  {
    title: "Gateway Settings",
    icon: "diamond",
    url: "/payment-gateway",
  },
  {
    title: "User Management",
    icon: "profile-circle",
    url: "/user/management",
  },
  // {
  //   title: 'Income',
  //   slug: 'income',
  //   icon: 'pie-chart',
  //   dropdown: [
  //     {
  //       title: 'Earning',
  //       url: '/income/earning',
  //     },
  //     {
  //       title: 'Refunds',
  //       url: '/income/refunds',
  //     },
  //     {
  //       title: 'Payouts',
  //       url: '/income/payouts',
  //     },
  //     {
  //       title: 'Statements',
  //       url: '/income/statements',
  //     },
  //   ],
  // },
  {
    title: "Role Management",
    icon: "promotion",
    url: "/roles",
  },
  // {
  //   title: 'App Dashboard',
  //   icon: 'promotion',
  //   url: '/promote',
  // },
  // {
  //   title: 'Payment  System',
  //   icon: 'payment',
  //   url: '#',
  // },
  {
    title: "Payment  System",
    slug: "payment",
    icon: "payment",
    dropdown: [
      {
        title: "Overview",
        url: "/payment/overview",
      },
      {
        title: "Transactions",
        url: "/payment/Transactions",
      },
      {
        title: "Gateway Reports",
        url: "/payment/gateway-report",
      },
      {
        title: "Webbook Reports",
        url: "/payment/webbook-reports",
      },

      // {
      //   title: 'Business',
      //   url: '/business',
      // },
      // {
      //   title: 'Houses',
      //   url: '/houses',
      // },
      // {
      //   title: 'Schools',
      //   url: '/schools',
      // },
    ],
  },
  {
    title: "Reports",
    slug: "reports",
    icon: "payment",
    dropdown: [
      {
        title: "Formal Sector",
        url: "/report/formal-report",
      },
      {
        title: "Informal Sector",
        url: "/report/informal-report",
      },
      // {
      //   title: "Services",
      //   url: "/report/App-services",
      // },
      // {
      //   title: "Tax Offices",
      //   url: "/taxoffices",
      // },
      // {
      //   title: "Business Sectors",
      //   url: "/business-sector",
      // },
    ],
  },
  {
    title: "Forestry",
    slug: "forestry",
    icon: "promotion",
    dropdown: [
      {
        title: "Overview",
        url: "/forestry/overview",
      },
      {
        title: "Reports",
        url: "/forestry/reports",
      },
      {
        title: "Forestry Agent/Checkpoints",
        url: "/forestry/App-services",
      },
      // {
      //   title: "Tax Offices",
      //   url: "/taxoffices",
      // },
      // {
      //   title: "Business Sectors",
      //   url: "/business-sector",
      // },
    ],
  },
  {
    title: "Services",
    url: "/report/App-services",
    icon: "promotion",
  },
  {
    title: "Settings",
    slug: "setting",
    icon: "setting",
    dropdown: [
      {
        title: "Trigger Points",
        url: "/settings",
      },
      // {
      //   title: "Informal Sector",
      //   url: "/report/informal-report",
      // },
      // {
      //   title: "Services",
      //   url: "/report/App-services",
      // },
      // {
      //   title: "Tax Offices",
      //   url: "/taxoffices",
      // },
      // {
      //   title: "Business Sectors",
      //   url: "/business-sector",
      // },
    ],
  },
];

const Sidebar = ({ className, onClose }) => {
  const [visibleHelp, setVisibleHelp] = useState(false);
  const [visible, setVisible] = useState(false);

  const { pathname } = useLocation();

  return (
    <>
      <div
        className={cn(styles.sidebar, className, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <Link className={styles.logo} to="/dashboard" onClick={onClose}>
          <Image
            className={styles.pic}
            src="/images/content/login-pic.png"
            srcDark="/images/content/login-pic.png"
            alt="Core"
          />
        </Link>
        <div className={styles.menu}>
          {navigation.map((x, index) =>
            x.url ? (
              <NavLink
                className={cn(styles.item, {
                  [styles.active]: pathname === x.url,
                })}
                to={x.url}
                key={index}
                onClick={onClose}
              >
                <Icon name={x.icon} size="24" />
                {/* <GrMoney /> */}
                {x.title}
              </NavLink>
            ) : (
              <Dropdown
                className={styles.dropdown}
                visibleSidebar={visible}
                setValue={setVisible}
                key={index}
                item={x}
                onClose={onClose}
              />
            )
          )}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        <div className={styles.foot}>
          {/* <button className={styles.link} onClick={() => setVisibleHelp(true)}>
            <Icon name='help' size='24' />
            Help & getting started
            <div className={styles.counter}>8</div>
          </button> */}
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>
      <Help
        visible={visibleHelp}
        setVisible={setVisibleHelp}
        onClose={onClose}
      />
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;
