import React, { useState } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import styles from './NameAndDescription.module.sass'
import Card from '../../../components/Card'
import Icon from '../../../components/Icon'
import TextInput from '../../../components/TextInput'
import Editor from '../../../components/Editor'

const NameAndDescription = ({ className, handleUserInputs, appDetails }) => {
  const [content, setContent] = useState()

  return (
    <Card
      className={cn(styles.card, className)}
      title='Name'
      classTitle='title-green'
      head={
        <Link
          className={cn('button-stroke button-small', styles.button)}
          to='/products/dashboard'
        >
          <Icon name='arrow-left' size='24' />
          <span>Back</span>
        </Link>
      }
    >
      <div className={styles.description}>
        <TextInput
          className={styles.field}
          label='App Name'
          name='appName'
          value={appDetails.appName}
          type='text'
          tooltip='Maximum 100 characters. No HTML or emoji allowed'
          onChange={handleUserInputs}
          required
        />
        <TextInput
          className={styles.field}
          label='Email'
          name='email'
          type='email'
          value={appDetails.email}
          onChange={handleUserInputs}
          tooltip='Maximum 100 characters. No HTML or emoji allowed'
          required
        />
        <TextInput
          className={styles.field}
          label='Password'
          name='password'
          value={appDetails.password}
          type='password'
          onChange={handleUserInputs}
          tooltip='Maximum 100 characters. No HTML or emoji allowed'
          required
        />
        <TextInput
          className={styles.field}
          label='Phone Number'
          name='phoneNumber'
          type='number'
          value={appDetails.phoneNumber}
          onChange={handleUserInputs}
          tooltip='Maximum 100 characters. No HTML or emoji allowed'
          required
        />
        {/* <Editor
                    state={content}
                    onChange={setContent}
                    classEditor={styles.editor}
                    label="Description"
                    tooltip="Description"
                /> */}
        {/* <div className={styles.group}>
                    <TextInput
                        className={styles.field}
                        label="Key features"
                        name="value1"
                        type="text"
                        placeholder="Value"
                        tooltip="Maximum 100 characters. No HTML or emoji allowed"
                        required
                    />
                    <TextInput
                        className={styles.field}
                        name="value2"
                        type="text"
                        placeholder="Value"
                        required
                    />
                    <TextInput
                        className={styles.field}
                        name="value3"
                        type="text"
                        placeholder="Value"
                        required
                    />
                    <TextInput
                        className={styles.field}
                        name="value4"
                        type="text"
                        placeholder="Value"
                        required
                    />
                </div> */}
      </div>
    </Card>
  )
}

export default NameAndDescription
