import React from "react";
import Statements from "./Statements";

function PaymentRecords() {
  return (
    <div>
      <Statements />
    </div>
  );
}

export default PaymentRecords;
