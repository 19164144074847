import React, { useEffect, useState } from "react";
import { onMessageListener, requestPermission } from "../firebase";

import toaster from "../utils/toaster";

function FireNotification() {
  const [notification, setNotification] = useState({ title: "", body: "" });

  useEffect(() => {
    // Request notification permission when the component mounts
    // requestPermission();

    // Initialize unsubscribe as a function
    let unsubscribe;

    // Listen for incoming FCM messages
    unsubscribe = onMessageListener((payload) => {
      toaster(
        `${payload?.notification?.title}: ${payload?.notification?.body}`,
        "success"
      );
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
      // Display the notification in your app using a toaster or other UI component
    });

    return () => {
      // Check if unsubscribe is a function before calling it
      if (unsubscribe && typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, []);

  console.log(notification, "This is notification");

  return <div></div>;
}

export default FireNotification;
